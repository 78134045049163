@switch (name()) {
    @case ("npos-full-report") {
        <npos-full-report [reportViewModel]="reportViewModel()"></npos-full-report>
    }
    @case ("reading-robot-full-report") {
        <reading-report [reportViewModel]="reportViewModel()"></reading-report>
    }
    @case ("rise-index-report") {
        <rise-index-report [reportViewModel]="reportViewModel()"></rise-index-report>
    }
    @default {
        No Report!
    }
}
