<ng-container *ngIf="report">
    <div class="page">
        <img class="cover section-image" src="assets/organizational-abs/images/cover.png" alt="intro-image" />
        <!-- <h2 class="organization-name" style="font-size: 70pt; color: white;">{{ report.name }}</h2> -->
    </div>
    <div class="page">
        <img src="assets/organizational-abs/images/2.png" alt="" class="cover" />
        <div style="height: 100%" class="wrapper flex-center">
            <div class="text-center">
                <p>تم إعداد المحتوى العلمي لهذا التقرير من قبل</p>
                <p>شركة بناء الطاقات</p>
                <p>وذلك من خلال خدمة التطبيق الرقمي</p>
                <p>&quot;مقياس قدرات الجمعيات&quot;</p>
                <p>لصالح</p>
                <h2 style="color: black; margin-block: 2rem;">{{ reportViewModel.user.name }}</h2>
                <p style="direction: ltr;">{{ reportViewModel.submitted_at | date : "yyyy MMM dd" }}</p>
            </div>
        </div>
    </div>
    <div class="page">
        <img src="assets/organizational-abs/images/2.png" alt="" class="cover" />
        <div class="wrapper">
            <div class="index">
                <h2>الفهرس:</h2>
                <div class="table">
                    <div class="row">
                        <div class="title">المؤشر العام لقدرات المنظمة</div>
                        <div class="dots"></div>
                        <div class="value">7</div>
                    </div>
                    <div class="row">
                        <div class="title">المجال الأول: القيادة</div>
                        <div class="dots"></div>
                        <div class="value">12</div>
                    </div>
                    <div class="row">
                        <div class="title">المجال الثاني: تصميم الحلول</div>
                        <div class="dots"></div>
                        <div class="value">15</div>
                    </div>
                    <div class="row">
                        <div class="title">المجال الثالث: الإدارة والعمليات</div>
                        <div class="dots"></div>
                        <div class="value">18</div>
                    </div>
                    <div class="row">
                        <div class="title">المجال الرابع: التفعيل الاجتماعي</div>
                        <div class="dots"></div>
                        <div class="value">21</div>
                    </div>
                    <div class="row">
                        <div class="title">المجال الخامس: الاستدامة</div>
                        <div class="dots"></div>
                        <div class="value">24</div>
                    </div>
                    <div class="row">
                        <div class="title">المجال السادس: الأثر الاجتماعي</div>
                        <div class="dots"></div>
                        <div class="value">27</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="page">
        <img class="cover" src="assets/organizational-abs/images/2.png" alt="overall-assessment" />
        <div class="wrapper">
            <p style="color: #444" class="text-center">بسم الله الرحمن الرحيم</p>

            <h2>ما هو مقياس قدرات الجمعيات؟</h2>
            <p>
                روبوت رقمي يساعد الجمعيات غير الربحية على تقييم قدراتها بالاعتماد على
                نموذج علمي ومعياري متطور ومحكم. يفيد المنظمة عند استخدامه في:
            </p>
            <table>
                <thead>
                    <tr>
                        <th>التقييم المعياري</th>
                        <th>التخطيط</th>
                        <th>الإدارة الاستراتيجية</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            تقييم قدرات المنظمة في أربعة مستويات (التأسيس، التمرس، التطوّر،
                            الاحترافية).
                        </td>
                        <td>
                            يزود تقرير توصيات المقياس بأداة تحليلية عميقة وفعالة تساعد المنظمة
                            على التخطيط الاستراتيجي.
                        </td>
                        <td>
                            تستخدم الإدارة هذا المقياس كمؤشر استراتيجي تقيس فيه قدراتها بشكل
                            سنوي، وتقيم مدى تطور قدراتها خلال العام.
                        </td>
                    </tr>
                </tbody>
            </table>
            <h2>عن الجهة المطورة</h2>
            <p>
                أعدّ المحتوى العلمي لهذا العمل شركة بناء الطاقات في جدة، ويعتمد على خدمة
                HCD Benchmarker في تكنولوجيا البيانات والذكاء الاصطناعي.
            </p>
        </div>
    </div>

    <div class="page">
        <img src="assets/organizational-abs/images/2.png" alt="" class="cover" />
        <div class="wrapper">
            <h2>عن النموذج العلمي للمقياس</h2>
            <p>
                درسنا من خلال هذا المقياس ستة أبعاد متكاملة من قدرات منظمتك، والتي
                نوضحها في الشكل الآتي:
            </p>
            <div class="diagram">
                <img src="assets/organizational-abs/images/npos-model.png" alt="" />
                <div class="flex-center">
                    <span>نموذج مقياس قدرات الجمعيات.</span>
                    <span>المصدر: شركة بناء الطاقات</span>
                </div>
            </div>
        </div>
    </div>
    <div class="page">
        <img class="cover" src="assets/organizational-abs/images/2.png" alt="overall-assessment" />
        <div class="wrapper">
            <p>حظيت منظمتكم بدرجة:</p>
            <h2 class="result-percentage">{{ report.score | number : "1.2-2" }}%</h2>
            <p>من المؤشر العام لقدرات المنظمة، وهي تدل على أن منظمتكم هي في:</p>
            <h2>{{ report.level }}</h2>
            <p>علماً أن جميع المراحل الأربعة لقدرات المنظمة هي:</p>
            <app-stepper [steps]="evaluationItems" [active]="getLevelIdx(report.level)"></app-stepper>
            <h2>لذلك نوصيكم بما يلي:</h2>

            <ng-container [ngSwitch]="report.recommendation">
                <div *ngSwitchCase="'l1'">
                    <p>
                        المنظمة في مرحلة التأسيس، لذلك من الطبيعي أنها لا تمتلك كل القدرات
                        المؤسسية الآن. لكن هذا لا يعني أن تركز المنظمة جلّ مواردها وطاقتها
                        لتطوير قدراتها، من المهم الموازنة بين مسؤولية المنظمة في خدمة
                        المجتمع، وبين دورها المستمر في بناء قدراتها. فكون المنظمة ناشئة أو
                        أن قدراتها في مرحلة التأسيس لا يعني أنها تبقى ساكنة تنتظر فرص الدعم،
                        بل ينبغي أن تنطلق من مواردها الحالية في خدمة المجتمع في مجال محدد
                        ومركز، فلا تهدر مواردها المحدودة في عدة مجالات، الأولى أن ينصب تركيز
                        المنظمة التي هي في مرحلة التأسيس على مجال واحد ما أمكن. وبذات الوقت
                        تستفيد من عملها المركز في بناء قدراتها، ابتداءاً من الخبرات التي
                        تكتسبها من العمل، وانتهاءاً بالوفرة المالية في تنفيذ المشاريع التي
                        قد تتيح لها أن تستثمر في بناء قدراها، أو فرص التمويل المتاحة لتمويل
                        بناء قدرات المنظمات في مرحلة التأسيس، ثم البرامج التدريبية، ومنح
                        التطوير والتدريب، والحاضنات التنموية وغيرها. وتذكر أن المنظمة
                        الناشئة، ستبقى ناشئة ما لم تستثمر في نموها وتطورها.
                    </p>
                </div>
                <div *ngSwitchCase="'l2'">
                    <p>
                        المنظمة في مرحلة الممارسة، لذلك من الطبيعي أنها لا تمتلك معظم
                        القدرات المؤسسية الآن. لكن هذا لا يعني أن تركز المنظمة جلّ مواردها
                        وطاقتها لتطوير قدراتها، من المهم الموازنة بين مسؤولية المنظمة في
                        خدمة المجتمع، وبين دورها المستمر في بناء قدراتها. فكون المنظمة في
                        مرحلة الممارسة لا يعني أن تنشغل في تطوير قدراتها، ولا تتحرك إلا
                        بوصول الدعم، بل ينبغي أن تنطلق من مواردها الحالية في خدمة المجتمع في
                        مجال محدد ومركز، وتزيد اعتمادها على المتطوعين، سواء من الفريق
                        الإداري أو مجلس الإدارة أو المتطوعين من خارج المنظمة، وتبني شراكات
                        مع جهات عدة لإطلاق مشاريع مشتركة. وبذات الوقت تستفيد من عملها المركز
                        في بناء قدراتها، ابتداءاً من الخبرات التي تكتسبها من العمل،
                        وانتهاءاً بالوفرة المالية في تنفيذ المشاريع التي قد تتيح لها أن
                        تستثمر في بناء قدراها، وتستفيد من منح التطوير والتدريب من المؤسسات
                        المانحة والمؤسسات الحكومية، والحاضنات التنموية وغيرها. وتذكر أن
                        المنظمة لن تنمو حجماً ونوعاً ما لم تستثمر في نموها وتطورها.
                    </p>
                </div>
                <div *ngSwitchCase="'l3'">
                    <p>
                        المنظمة في مرحلة التطور، والذي يعني أنها تمتلك من القدرات ما يمكنها
                        من تنفيذ مشاريع نوعية، وبالذات لديها تحدّ في تطوير عدد من الجوانب في
                        قدراتها المؤسسية. لذا من الضروري أن تنطلق المنظمة من مواردها التي
                        تتميز فيها في النمو وصنع الأثر، وبذات الوقت تخصص نسبة من مواردها في
                        تطوير نفسها وتعزيز قدراتها في المجالات التي تحتاج إلى تطوير. وتذكر
                        أنه لا توجد نقاط ضعف وقوة، هنالك موارد ينبغي للمنظمة أن تستثمرها
                        أفضل استثمار في صنع الأثر والنمو، ثم لا مانع أن تعمل على تطوير
                        قدراتها في مجالات أخرى. وتطوير قدراتها لا يقتصر على التطوير الذاتي
                        لقدراتها، بل قد يكون من خلال التحالف مع منظمات أخرى تتكامل فيما
                        بينها، بحيث يضيف كل طرف موارده التي يتميز بها للطرف المتحالف الآخر.
                    </p>
                </div>
                <div *ngSwitchCase="'l4'">
                    <p>
                        المنظمة في مرحلة الاحترافية، والذي يعني أنها تمتلك من القدرات ما
                        يمكنها من تنفيذ مشاريع نوعية، وتتمتع باستقرار نسبي، والتحدي الذي
                        يواجهها هو الحفاظ على هذا الاستقرار، مع الاستمرار بالتحسين المستمر
                        والابتكار. ومن الطبيعي أن المنظمة تتميز بمجالات عن سواها في القدرات،
                        لذلك ينبغي أن تنطلق في النمو وصنع الأثر في المجالات التي تتميز فيها،
                        وبذات الوقت تخصص نسبة من مواردها في تطوير نفسها وتعزيز قدراتها في
                        المجالات التي تطمح أن تتطور فيها. وتطوير قدراتها لا يقتصر على
                        التطوير الذاتي لقدراتها، بل قد يكون من خلال التحالف مع منظمات أخرى
                        تتكامل فيما بينها، بحيث يضيف كل طرف موارده التي يتميز بها للطرف
                        المتحالف الآخر.
                    </p>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="page">
        <img src="assets/organizational-abs/images/2.png" alt="" class="cover" />
        <div class="wrapper">
            <h2>الخطوات الست لتطوير قدرات الجمعية</h2>
            <p>
                بعد تحديد المستوى العام لقدرات الجمعية، نفصّل فيما يلي قدرات الجمعية في
                كل بعد من الأبعاد الستة الفرعية للقدرات: القيادة، تصميم الحلول، الإدارة
                والعمليات، التفعيل الاجتماعي، الاستدامة، الأثر الاجتماعي.
            </p>
            <p>
                وهذه الأبعاد الستة الفرعية لا ينبغي العمل على تعزيزها مجتمعة، بل يجب أن
                يخضع ذلك إلى الأولويات التي تأخذ بالحسبان كلاً من المعيارين في تحديد
                الأولويات:
            </p>
            <ol>
                <li>
                    أهمية البعد وأولويته في العمل، فتعزيز القدرات القيادية تأتي في المرحلة
                    الأولى كأساس للتفكير والتوجيه والتخطيط، ثم يأتي بعدها تحديد المشاكل
                    التي ستتصدى لها الجمعية وابتكار الحلول لها، وهكذا. هذا الترتيب وفق
                    أولوية الأهمية الاستراتيجية، والتي نوضحها في المخطط اللاحق.
                </li>
                <li>
                    اعتبارات خاصة بالجمعية والتي ترتبط بالظروف الزمنية أو ظروف الجمعية
                    وتوجهاتها.
                </li>
            </ol>
            <div class="diagram">
                <img style="height: auto" src="assets/organizational-abs/images/model.png" style="width: 100%;"
                    alt="" />
            </div>
        </div>
    </div>
    <div class="page">
        <img src="assets/organizational-abs/images/2.png" alt="" class="cover" />
        <div class="wrapper">
            <p>
                ونوضح في المخطط الآتي نتيجة التحليل المفصل لكل بعد من الأبعاد الفرعية
                الستة لقدرات الجمعية، والتي تبين الأبعاد ذات التقييم الأعلى، والأبعاد
                ذات التقييم الأقل، ثم نأتي على تفصيل هذه النتائج وتوصيات تعزيز هذا
                البعد.
            </p>
            <div id="result-chart" class="chart-container" style="margin-block-start: 2rem; width: 100%; height: 600px">
            </div>
        </div>
    </div>
    <ng-container *ngFor="let section of allSections">
        <ng-container [ngSwitch]="section.name">
            <ng-container *ngSwitchCase="'leadership'">
                <ng-container *ngTemplateOutlet="leadershipSection; context: { section }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'solution'">
                <ng-container *ngTemplateOutlet="solutionSection; context: { section }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'management'">
                <ng-container *ngTemplateOutlet="managementSection; context: { section }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'activation'">
                <ng-container *ngTemplateOutlet="activationSection; context: { section }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'sustainability'">
                <ng-container *ngTemplateOutlet="sustainabilitySection; context: { section }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'impact'">
                <ng-container *ngTemplateOutlet="impactSection; context: { section }"></ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault></ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #tableHelper let-elements="elements">
    <table>
        <thead>
            <tr>
                <th *ngFor="let el of elements | keyvalue">{{ el.key }}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td *ngFor="let el of elements | keyvalue">
                    <ul>
                        <li *ngFor="let c of getElementValues(el.value)">{{ c }}</li>
                    </ul>
                </td>
            </tr>
        </tbody>
    </table>
</ng-template>

<!-- SECTIONS -->
<ng-template #leadershipSection let-section="section">
    <div class="page">
        <img class="cover section-image" src="{{ section.image }}" alt="{{ section.name }}" />
        <div class="wrapper"></div>
    </div>
    <div class="page">
        <img class="cover" src="assets/organizational-abs/images/2.png" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="header3">المجال الأول: القيادة</h2>
            <p>
                درسنا لديكم من خلال عدة أسئلة مجال القدرات القيادية، وحلّلنا بشكل مفصل
                هذه السمات:
            </p>

            <ng-container *ngTemplateOutlet="tableHelper; context: { elements: section.analysis }"></ng-container>
            <p>
                حصلت المنظمة على تقييم %{{ section.score | number : "1.2-2" }} في المجال
                القيادي
            </p>
            <h4>المنظمة في {{ section.level }} في المجال القيادي</h4>
            <p>وتبين من التحليل في هذا المجال ما يلي:</p>

            <p>{{ getSectionNote(section) }}</p>

            <p>علماً أن المستويات الأربعة لهذه السمعة بشكل عام هي:</p>
            <app-stepper [steps]="evaluationItems" [active]="getLevelIdx(section.level)"></app-stepper>
        </div>
    </div>
    <div class="page">
        <img class="cover" src="assets/organizational-abs/images/2.png" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="header3">ماذا يجب أن نفعل؟</h2>
            <ng-container *ngTemplateOutlet="sectionRecommendationsHelper; context: { section }"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #solutionSection let-section="section">
    <div class="page">
        <img class="cover section-image" src="{{ section.image }}" alt="{{ section.name }}" />
        <div class="wrapper"></div>
    </div>

    <div class="page">
        <img class="cover" src="assets/organizational-abs/images/2.png" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="header3">المجال الثاني: تصميم الحلول</h2>
            <p>
                درسنا مجال تصميم الحلول لديكم من خلال عدة مؤشرات، وحلّلنا بشكل مفصل هذه
                السمات:
            </p>
            <ng-container *ngTemplateOutlet="tableHelper; context: { elements: section.analysis }"></ng-container>
            <p>
                حصلت المنظمة على تقييم %{{ section.score | number : "1.2-2" }} في مجاال
                تصميم الحلول
            </p>
            <h4>المنظمة في {{ section.level }} في مجال تصميم الحلول</h4>
            <p>وتبين من التحليل في هذا المجال ما يلي:</p>
            <p>
                السمة موجودة، وفاعلة جزئياً. وتحتاج المنظمة في هذه المرحلة إلى تعزيز
                قدراتها في ممارسة هذه السمة بشكل أفضل.
            </p>
            <p>علماً أن المستويات الأربعة لهذه السمعة بشكل عام هي:</p>
            <app-stepper [steps]="evaluationItems" [active]="getLevelIdx(section.level)"></app-stepper>
        </div>
    </div>
    <div class="page">
        <img class="cover" src="assets/organizational-abs/images/2.png" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="header3">ماذا يجب أن نفعل؟</h2>
            <ng-container *ngTemplateOutlet="sectionRecommendationsHelper; context: { section }"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #managementSection let-section="section">
    <div class="page">
        <img class="cover section-image" src="{{ section.image }}" alt="{{ section.name }}" />
        <div class="wrapper"></div>
    </div>

    <div class="page">
        <img class="cover" src="assets/organizational-abs/images/2.png" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="header3">المجال الثالث: الإدارة والعمليات</h2>
            <p>
                درسنا مجال الإدارة والعمليات لديكم من خلال عدة مؤشرات، وحلّلنا بشكل مفصل
                هذه السمات:
            </p>
            <ng-container *ngTemplateOutlet="tableHelper; context: { elements: section.analysis }"></ng-container>
            <p>
                حصلت المنظمة على تقييم %{{ section.score | number : "1.2-2" }} في مجال
                الإدارة والعمليات
            </p>
            <h4>المنظمة في {{ section.level }} في مجال الإدارة والعمليات</h4>
            <p>وتبين من التحليل في هذا المجال ما يلي:</p>
            <p>
                السمة موجودة، وفاعلة جزئياً. وتحتاج المنظمة في هذه المرحلة إلى تعزيز
                قدراتها في ممارسة هذه السمة بشكل أفضل.
            </p>
            <p>علماً أن المستويات الأربعة لهذه السمعة بشكل عام هي:</p>
            <app-stepper [steps]="evaluationItems" [active]="getLevelIdx(section.level)"></app-stepper>
        </div>
    </div>
    <div class="page">
        <img class="cover" src="assets/organizational-abs/images/2.png" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="header3">ماذا يجب أن نفعل؟</h2>
            <ng-container *ngTemplateOutlet="sectionRecommendationsHelper; context: { section }"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #activationSection let-section="section">
    <div class="page">
        <img class="cover section-image" src="{{ section.image }}" alt="{{ section.name }}" />
        <div class="wrapper"></div>
    </div>

    <div class="page">
        <img class="cover" src="assets/organizational-abs/images/2.png" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="header3">المجال الرابع: التفعيل الاجتماعي</h2>
            <p>
                درسنا مجال التفعيل الاجتماعي لديكم من خلال عدة مؤشرات، وحلّلنا بشكل مفصل
                هذه السمات:
            </p>
            <ng-container *ngTemplateOutlet="tableHelper; context: { elements: section.analysis }"></ng-container>
            <p>
                حصلت المنظمة على تقييم %{{ section.score | number : "1.2-2" }} في مجال
                التفعيل الاجتماعي
            </p>
            <h4>المنظمة في {{ section.level }} في مجال التفعيل الاجتماعي</h4>

            <p>وتبين من التحليل في هذا المجال ما يلي:</p>
            <p>
                السمة موجودة، لكن غير فاعلة، أو غير معتمدة. وتحتاج المنظمة في هذه
                المرحلة إلى بناء قدراتها في ممارسة هذه السمة.
            </p>
            <p>علماً أن المستويات الأربعة لهذه السمعة بشكل عام هي:</p>
            <app-stepper [steps]="evaluationItems" [active]="getLevelIdx(section.level)"></app-stepper>
        </div>
    </div>
    <div class="page">
        <img class="cover" src="assets/organizational-abs/images/2.png" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="header3">ماذا يجب أن نفعل؟</h2>
            <ng-container *ngTemplateOutlet="sectionRecommendationsHelper; context: { section }"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #sustainabilitySection let-section="section">
    <div class="page">
        <img class="cover section-image" src="{{ section.image }}" alt="{{ section.name }}" />
        <div class="wrapper"></div>
    </div>

    <div class="page">
        <img class="cover" src="assets/organizational-abs/images/2.png" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="header3">المجال الخامس: الاستدامة</h2>
            <p>
                درسنا مجال الاستدامة لديكم من خلال عدة مؤشرات، وحلّلنا بشكل مفصل هذه
                السمات:
            </p>
            <ng-container *ngTemplateOutlet="tableHelper; context: { elements: section.analysis }"></ng-container>
            <p>
                حصلت المنظمة على تقييم %{{ section.score | number : "1.2-2" }} في مجال
                الاستدامة
            </p>

            <h4>المنظمة في {{ section.level }} في مجال الاستدامة</h4>
            <p>وتبين من التحليل في هذا المجال ما يلي:</p>
            <p>
                السمة موجودة، وفاعلة جزئياً. وتحتاج المنظمة في هذه المرحلة إلى تعزيز
                قدراتها في ممارسة هذه السمة بشكل أفضل
            </p>
            <p>علماً أن المستويات الأربعة لهذه السمعة بشكل عام هي:</p>
            <app-stepper [steps]="evaluationItems" [active]="getLevelIdx(section.level)"></app-stepper>
        </div>
    </div>
    <div class="page">
        <img class="cover" src="assets/organizational-abs/images/2.png" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="header3">ماذا يجب أن نفعل؟</h2>
            <ng-container *ngTemplateOutlet="sectionRecommendationsHelper; context: { section }"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #impactSection let-section="section">
    <div class="page">
        <img class="cover section-image" src="{{ section.image }}" alt="{{ section.name }}" />
        <div class="wrapper"></div>
    </div>

    <div class="page">
        <img class="cover" src="assets/organizational-abs/images/2.png" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="header3">المجال السادس: الأثر الاجتماعي</h2>
            <p>
                درسنا مجال الأثر الاجتماعي لديكم من خلال عدة مؤشرات، وحلّلنا بشكل مفصل
                هذه السمات:
            </p>
            <ng-container *ngTemplateOutlet="tableHelper; context: { elements: section.analysis }"></ng-container>
            <p>
                حصلت المنظمة على تقييم %{{ section.score | number : "1.2-2" }} في مجاال
                الأثر الاجتماعي
            </p>

            <h4>المنظمة في {{ section.level }} في الأثر الاجتماعي</h4>
            <p>وتبين من التحليل في هذا المجال ما يلي:</p>
            <p>
                هذه السمة غير موجودة وتحتاج إلى تأسيس كخطوة أولى، ثم تمكين المنظمة في
                ممارسة هذه السمة.
            </p>
            <p>علماً أن المستويات الأربعة لهذه السمعة بشكل عام هي:</p>
            <app-stepper [steps]="evaluationItems" [active]="getLevelIdx(section.level)"></app-stepper>
        </div>
    </div>
    <div class="page">
        <img class="cover" src="assets/organizational-abs/images/2.png" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="header3">ماذا يجب أن نفعل؟</h2>
            <ng-container *ngTemplateOutlet="sectionRecommendationsHelper; context: { section }"></ng-container>
        </div>
    </div>
</ng-template>
<div class="page">
    <img class="cover" src="assets/organizational-abs/images/2.png" alt="overall-assessment" />
    <div class="wrapper">
        <h2>الخطوة المقبلة</h2>
        <p>
            عمل هذا المقياس كأداة علمية تتمتع بالمصداقية والموثوقية في تقييم قدرات
            الجمعية من جوانب عدّة متكاملة، والخطوة المقبلة هي استثمار نتائج هذا
            المقياس فيما يلي:
        </p>
        <h3>أولا - التقييم المعياري:</h3>
        <p>
            من المهم إعادة تطبيق المقياس مرة أخرى بعد عام من تجربة التقييم هذه، بحيث
            تتمكن الجمعية من متابعة وتقييم تأثير استراتيجيتها على قدراتها. فمثل هذا
            المقياس يمكن استخدامه كمؤشر استراتيجي سنوي يقيم قدرات الجمعية.
        </p>
        <h3>ثانياً - التخطيط الاستراتيجي:</h3>
        <p>
            يعمل هذا المقياس مع الجمعية كمنظم لأولويات العمل والتطوير، والتي ينبغي أن
            تكون وفق الترتيب:
        </p>
        <ul>
            <li>الخطوة الأولى: تطوير القدرات القيادية</li>
            <li>الخطوة الثانية: تطوير قدرات تصميم الحلول</li>
            <li>الخطوة الثالثة: تطور قدرات الإدارة والعمليات</li>
            <li>الخطوة الرابعة: تطوير قدرات التفعيل الاجتماعي</li>
            <li>الخطوة الخامسة: تطوير قدرات الاستدامة</li>
            <li>الخطوة السادسة: تطوير قدرات الأثر الاجتماعي</li>
        </ul>
    </div>
</div>
<!-- RECOMMENDATIONS -->

<ng-template #sectionRecommendationsHelper let-section="section">
    <ng-container [ngSwitch]="section.name">
        <ng-container *ngSwitchCase="'leadership'">
            <ng-container *ngTemplateOutlet="leadershipRecommendations; context: { section }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'solution'">
            <ng-container *ngTemplateOutlet="solutionRecommendations; context: { section }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'management'">
            <ng-container *ngTemplateOutlet="managementRecommendations; context: { section }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'activation'">
            <ng-container *ngTemplateOutlet="activationRecommendations; context: { section }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'sustainability'">
            <ng-container *ngTemplateOutlet="sustainabilityRecommendations; context: { section }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'impact'">
            <ng-container *ngTemplateOutlet="impactRecommendations; context: { section }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault></ng-container>
    </ng-container>
</ng-template>

<ng-template #leadershipRecommendations let-section="section">
    <p>
        المجال القيادي هو أولى الأوليات في المنظمة، ويزداد أهمية الأمر عندما تكون
        المنظمة ناشئة، فكما نعلم ليس المهم المضي قدماً بسرعة، الأهم هو المسير
        بالاتجاه الصحيح (الاستراتيجية)، ووجود من يحدّد هذا الاتجاه الصحيح بالطبع
        (القيادة).
    </p>
    <ng-container *ngIf="section.recommendation === 'l1'">
        <p>
            وكون الجانب القيادي من الأمور التي تحتاج إلى تطوير كما تبين من التحليل،
            فهذا يجعل التطوير القيادي ذو أولوية، لذلك نوصي في هذا الصدد ما يلي:
        </p>
        <ul>
            <li>
                مراجعة وتطوير الرؤية والرسالة، والتحقق من كونها محفزة وواقعية وليست مجرد
                عبارات مكتوبة في الملف التعريفي.
            </li>
            <li>
                مراجعة وتطوير قيم المؤسسة لتكون مؤثراً في ثقافة الفريق، لا مانع من إجراء
                ورشة مع جميع فريق العمل ومراجعة القيم واقتراح قيم جديدة يتفق عليها
                الفريق وتكون مصدراً للإلهام والتأثير.
            </li>
            <li>
                تطوير خطة استراتيجية رشيقة وبسيطة وقابلة للتنفيذ، وتساعد المنظمة على
                تحقيق أهدافها، لا تضع أهدافاً أكبر من مواردكم، ولا أقل، واترك هامشاً
                للمخاطر.
            </li>
            <li>
                مجلس الإدارة عامل حاسم ومحوري في نجاح المنظمة، فاحرص على وجود سياسات
                ضابطة ومحوكمة لعمل مجلس الإدارة، بحيث يتم تفعيل المجلس في دعم المنظمة،
                وبذات الوقت لا تتداخل مهامهم وصلاحياتهم مع الفريق التنفيذي.
            </li>
        </ul>
    </ng-container>
    <ng-container *ngIf="section.recommendation === 'l2'">
        <p>
            وكون الجانب القيادي من الأمور التي تتميز بها منظمتك كما تبين من التحليل،
            فأنت حاجة إلى الاستمرار في تطوير هذا المجال، والمحافظة على ما وصلتم إليه،
            لذلك نوصي في هذا الصدد ما يلي:
        </p>
        <ul>
            <li>
                مراجعة وتطوير الرؤية والرسالة، والتحقق من كونها محفزة وواقعية وليست مجرد
                عبارات مكتوبة في الملف التعريفي.
            </li>
            <li>
                مراجعة وتطوير قيم المؤسسة لتكون مؤثراً في ثقافة الفريق، لا مانع من إجراء
                ورشة مع جميع فريق العمل ومراجعة القيم واقتراح قيم جديدة يتفق عليها
                الفريق وتكون مصدراً للإلهام والتأثير.
            </li>
            <li>
                مراجعة الخطة استراتيجية لتكون رشيقة وبسيطة وقابلة للتنفيذ، وتساعد
                المنظمة على تحقيق أهدافها، لا تضع أهدافاً أكبر من مواردكم، ولا أقل،
                واترك هامشاً للمخاطر.
            </li>
            <li>
                مجلس الإدارة عامل حاسم ومحوري في نجاح المنظمة، فاحرص على مراجعة سياسات
                عمل مجلس الإدارة، بحيث يتم تفعيل المجلس في دعم المنظمة، وبذات الوقت لا
                تتداخل مهامهم وصلاحياتهم مع الفريق التنفيذي.
            </li>
            <li>
                من المهم مراجعة وتطوير سياسات لمختلف أقسام المنظمة، مثل السياسة المالية
                وسياسة قياس وإدارة الأثر.
            </li>
        </ul>
    </ng-container>
</ng-template>
<ng-template #solutionRecommendations let-section="section">
    <p>
        مجال تصميم الحلول يأتي في المرحلة الثانية بعد التأسيس القيادي والاستراتيجي
        للمنظمة. ونقصد بتصميم الحلول تطوير المنتجات والخدمات التي تلبي احتياجات
        المجتمع، والتي يمكن أن تكون على شكل مبادرات ومشاريع.
    </p>
    <ng-container *ngIf="section.recommendation === 'l1'">
        <p>
            وتصميم الحلول ينبغي أن يتم مع المجتمع المستهدف نفسه، لذلك فإن قدرات
            المنظمة في تصميم الحلول ترتبط بقدرتها على الاقتراب من الناس والابتكار
            معهم. ومنظمتك تواجه تحدياً في تطوير قدراتها في تصميم الحلول كما تبين من
            التحليل. لذلك نوصي المنظمة في هذا الصدد بما يلي:
        </p>
        <ul>
            <li>
                تطوير أدوات لفهم احتياجات المستفيدين المستهدفين قبل تخطيط المشروع، بحيث
                تستخدم نتائج فهم الاحتياج في تصميم الحل وتخطيط المشروع، مثل إجراء ورش
                ميدانية مع المستفيدين والتفكير معهم، وإجراء مقابلات معمقة مع الخبراء.
            </li>
            <li>
                في المشاريع الكبيرة، من المهم إجراء تجربة مصغرة على عينة من خدمات
                ومنتجات المشروع مع عينة من المستفيدين، قبل إطلاق المشروع بالكامل، فهذا
                يتيح تطوير المنتجات والخدمات واكتشاف المشاكل فيها سواء في المنتج نفسه،
                أو في طريقة استخدامه، وفي ذلك حفظ للموارد وتعزيز لابتكارية المشروع.
            </li>
            <li>
                النظر المعمق في بنود الرؤية الوطنية، وتحديد بند واحد على الأقل تساهم
                المنظمة في تحقيقه مهما كانت نسبة المساهمة صغيرة، والأخذ بالحسبان لهذه
                المساهمة في تخطيط المنظمة بمختلف مستوياتها، على المستوى الاستراتيجي
                ومستوى المشاريع. فذلك يوسع من النظرة الشمولية للمنظمة والتفكير
                الاستراتيجي بالمجتمع المحيط والمستقبل المقبل.
            </li>
        </ul>
    </ng-container>

    <ng-container *ngIf="section.recommendation === 'l2'">
        <p>
            وتصميم الحلول ينبغي أن يتم مع المجتمع المستهدف نفسه، لذلك فإن قدرات
            المنظمة في تصميم الحلول ترتبط بقدرتها على الاقتراب من الناس والابتكار
            معهم. ومنظمتك لديها قدرات متميزة في تصميم الحلول كما تبين من التحليل. لذا
            فهي بحاجة إلى المحافظة على هذا التميز بالتوازي مع التحسين المستمر. لذلك
            نوصي المنظمة في هذا الصدد بما يلي:
        </p>
        <ul>
            <li>
                تطوير أدوات أكثر عمقاً وتنوعاً لفهم احتياجات المستفيدين المستهدفين قبل
                تخطيط المشروع، بحيث تستخدم نتائج فهم الاحتياج في تصميم الحل وتخطيط
                المشروع، مثل إجراء ورش ميدانية مع المستفيدين والتفكير معهم، وإجراء
                مقابلات معمقة مع الخبراء، إضافة إلى أهمية الاستفادة من تقارير الدراسات
                المنشورة سابقاً حول احتياجات المجتمع وأولوياته.
            </li>
            <li>
                في المشاريع الكبيرة، من المهم إجراء تجربة مصغرة على عينة من خدمات
                ومنتجات المشروع مع عينة من المستفيدين، قبل إطلاق المشروع بالكامل، فهذا
                يتيح تطوير المنتجات والخدمات واكتشاف المشاكل فيها سواء في المنتج نفسه،
                أو في طريقة استخدامه، وفي ذلك حفظ للموارد وتعزيز لابتكارية المشروع.
            </li>
            <li>
                النظر المعمق في بنود الرؤية الوطنية، وتحديد المجالات التي تساهم المنظمة
                في تحقيقها مهما كانت نسبة المساهمة صغيرة، والأخذ بالحسبان لهذه المساهمة
                في تخطيط المنظمة بمختلف مستوياتها، على المستوى الاستراتيجي ومستوى
                المشاريع. فذلك يوسع من النظرة الشمولية للمنظمة والتفكير الاستراتيجي
                بالمجتمع المحيط والمستقبل المقبل.
            </li>
        </ul>
    </ng-container>
</ng-template>
<ng-template #managementRecommendations let-section="section">
    <p>
        بعد أن تتحدد وجهة المنظمة (القيادة)، وتحدّد كيف ستصل إلى هذه الوجهة وتصنع
        الأثر (تصميم الحلول)، المنظمة بحاجة الآن إلى تحويل هذه الرؤية إلى واقع عملي
        من خلال قدرات إدارية وتشغيلية، وهو البعد الثالث في الأهمية والذي نسميه
        الإدارة والعمليات.
    </p>
    <ng-container *ngIf="section.recommendation === 'l1'">
        <p>
            منظمتك تواجه تحديات في مجال الإدارة والعمليات كما تبين من التحليل، لذلك
            نوصي المنظمة في هذا الصدد بما يلي:
        </p>
        <ul>
            <li>
                اعتماد المنظمة على الهيكل الإداري المصفوفي بحيث يمكن للفرد أن يعمل
                مهمتين اثنتين مختلفتين، إحداهما ترتبط بالعمليات مثل التسويق، والأخرى
                ترتبط بإحدى مشاريع المنظمة. مثل هذا النمط يناسب المنظمات غير الربحية
                التي يرتكز جل عملها على المشاريع أكثر من العمليات. وهو ما يساهم في رشاقة
                عمل المنظمة ويعزز من قدراتها في العمليات.
            </li>
            <li>
                من الضروري أن يكون لدى المنظمة الحد الأدنى من التوثيق المالي لأعمالها
                ومشاريعها. وليس بالضرورة أن يكون ذلك مثالياً بوجود محاسب مالي متفرغ
                بالكامل وشراء نظام برمجي محاسبي متخصص. المهم أن يتم إدارة شؤونها المالية
                بأسلوب صحيح، ويتم تطوير ذلك مع الزمن.
            </li>
            <li>
                استخدم مؤشرات الأداء التي تركز على النتائج بالدرجة الأولى، سواء في الخطة
                التشغيلية أو بيئة العمل.
            </li>
        </ul>
    </ng-container>

    <ng-container *ngIf="section.recommendation === 'l2'">
        <p>
            بعد أن تتحدد وجهة المنظمة (القيادة)، وتحدّد كيف ستصل إلى هذه الوجهة وتصنع
            الأثر (تصميم الحلول)، المنظمة بحاجة الآن إلى تحويل هذه الرؤية إلى واقع
            عملي من خلال قدرات إدارية وتشغيلية، وهو البعد الثالث في الأهمية والذي
            نسميه الإدارة والعمليات.
        </p>
        <ul>
            <li>
                مراجعة الهيكل الإداري ليكون أكثر رشاقة بما يضمن استقرار العمليات على
                المدى البعيد مهما كبر حجم العمل، ونوصي في هذا الصدد لاعتماد المنظمة على
                الهيكل الإداري المصفوفي بحيث يمكن للفرد أن يعمل مهمتين اثنتين مختلفتين،
                إحداهما ترتبط بالعمليات مثل التسويق، والأخرى ترتبط بإحدى مشاريع المنظمة.
                مثل هذا النمط يناسب المنظمات غير الربحية التي يرتكز جل عملها على
                المشاريع أكثر من العمليات. وهو ما يساهم في رشاقة عمل المنظمة ويعزز من
                قدراتها في العمليات.
            </li>
            <li>
                إن أكبر تحد يواجه الخطط الاستراتيجية هو التنفيذ، والتنفيذ لا يتحقق إلا
                بجودة خطة تنفيذ رشيقة ترتكز على ما يهم فقط من أنشطة ومخرجات، وتتجنب أي
                نشاط لا يساهم في تحقيق الأهداف الاستراتيجية.
            </li>
            <li>
                لا تغرقوا في استخدام المؤشرات وقياس كل ما يمكن قياسه. ينبغي على المنظمة
                أن تقيس ما يهم فقط ويرتبط بتحقيق الأهداف الاستراتيجية
            </li>
        </ul>
    </ng-container>
</ng-template>
<ng-template #activationRecommendations let-section="section">
    <p>
        المنظمة هي كيان اجتماعي لا ينبغي أن تعمل إلا من خلال دعم مجتمعي متبادل، فهي
        تقدم للمجتمع من خلال الدعم الذي تحصل عليه من المجتمع بنفس الوقت. ومجال
        التفعيل الاجتماعي يأتي في المرحلة الرابعة وفقاً للترتيب المنطقي للقدرات
        المؤسسية. فالمؤسسة حدّدت وجهتها، ثم ابتكرت حلولها، نفّذت وتعلمت، والآن عندما
        تريد أن تتوسع وتعمّق من أثرها، فذلك لا يكون إلا من خلال تفعيل قدرات المجتمع،
        وهو المرحلة الرابعة التي نسميها التفعيل الاجتماعي.
    </p>
    <ng-container *ngIf="section.recommendation === 'l1'">
        <p>
            والمنظمة كما تبين من التحليل لا تفعّل قدرات المجتمع كما ينبغي، لذلك نوصي
            المنظمة في هذا الصدد بما يلي:
        </p>
        <ul>
            <li>
                تنفيذ مشاريع مشتركة مع منظمات أخرى لتحقيق أثر أعمق وأوسع، وفق اتجاهين،
                تشارك عمودي وتشارك أفقي:
                <ul>
                    <li>
                        التشارك العمودي: مثال، مشروع تنمية مواهب اليتيم (قياس احتياجات
                        اليتيم، وتقديم برامج تدريب ورعاية مواهب): المنظمة أ: تقدم دعماً لـ
                        ١٠٠ طفل يتيم، والمنظمة ب: تقدم دعماً لـ ١٠٠ طفل يتيم آخر. الفائدة من
                        ذلك هو: (١) تقاسم التكاليف الإدارية الثابتة (الإدارة المالية،
                        التسويق، دورات تأهيل الفريق، ...)، (٢) تعظيم الأثر الاجتماعي
                        للمشروع، من خلال توسيع دائرة المستفيدين.
                    </li>
                    <li>
                        التشارك الأفقي: مثال، مشروع تنمية مواهب اليتيم (قياس احتياجات
                        اليتيم، وتقديم برامج تدريب ورعاية مواهب): المنظمة أ متخصصة في
                        التحليل والبحوث: تقيم احتياجات الأطفال الأيتام المستهدفين. المنظمة ب
                        متخصصة في برامج التدريب الاجتماعي: تقدم تدريباً لـ ٢٠٠ طفل يتيم.
                        الفائدة من ذلك هو توزع الأدوار بناءً على التخصص، وضمان احترافية أعلى
                        في تنفيذ المشروع نتيجة لتركيز كل منظمة على مجال واحد فقط وتركز
                        مواردها فيه.
                    </li>
                </ul>
            </li>
            <li>
                تفعيل المتطوعين أمر في غاية الأهمية وهو ثروة اجتماعية، فثمة العديد من
                الأفراد الذين يبحثون عن فرص للتطوع، رغبة بفعل الخير وكسباً للخبرات، لذلك
                نوصي بإتاحة فرص التطوع في مختلف المشاريع. ومع تفعيل استقطاب المتطوعين،
                يجب أن يكون هنالك نظام داعم لبيئة التطوع في المنظمة، بحيث توضع لهم عدد
                من الميزات إضافة إلى حقوقهم في العمل، وبالتوازي حتى تتحقق الفائدة
                المرجوة من تطوعهم، لا بدّ أيضاً من تحديد واجباتهم ومسؤولياتهم في العمل.
            </li>
        </ul>
    </ng-container>

    <ng-container *ngIf="section.recommendation === 'l2'">
        <p>
            والمنظمة كما تبين من التحليل متميزة في تفعيل قدرات المجتمع وتحتاج إلى
            تطوير تلك القدرات لضمان استدامتها، لذلك نوصي المنظمة في هذا الصدد بما يلي:
        </p>
        <ul>
            <li>
                توسع فرص التشارك مع منظمات أخرى لتحقيق أثر أعمق وأوسع، وفق اتجاهين،
                تشارك عمودي وتشارك أفقي:
                <ul>
                    <li>
                        التشارك العمودي: مثال، مشروع تنمية مواهب اليتيم (قياس احتياجات
                        اليتيم، وتقديم برامج تدريب ورعاية مواهب): المنظمة أ: تقدم دعماً لـ
                        ١٠٠ طفل يتيم، والمنظمة ب: تقدم دعماً لـ ١٠٠ طفل يتيم آخر. الفائدة من
                        ذلك هو: (١) تقاسم التكاليف الإدارية الثابتة (الإدارة المالية،
                        التسويق، دورات تأهيل الفريق، ...)، (٢) تعظيم الأثر الاجتماعي
                        للمشروع، من خلال توسيع دائرة المستفيدين.
                    </li>
                    <li>
                        التشارك الأفقي: مثال، مشروع تنمية مواهب اليتيم (قياس احتياجات
                        اليتيم، وتقديم برامج تدريب ورعاية مواهب): المنظمة أ متخصصة في
                        التحليل والبحوث: تقيم احتياجات الأطفال الأيتام المستهدفين. المنظمة ب
                        متخصصة في برامج التدريب الاجتماعي: تقدم تدريباً لـ ٢٠٠ طفل يتيم.
                        الفائدة من ذلك هو توزع الأدوار بناءً على التخصص، وضمان احترافية أعلى
                        في تنفيذ المشروع نتيجة لتركيز كل منظمة على مجال واحد فقط وتركز
                        مواردها فيه.
                    </li>
                </ul>
            </li>
            <li>
                توسيع الاستفادة من المتطوعين، وتنظيم بيئة العمل للمتطوعين، فمن المهم أن
                يكون هنالك نظام داعم لبيئة التطوع في المنظمة، بحيث توضع لهم عدد من
                الميزات إضافة إلى حقوقهم في العمل، وبالتوازي حتى تتحقق الفائدة المرجوة
                من تطوعهم، لا بدّ أيضاً من تحديد واجباتهم ومسؤولياتهم في العمل.
            </li>
            <li>
                تطوير محتوى شبكات التواصل الاجتماعي لتكون أكثر تفاعلية، وليس مجرد قنوات
                للنشر، بحيث تستثمر المنظمة وجودها في المنصات الرقمية في التفاعل مع
                المجتمع والاستماع إليهم وطرح القضايا التي يستفيد منها المجتمع والمنظمة
                أيضاً على حدّ سواء.
            </li>
        </ul>
    </ng-container>
</ng-template>
<ng-template #sustainabilityRecommendations let-section="section">
    <p>
        الاستدامة المالية لا تأتي من كثرة المشاريع وحجمها، الاستدامة المالية ترتبط
        بقدرة المنظمة على الاستمرار في العمل حتى مع توقف المشاريع. فالاستدامة
        المالية تعكس قدرة المنظمة على صنع الأثر دون وجود دعم خارجي مباشر.
    </p>
    <ng-container *ngIf="section.recommendation === 'l1'">
        <p>
            والمنظمة على ما يبدو لم تولي اهتماماً كبيراً حتى الآن لتطوير قدراتها في
            الاستدامة كما تبين من نتائج التحليل، ولم يفت الأوان بعد، لدى المنظمة فرص
            عديدة لتعزيز استدامتها المالية عبر عدد من الأفكار والتوصيات:
        </p>
        <ul>
            <li>
                ليس هنالك أدنى مشكلة في أن تفكر المنظمة بعقلية استثمارية، فلا مانع من
                تخصيص نسبة من مواردها في امتلاك أصول استثمارية كالأسهم والعقارات
                والصكوك.
            </li>
            <li>
                استفد من الوفرة الإدارية التي تستطيع ادخارها من تنفيذ المشاريع، مهما
                كانت نسبتها صغيرة، لكن مع تراكمها في نهاية العام ستحقق وفرة جيدة يمكنك
                استثمارها في امتلاك أصول للمنظمة.
            </li>
            <li>
                تعزيز علاقتك مع الداعمين الحاليين أولى من البحث عن داعمين جدد، خصوصاً
                إذا تمكنت من بناء تجربة شراكة ناجحة وشكلوا عنك سمعة حسنة، وتعزيز علاقتك
                مع الداعمين يكون من عدة طرق متكاملة، أولها وأهمها هو كفاءة التنفيذ
                وفاعلية الأثر، وثانيها التواصل الفعال مع الداعم بالطرق والقنوات التي
                يفضلها، وتزويده بالمعلومات التي يفضلها أيضاً مثل تقارير قياس الأثر
                وغيرها.
            </li>
            <li>
                استثمر الموارد المتاحة لديكم الآن بأفكار استثمارية ما أمكن، كمثال إذا
                كان لديكم مساحة في مكتبكم غير مستغلة، بإمكانك استخدامها لأغراض تجارية
                وفقاً للمساحة المتاحة.
            </li>
        </ul>
    </ng-container>

    <ng-container *ngIf="section.recommendation === 'l2'">
        <p>
            والمنظمة على ما يبدو تول اهتماماً للاستدامة كما تبين من نتائج التحليل،
            ولديها فرص عديدة لتعزيز استدامتها المالية واستقرارها المالي عبر عدد من
            الأفكار والتوصيات:
        </p>
        <ul>
            <li>
                إذا لم تكن المنظمة قد أطلقت حتى الآن مشاريع استثمار اجتماعي فقد حان
                الوقت، ينبغي على المنظمة أن تطلق مشاريع ومبادرات استثمار اجتماعي تشتمل
                على تقديم أثر مع وجود ربحية، والاستفادة من العديد من الأفكار الملهمة حول
                العالم من قبيل مفهوم (One for One واحدة بواحدة)، وهو كمثال أن كل زبون
                يشتري من هذا الكتاب، سيحصل شخص آخر ذو احتياج شديد على كتاب أيضاً. أو
                مفهوم البيع بسعر التكلفة لأصحاب الاحتياج الشديد، مع البيع بسعر السوق
                للأشخاص العاديين، وهكذا.
            </li>
            <li>
                تعزيز علاقتك مع الداعمين الحاليين أولى من البحث عن داعمين جدد، خصوصاً
                إذا تمكنت من بناء تجربة شراكة ناجحة وشكلوا عنك سمعة حسنة، وتعزيز علاقتك
                مع الداعمين يكون من عدة طرق متكاملة، أولها وأهمها هو كفاءة التنفيذ
                وفاعلية الأثر، وثانيها التواصل الفعال مع الداعم بالطرق والقنوات التي
                يفضلها، وتزويده بالمعلومات التي يفضلها أيضاً مثل تقارير قياس الأثر
                وغيرها.
            </li>
            <li>
                تأكد أن الاستدامة ليست مجرد مسار نشط لدى المنظمة، ينبغي أن تكون ضمن
                الأهداف الاستراتيجية للمنظمة وتعمل ضمن خطة محددة مسبقاً لتعزيز قدراتها
                في الاستدامة المالية.
            </li>
        </ul>
    </ng-container>
</ng-template>
<ng-template #impactRecommendations let-section="section">
    <p>
        الحديث عن الأثر الاجتماعي لا يقتصر على القياس وتقرير أثر المشروع، فالأثر هو
        سبب وجود المنظمة، وسبب دعمها، فإذا لم يكن لمنظمتك أثر في المجتمع، ما الداعي
        لدعمها؟ فالداعم لا يعنيه كثيرا حسن التواصل من منظمتك، ولا كثرة تداول اسمها
        في منصات التواصل الاجتماعي. فتنفيذ برامج مجتمعية لا أثر لها يعني ببساطة أن
        التبرعات تضيع سدى، فإذاً فالأثر قضية استراتيجية وليس فقط قضية قياس وتحليل.
    </p>
    <ng-container *ngIf="section.recommendation === 'l1'">
        <p>
            ومنظمتك على ما يبدو تحتاج إلى تطوير قدراتها بشكل كبير في مجال الأثر
            الاجتماعي كما تبين من التحليل، ولذلك نوصي لهذا الصدد ما يلي:
        </p>
        <ul>
            <li>
                جعل الأثر الاجتماعي قضية استراتيجية، بدءاً من مراجعة الرؤية والرسالة
                لتكون بوصلة موجهة لصنع الأثر، ثم الأهداف الاستراتيجية المرتبطة
                بالمستفيدين والمشاريع، ثم تطوير مؤشرات استراتيجية في الأثر الاجتماعي،
                والتي ستكون قاعدة لقياس وإدارة الأثر.
            </li>
            <li>
                اتباع منهجيات رشيقة في تصميم بيانات قياس الأثر، كالاعتماد على استبيانات
                قصيرة ومركزة ما أمكن، واستخدام أقل عدد ممكن من المؤشرات.
            </li>
            <li>
                إصدار تقرير سنوي للمنظمة عن الأثر الاجتماعي، التقرير الأول سيستغرق وقتاً
                وجهداً بالطبع، غير أن الجهد والوقت في الإصدارات اللاحقة للتقرير سيقل.
                مثل هذه التقارير تساعد المنظمة على فهم الأثر الذي أحدثته، وإدارته
                وتعظيمه، وتساعدها على تخطيط الأثر للعام المقبل وتحديد ما يجب فعله، وبذات
                الوقت نشر هذه التقارير مع المعنيين سيعزز من علاقة المنظمة مع شركائها من
                المجتمع والمانحين وغيرهم.
            </li>
        </ul>
    </ng-container>

    <ng-container *ngIf="section.recommendation === 'l2'">
        <p>
            ومنظمتك على ما يبدو قد قطعت أشواطاً مهمة في المتابعة وتقييم الأثر، وتحتاج
            إلى تعزيز قدراتها في هذا الجانب، ولذلك نوصي لهذا الصدد ما يلي:
        </p>
        <ul>
            <li>
                جعل الأثر الاجتماعي قضية استراتيجية، بدءاً من مراجعة الرؤية والرسالة
                لتكون بوصلة موجهة لصنع الأثر، ثم الأهداف الاستراتيجية المرتبطة
                بالمستفيدين والمشاريع، ثم تطوير مؤشرات استراتيجية في الأثر الاجتماعي،
                والتي ستكون قاعدة لقياس وإدارة الأثر.
            </li>
            <li>
                اتباع منهجيات رشيقة في تصميم بيانات قياس الأثر، كالاعتماد على استبيانات
                قصيرة ومركزة ما أمكن، واستخدام أقل عدد ممكن من المؤشرات.
            </li>
            <li>
                إصدار تقرير سنوي للمنظمة عن الأثر الاجتماعي، التقرير الأول سيستغرق وقتاً
                وجهداً بالطبع، غير أن الجهد والوقت في الإصدارات اللاحقة للتقرير سيقل.
                مثل هذه التقارير تساعد المنظمة على فهم الأثر الذي أحدثته، وإدارته
                وتعظيمه، وتساعدها على تخطيط الأثر للعام المقبل وتحديد ما يجب فعله، وبذات
                الوقت نشر هذه التقارير مع المعنيين سيعزز من علاقة المنظمة مع شركائها من
                المجتمع والمانحين وغيرهم.
            </li>
        </ul>
    </ng-container>
</ng-template>