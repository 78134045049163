import { CommonModule } from '@angular/common';
import { Component, computed, input, viewChild, ViewEncapsulation } from '@angular/core';
import { GradeViewerComponent } from '../../components/grade-viewer/grade-viewer.component';

@Component({
    selector: 'specialized-skills-report',
    templateUrl: './specialized-skills-report.component.html',
    styleUrls: ['../../rise-index-report.component.scss'],
    standalone: true,
    imports: [CommonModule, GradeViewerComponent],
    encapsulation: ViewEncapsulation.None,
})
export class SpecializedSkillsReportComponent {
    groupName = 'الجدارات المهارية التخصصية';
    report = input.required<any>();
    total = computed(() => this.report()['specialized_skills']);

    se_engineering = viewChild('se_engineering');
    model_design = viewChild('model_design');
    fin_sustainability = viewChild('fin_sustainability');
    commercial_int = viewChild('commercial_int');
    impact = viewChild('impact');

    skills = input.required({
        transform: (v: { name: string; variable: string }[]) => {
            return v.map((skill) => {
                return { name: skill.name, variable: skill.variable, template: this[skill.variable] };
            });
        },
    });
}
