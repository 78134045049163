import { Component, inject, Input } from '@angular/core';
import { AuthService } from '@upupa/auth';

import { ReportViewModel } from '../../../../shared/types';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { StepperComponent } from '../stepper/stepper.component';

export class Report {
    evaluations!: SamModel[];
}
declare const google;

export const LEVELS = ['التأسيس', 'الممارسة', 'التطور', 'الاحترافية'] as const;
export type Level = (typeof LEVELS)[number];

export type SamModel = {
    name: string;
    ar_name: string;
    image: string;
    analysis: Record<string, string[]>;
    level: Level;
    score: number;
    recommendation?: 'l1' | 'l2';
};

@Component({
    selector: 'npos-full-report',
    templateUrl: './npos-full-report.component.html',
    styleUrls: ['./npos-full-report.component.scss'],
    standalone: true,
    imports: [CommonModule, StepperComponent],
})
export class NPOsFullReportComponent {
    static __name = 'npos-full-report';

    private readonly http = inject(HttpClient);
    private readonly route = inject(ActivatedRoute);
    private readonly auth = inject(AuthService);

    static async updateResult(v: ReportViewModel, http: HttpClient, user = null): Promise<any> {
        const result = generateResponseReport(v);
        try {
            await firstValueFrom(
                http.post(`${environment.base_url}/tfr/update-result`, {
                    responseId: v._id,
                    result: { ...result, score: `${result.score ?? 0}` },
                }),
            );
            return result;
        } catch (err) {
            console.error(err);
        }
    }

    level: Level = 'الاحترافية';
    report = null;
    allSections = [];

    private _reportViewModel: ReportViewModel;
    @Input()
    public get reportViewModel(): ReportViewModel {
        return this._reportViewModel;
    }
    public set reportViewModel(value: ReportViewModel) {
        this._reportViewModel = value;
        if (!value) return;
        this._fillResult();
    }

    private async _fillResult() {
        let result = this.reportViewModel.result;

        if (!result) {
            console.warn(`${NPOsFullReportComponent.name} has no result to be displayed`);
            result = await NPOsFullReportComponent.updateResult(this.reportViewModel, this.http);
        }

        this.report = {
            ...REPORT,
            ...result,
            recommendation: this.levelRecommendation(result.level),
            evaluations: REPORT.evaluations.map((e) => ({
                ...e,
                level: result.fields[e.name],
                score: +result.fields[`${e.name}Score`],
                recommendation: this.levelRecommendation(result.fields[e.name]),
            })),
        };

        this.allSections = this.report.evaluations;
        this.drawChart(this.report);
    }

    drawChart(rawData) {
        google.charts.load('current', { packages: ['corechart'] });
        const chartData = rawData.evaluations
            .slice()
            .sort((e1, e2) => e2.score - e1.score)
            .map((e) => [e.ar_name, +e.score, '#03989e', `${e.score}%`]);
        google.charts.setOnLoadCallback(() => drawChart(chartData));
        function drawChart(d) {
            const data = google.visualization.arrayToDataTable([['المجال', 'المستوى', { role: 'style' }, { role: 'annotation' }], ...d]);

            const options = {
                title: 'تقييم الأبعاد الستة لقدرات الجمعية',
                legend: { position: 'none' },
                enableInteractivity: false,
                fontName: 'Noto Sans Arabic',
                height: '100%',
                hAxis: {
                    minValue: 0,
                    maxValue: 100,
                    ticks: [0, 25, 50, 75, 100],
                },
            };

            const chart = new google.visualization.BarChart(document.getElementById('result-chart'));
            chart.draw(data, options);
        }
    }

    levelRecommendation(level: string) {
        const ls = {
            'مرحلة التأسيس': 'l1',
            'مرحلة الممارسة': 'l2',
            'مرحلة التطور': 'l3',
            'مرحلة الاحترافية': 'l4',
        };

        return ls[level];
    }

    note: { [level: string]: string } = {
        'مرحلة التأسيس': 'هذه السمة غير موجودة وتحتاج إلى تأسيس كخطوة أولى، ثم تمكين الجمعية في ممارسة هذه السمة.',
        'مرحلة الممارسة': 'هذه السمة غير موجودة وتحتاج إلى تأسيس كخطوة أولى، ثم تمكين الجمعية في ممارسة هذه السمة.',
        'مرحلة التطور': 'السمة موجودة، وفاعلة جزئياً. وتحتاج الجمعية في هذه المرحلة إلى تعزيز قدراتها في ممارسة هذه السمة بشكل أفضل.',
        'مرحلة الاحترافية': 'السمة موجودة وفاعلة أو مطبقة لدى الجمعية، وتحتاج الجمعية إلى تمكينها للمحافظة على هذه السمة.',
    };

    getSectionNote(section: SamModel): string {
        return this.note[section.level];
    }

    getElementValues(values: unknown): string[] {
        return values as string[];
    }

    getLevelIdx = (level: Level) => LEVELS.findIndex((l) => level.indexOf(l) > -1);

    evaluationItems: string[] = ['مرحلة التأسيس', 'مرحلة الممارسة', 'مرحلة التطور', 'مرحلة الاحترافية'];
}

const REPORT: Report = {
    evaluations: [
        {
            name: 'leadership',
            ar_name: 'القيادة',
            image: 'assets/organizational-abs/images/evaluation1.png',
            level: 'التأسيس',
            score: 0,
            analysis: {
                'التوجه والقيم': ['تعمل وفق رسالة ورؤية مفزة', 'تعمل وفق قيم مؤثرة'],
                'الإدارة والاستراتيجة': ['تعمل وفق نظام مؤشرات للأداء', 'تعمل وفق خطة استراتيجية'],
                الحوكمة: ['تعمل وفق سياسات حوكمة فاعلة', 'تمتلك مجلس إدارة فعال'],
            },
        },
        {
            name: 'solution',
            ar_name: 'تصميم الحلول',
            image: 'assets/organizational-abs/images/evaluation2.png',
            level: 'الاحترافية',
            score: 0,

            analysis: {
                'تقييم الاحتياجات المستقبلية للمستقبل': ['تستخدم طرقاً متكاملة لفهم احتياجات المجتمع. ', 'النظرة الشمولية للاحتياجات الوطنية'],
                'الابتكار المجتمعي': ['تطوّر المنظمة حلولها مع المجتمع المستهدف ', 'تختبر الحلول مع المجتمع قبل الشروع في إطلاق البرامج '],
                'تصميم البرامج والمشاريع المجتمعية': ['تستخدم منهجيات احترافية في تخطيط المشاريع التنموية '],
            },
        },
        {
            name: 'management',
            ar_name: 'الإدارة والعمليات',
            analysis: {
                'الموارد البشرية': ['لدى المنظمة ما تحتاجه من الكفاءات الإدارية لتحقيق أهدافها. ', 'تعمل المنظمة وفق بناء مؤسسي واضح. ', 'تنوع الكادر الإداري '],
                'الإدارة المالية': ['تقوم المنظمة بتوثيق بياناتها المالية. ', 'توائم المنظمة خطتها المالية مع الخطة التشغيلية. ', 'تحقق كفاءة في الإنفاق الإداري. '],
                'التخطيط التشغيلي ': ['تضع المنظمة خطة تشغيلية لأعمالها لمدة عام. ', 'تضع خطة متابعة تنفيذ الخطة من خلال مؤشرات أداء. '],
                'تضع خطة متابعة تنفيذ الخطة من خلال مؤشرات أداء.': ['تمتلك المنظمة بيئة عمل مناسبة لحجم أعمالها. ', 'تمتلك بنية تقنية تلبي احتياج وحجم أعمالها. '],
            },
            level: 'التطور',
            score: 0,

            image: 'assets/organizational-abs/images/evaluation3.png',
        },
        {
            name: 'activation',
            ar_name: 'التفعيل الاجتماعي',

            analysis: {
                'التكامل مع الشركاء ': ['تنفذ مشاريع مشتركة مع شركاء (مكمّلين أو متشابهين) ', 'تنوع المنظمة شركائها '],
                'الدعم من المجتمع ': ['تتواصل بفاعلية مع مختلف فئات المجتمع ', 'تستخدم منصات رقمية تتناسب مع الفئة المستهدفة '],
                'إدارة التطوع ': ['تستثمر المتطوعين ', 'يستفيد المتطوعون من عملهم مع المنظمة '],
            },

            level: 'التطور',
            score: 0,

            image: 'assets/organizational-abs/images/evaluation4.png',
        },
        {
            name: 'sustainability',
            ar_name: 'الاستدامة',

            analysis: {
                'إدارة العلاقة مع المانحين ': ['تتوافق المنظمة مع توجهات المانحين. ', 'للمنظمة علاقة مستقرة مع المانحين. '],
                'استدامة مصادر التمويل ': ['تنوع مصادر الدعم الخارجي ', 'تمتلك استثمارات وأوقاف '],
                'إدارة الاستدامة ': ['تضع الاستدامة كتوجه استراتيجي ', 'إدارة مخاطر الاستدامة '],
            },

            level: 'التطور',
            score: 0,

            image: 'assets/organizational-abs/images/evaluation5.png',
        },
        {
            name: 'impact',
            ar_name: 'الأثر الاجتماعي',
            analysis: {
                'المتابعة والتقييم والتعلم والمساءلة ': ['استخدام المتابعة والتقييم في تنفيذ المشاريع المجتمعية والتعلم منها', 'إدارة الشكاوى'],
                'إدارة الأثر ': ['تنفيذ قياس الأثر الاجتماعي ', 'الموازنة بين الأثر والأداء المالي '],
            },

            level: 'التطور',
            score: 0,

            image: 'assets/organizational-abs/images/evaluation6.png',
        },
    ],
};

type LEVEL = 'مرحلة التأسيس' | 'مرحلة الممارسة' | 'مرحلة التطور' | 'مرحلة الاحترافية';
type ShortRerportContext = {
    name: string;
    score: number;
    level: LEVEL;
    foundation: boolean;
    practice: boolean;
    development: boolean;
    professional: boolean;
};

function generateResponseReport(vm: ReportViewModel): any {
    const toLevel = (per: number) => {
        if (per <= 25) return 'مرحلة التأسيس' as LEVEL;
        else if (per > 25 && per <= 55) return 'مرحلة الممارسة' as LEVEL;
        else if (per > 55 && per <= 85) return 'مرحلة التطور' as LEVEL;
        return 'مرحلة الاحترافية' as LEVEL;
    };
    const MAXes = {
        activation: 42,
        impact: 21,
        leadership: 35,
        management: 48,
        solution: 20,
        sustainability: 28,
    };
    const MAX = Object.getOwnPropertyNames(MAXes)
        .map((k) => MAXes[k])
        .reduce((a, b) => a + b, 0);

    const variables = vm.variables.filter((v) => v.type === 'number').map((v) => ({ ...v, number: Number(v.number ?? 0) }));

    const toFixedNumber = (n: number, d = 2) => Number(n.toFixed(d));
    const score = toFixedNumber((100 * variables.map((v) => v.number).reduce((a, acc) => acc + a, 0)) / MAX);

    const level = toLevel(score);

    const fields = variables
        .filter((v) => v.key in MAXes)
        .map((v) => {
            const n = toFixedNumber((100 * v.number) / MAXes[v.key]);
            return {
                [v.key]: toLevel(n),
                [`${v.key}Score`]: n,
            };
        })
        .reduce((a, acc) => ({ ...a, ...acc }), {});

    return {
        name: vm.user.name,
        score,
        level,
        development: level === 'مرحلة التطور',
        foundation: level === 'مرحلة التأسيس',
        practice: level === 'مرحلة الممارسة',
        professional: level === 'مرحلة الاحترافية',
        fields,
    } as ShortRerportContext;
}
