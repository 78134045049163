@if (adapter.items$ | async; as items) {
    <div class="list" [@listAnimation]="items.length">
        @for (item of items; track item) {
            <ng-container *ngTemplateOutlet="cardTemplate(); context: { item, adapter }"></ng-container>
        } @empty {
            <p>No Data</p>
        }
    </div>
}

@if ((adapter.loading$ | async) === true) {
    <p>loading...</p>
} @else if (adapter.error !== null) {
    <p>{{ adapter.error }}</p>
}
