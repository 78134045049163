<div class="forms-filter">
    <!-- <dynamic-form [fields]="fields" [name]="'forms-filter'" [value]="value" (valueChange)="value = $event"></dynamic-form> -->
    <!-- <h5 class="mbe-1">البحث</h5>
    <mat-form-field class="mbe-1" appearance="fill">
        <mat-label>بحث</mat-label>
        <input matInput [(ngModel)]="search">
        <button *ngIf="search" matSuffix mat-icon-button aria-label="احذف" (click)="search=''">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>

  
    <h5 class="mbe-1">التقييم</h5>
    <mat-radio-group class="rating-buttons mbe-1" [(ngModel)]="rating">
        <mat-radio-button class="rating-button" color="primary" *ngFor="let r of ratingOptions" [value]="r"><span class="star">&#9733;</span> {{r}} نجمة</mat-radio-button>
    </mat-radio-group>
 

    <h5 class="mbe-1">موجه لـ</h5>
   
    <mat-radio-group class="mbe-1" [(ngModel)]="mode">
        <mat-radio-button color="primary" *ngFor="let option of modeOptions" [value]="option">{{ option
            }}</mat-radio-button>
    </mat-radio-group>

   

    <h5 class="mbe-1">السعر</h5>

    <div id="price" class="mbe-1">
        <mat-form-field appearance="fill">
            <input matInput type="number" placeholder="أقل سعر" [(ngModel)]="priceMin">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <input matInput type="number" placeholder="أعلى سعر" [(ngModel)]="priceMax">
        </mat-form-field>
        <mat-icon>monetization_on
        </mat-icon>
    </div> -->

    <button mat-raised-button color="primary" (click)="applyFilter()">ابحث</button>
</div>