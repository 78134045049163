import { BehaviorSubject, Observable, ReplaySubject, debounceTime, firstValueFrom } from "rxjs"

export type Query = Record<string, any>
export type SDataListEvent = { event: string, params?: Record<string, any> }
export type SDataListItemEvent<T> = { event: string, items: T[] }

export interface IDataListAdapter<T = any, Q extends Query = Query> {
    /*
        loading -> init, filter change, page change(paginator or infinite scroll)
        no data -> !loading and data length === 0
        data  -> !loading and data length > 0
        error -> !loading and Error
    */

    readonly items$: Observable<T[]>
    readonly loading$: Observable<boolean>
    readonly total$: Observable<number>
    readonly query$: Observable<Partial<Q>>
    readonly error$: Observable<Record<string, any> | null>

    readonly items: T[]
    readonly loading: boolean
    readonly total: number
    readonly query: Partial<Q>
    readonly error: Record<string, any> | null

    onError(error: Error | Record<string, any>): void
    fetch(query?: Partial<Q>): void | Promise<void>
    reset(): void
    triggerListItemEvent(e: SDataListItemEvent<T>): void

    events$: Observable<SDataListEvent | SDataListItemEvent<T>>
}

export interface IDataListDataSource<T = any, Q extends Query = Query> {
    fetch(query?: Partial<Q>): Observable<{ data: T[], total?: number, query?: Partial<Q> }>
}

export class DataListAdapter<T = any, Q extends Query = Query> implements IDataListAdapter<T, Q>{

    private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject(false)
    private readonly _items$: ReplaySubject<T[]> = new ReplaySubject(1)
    private readonly _total$: BehaviorSubject<number> = new BehaviorSubject(0)
    private readonly _query$: BehaviorSubject<Partial<Q>> = new BehaviorSubject({})
    private readonly _error$: BehaviorSubject<any | null> = new BehaviorSubject(null)

    items$ = this._items$.asObservable()
    loading$ = this._loading$.pipe(debounceTime(50))
    total$ = this._total$.asObservable()
    query$ = this._query$.asObservable()
    error$ = this._error$.asObservable()

    items: T[] = []
    loading!: boolean
    total!: number
    query!: Partial<Q>
    error: Record<string, any> | null = null

    private readonly _events$: ReplaySubject<SDataListEvent | SDataListItemEvent<T>> = new ReplaySubject(1)
    events$ = this._events$.asObservable()

    constructor(private dataSource: IDataListDataSource<T, Q>) {
        this._items$.subscribe(i => this.items = i)
        this._total$.subscribe(t => this.total = t)
        this._loading$.subscribe(l => this.loading = l)
        this._query$.subscribe(q => this.query = q)
    }




    onError(error: Record<string, any>): void {
        this._error$.next(error)
        console.error(error);
    }
    reset(): void {
        this._loading$.next(false)
        this._items$.next([])
        this._total$.next(0)
        this._query$.next({})

        this._events$.next({ event: 'reset' })
    }

    triggerListItemEvent = (e: SDataListItemEvent<T>) => this._events$.next(e)
    async fetch(query?: Partial<Q>, append = false) {
        this._loading$.next(true)
        try {
            const res = await firstValueFrom(this.dataSource.fetch(query))
            if (append === true) {
                this.items.push(...res.data)
                this._items$.next(this.items)
            }
            else this._items$.next(res.data)

            this._events$.next({ event: 'fetched', items: res.data })
            this._total$.next(res.total || res.data.length)
            this._query$.next(res.query ?? query ?? {})
        } catch (error: any) {
            this.onError(error)
        }
        finally {
            this._loading$.next(false)
        }
    }
}