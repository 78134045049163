import { ChangeDetectionStrategy, Component, input, Input, signal, SimpleChange, SimpleChanges } from "@angular/core";
import { Subject } from "rxjs";
import { ReportViewModel } from "../../shared/types";
import { NPOsFullReportComponent } from "../reports/orgs/npos-full-report/npos-full-report.component";
import { ReadingReportComponent } from "../reports/orgs/reading-report/reading-report.component";
import { AuthService } from "@upupa/auth";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { RiseIndexReportComponent } from "../reports/orgs/rise-index-report/rise-index-report.component";

@Component({
    selector: "report-viewer",
    templateUrl: "./report-viewer.component.html",
    styleUrls: ["./report-viewer.component.scss"],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NPOsFullReportComponent, ReadingReportComponent, RiseIndexReportComponent],
})
export class ReportViewerComponent {
    loading = signal(true);
    name = signal(undefined);

    reportViewModel = input.required<ReportViewModel>();

    private async _updateResult() {
        const vm = this.reportViewModel();
        if (!vm) return;
        this.loading.set(false);

        const access_token = this.route.snapshot.queryParams.access_token;
        const name = vm.report?.name ?? "";
        this.name.set(name);

        try {
            if (access_token) {
                const availableReports = [NPOsFullReportComponent, ReadingReportComponent, RiseIndexReportComponent];
                const user = this.auth.jwt(access_token);
                if (access_token && user) {
                    const reportComp = availableReports.find((r) => r.__name === name);
                    if (reportComp) await reportComp.updateResult(this.reportViewModel(), this.http, user);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            this.loading.set(true);
        }
    }

    destroyed$ = new Subject();
    constructor(
        private readonly http: HttpClient,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly auth: AuthService,
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes["reportViewModel"]) {
            this._updateResult();
        }
    }
}
