import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';
import { AuthService } from '@upupa/auth';
import { LanguageService } from '@upupa/language';

@Injectable({
  providedIn: 'root',
})
export class AdminGuardService implements CanActivate {
  constructor(
    public auth: AuthService,
    private ls: LanguageService,
    public router: Router,
    private route: ActivatedRoute
  ) {}
  canActivate(): boolean {
    const user = this.auth.user;
    if (
      user &&
      user.roles &&
      user.roles.some((r) => r === 'admin' || r === 'super-admin')
    )
      return true;
    this.router.navigate(
      ['/', this.ls.language ?? this.ls.defaultLang, 'account', 'login'],
      {
        queryParams: {
          redirect: `/${this.ls.language ?? this.ls.defaultLang}/admin`,
        },
      }
    );
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: AuthService,
    private ls: LanguageService,
    public router: Router,
    private route: ActivatedRoute
  ) {}
  canActivate(): boolean {
    const user = this.auth.user;
    if (user) return true;
    const url = new URL(location.href);
    this.router.navigate(
      ['/', this.ls.language ?? this.ls.defaultLang, 'account', 'login'],
      { queryParams: { redirect: location.href.replace(url.origin, '') } }
    );
    return false;
  }
}
