import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from '@upupa/language';
import { filter, map, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { AuthService } from '@upupa/auth';
import { DataService } from '@upupa/data';
import { FormsListHttpDataAdapter, FormsListPageAdapter } from '../forms-list-adapter.service';
import { SDataListItemEvent } from '@s-data-list';
import { ReviewCardComponent, ReviewCardVm, defaultSubscriber } from '../../shared/review-card/review-card.component';
import { SnackBarService } from '@upupa/dialog';
import { CommonModule } from '@angular/common';
import { TopHeaderComponent } from '../../shared/top-header/top-header.component';
import { SSwiperModule } from '@s-swiper';
import { MatIconModule } from '@angular/material/icon';
import { RatingStarsComponent } from '../../shared/rating-stars/rating-stars.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'form-details',
    templateUrl: './form-details-page.component.html',
    styleUrls: ['./form-details-page.component.scss'],
    standalone: true,
    imports: [TopHeaderComponent, SSwiperModule, CommonModule, MatIconModule, ReviewCardComponent, RatingStarsComponent, MatProgressBarModule, MatExpansionModule],
})
export class FormDetailsPageComponent implements OnInit {
    similarSwiperConfig = similarSwiperConfig;

    private readonly data = inject(DataService);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    public readonly ls = inject(LanguageService);
    private readonly auth = inject(AuthService);
    private readonly snack = inject(SnackBarService);
    public readonly adapter = inject(FormsListPageAdapter);

    form$: Observable<any> = this.route.params.pipe(
        map((ps) => ps['slug']),
        switchMap((slug) => this.data.get<any>(`form/${slug}?select=created_at,name,reports,published_at,status,title,settings.language,settings.meta`)),
        map((res) => res),
        tap((res) => {
            this.adapter.fetch({ form: res._id } as any);
        }),
    );
    readonly breadcrumb$ = this.form$.pipe(
        filter((f) => f),
        map((f) => [
            { name: 'robots', display: 'قائمة الروبوتس' },
            { name: 'robot', display: f.title },
        ]),
    );

    //todo: remove this mock author
    author = {
        image: 'https://cdn0.iconfinder.com/data/icons/avatar-profession-3/512/Avatar_29_-_Professions_-_Background_-_Copy-09-512.png',
        title: 'الباحثة الدكتورة سارة عبد الرحمن',
        rating: 2.25,
        subtitle: 'تمتلك الدكتورة سارة خبرة واسعة في علوم النفس وعلم البيانات',
    };
    author$ = this.form$.pipe(
        switchMap((f) => (f.author ? this.data.get<any>(`profile/${f.author._id}`) : of(this.author))),
        startWith(this.author),
    );
    review: ReviewCardVm = defaultSubscriber;

    query = { page: 1, per_page: 1 };
    set page(page: number) {
        this.query = { ...this.query, page };
    }
    page$ = this.route.queryParams.pipe(tap((qps) => (this.page = +(qps['page'] ?? '1'))));

    async ngOnInit(): Promise<void> {
        this.adapter.events$
            .pipe(filter((e) => e.event === 'item-click'))
            .subscribe((e: SDataListItemEvent<any>) => this.router.navigateByUrl(`/${this.ls.language}/robot/${e.items[0]._id}`));
    }

    navigateToBreadcrumbItem(bc) {
        if (bc.name === 'robots') this.router.navigate([`..`], { relativeTo: this.route });
    }
}

const similarSwiperConfig = {
    // centeredSlides: true,
    // centeredSlidesBounds: true,
    spaceBetween: 20,
    grabCursor: true,

    autoplay: {
        delay: 5000,
        disableOnInteraction: true,
    },
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        440: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 3,
        },
        1100: {
            slidesPerView: 4,
        },
    },

    // Navigation arrows
    // navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    // },

    // And if we need scrollbar
    // scrollbar: {
    //   draggable: true,
    //   el: '.swiper-scrollbar'
    // }
};
