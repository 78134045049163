<mat-toolbar color="primary">
    <div style="display: flex; flex-flow: row nowrap; align-items: center; width: 100%" class="container">
        <!-- <button class="align-self: flex-end" mat-icon-button id="toggle-menu-btn" (click)="drawer.toggle()">
            <mat-icon class="material-icons">menu</mat-icon>
        </button> -->
        <!-- <a style="text-decoration: none; color: inherit" routerLink="/{{ languageService.language }}/home">
            ASK ROBOTS
        </a> -->
        <div class="spacer"></div>
        <div class="links">
            <ng-container *ngIf="auth.user$ | async">
                <a class="link" mat-stroked-button color="accent" routerLink="/{{ languageService.language }}/subscriptions/list" routerLinkActive="active">My subscriptions</a>
            </ng-container>
        </div>
        <!-- <toolbar-user-menu></toolbar-user-menu> -->
    </div>
</mat-toolbar>
<mat-drawer-container [dir]="dir | async" class="drawer-container">
    <mat-drawer #drawer mode="side"> </mat-drawer>
    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>

<div class="footer">
    <div class="footer-overlay">
        <div class="content wrapper container">
            <span class="spacer"></span>
            <p style="font-size: 9pt">&copy;{{ "All rights reserved" }} {{ date }}</p>
            <span class="spacer"></span>
        </div>
    </div>
</div>
