import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Injector,
    input,
    OnChanges,
    output,
    PLATFORM_ID,
    ViewEncapsulation,
} from "@angular/core";
import { Subject } from "rxjs";
import { TypeFormResponseInfo } from "../typeform-response-info";
import { createWidget } from "@typeform/embed";

import { CommonModule, DOCUMENT, isPlatformBrowser } from "@angular/common";

@Component({
    selector: "embed-tf",
    templateUrl: "./embed.component.html",
    styleUrls: ["./embed.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule],
})
export class EmbedComponent implements OnChanges {
    private readonly doc = inject(DOCUMENT);
    submitted = output<TypeFormResponseInfo>();
    loading = true;
    destroyed$ = new Subject();

    subscriptionId = input.required<string>();
    formId = input.required<string>();
    private readonly platformId = inject(PLATFORM_ID);
    injector = inject(Injector);
    ngOnChanges() {
        if (isPlatformBrowser(this.platformId)) {
            this._init(this.formId(), this.subscriptionId());
        }
    }

    private async _init(formId: string, subscription_id: string): Promise<any> {
        //EmbedWidget
        if (!formId || !subscription_id) return;
        this.loading = true;

        await loadScript(this.doc, "https://embed.typeform.com/next/embed.js", "async");

        const embedWidget = createWidget(formId, {
            container: this.doc.querySelector("#" + formId),
            medium: "snippet",
            fullScreen: true,
            transitiveSearchParams: true,
            opacity: 100,
            disableScroll: true,
            inlineOnMobile: true,
            autoFocus: true,
            // hideFooter: true,
            hidden: { subscription_id },
            lazy: true,
            keepSession: true,
            onReady: () => {
                // console.log("READY");
            },
            onSubmit: async (resInfo: TypeFormResponseInfo) => {
                this.submitted.emit({
                    ...resInfo,
                    subscriptionId: subscription_id,
                });
            },
            onClose: () => {
                // console.log("CLOSE");
            },
            onQuestionChanged: (questionInfo: { ref: string; formId: string }) => {
                // console.log(questionInfo.ref);
            },
            onHeightChanged: (data) => {
                // console.log(data);
            },
            onEndingButtonClick: () => {
                // console.log('ENDING BTN');
            },
        } as any);

        return embedWidget;
    }
}

function loadScript(doc: Document, url: string, load: "defer" | "async" | undefined = "defer"): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const script = doc.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        if (load) script[load] = true;
        script.onload = () => {
            resolve(true);
        };
        doc.head.appendChild(script);
    });
}
