import { ChangeDetectorRef, Component, inject, input, LOCALE_ID, signal, SimpleChanges, viewChild } from "@angular/core";
import { ReportViewModel } from "../../../../shared/types";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { firstValueFrom } from "rxjs";
import { CommonModule, registerLocaleData } from "@angular/common";
import { PersonalSkillsReportReportComponent } from "./sub-reports/personal-skills-report/personal-skills-report.component";
import { ProfessionalSkillsReportComponent } from "./sub-reports/professional-skills-report/professional-skills-report.component";
import { SpecializedSkillsReportComponent } from "./sub-reports/specialized-skills-report/specialized-skills-report.component";
import { EmotionalSkillsReportComponent } from "./sub-reports/emotional-skills-report/emotional-skills-report.component";
import { CognitiveSkillsReportComponent } from "./sub-reports/cognitive-skills-report/cognitive-skills-report.component";
import { groupsInfo } from "./groups-info";
import { GradeViewerComponent } from "./components/grade-viewer/grade-viewer.component";

import localeArSA from "@angular/common/locales/ar-SA";
registerLocaleData(localeArSA);

declare const google;

@Component({
    selector: "rise-index-report",
    templateUrl: "./rise-index-report.component.html",
    styleUrls: ["./rise-index-report.component.scss"],
    standalone: true,
    imports: [
        CommonModule,
        PersonalSkillsReportReportComponent,
        ProfessionalSkillsReportComponent,
        SpecializedSkillsReportComponent,
        EmotionalSkillsReportComponent,
        CognitiveSkillsReportComponent,
        GradeViewerComponent,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: "ar-SA" }, // Set Arabic Saudi Arabia as the locale
    ],
})
export class RiseIndexReportComponent {
    static __name = "rise-index-report";

    personal_skills = viewChild("personal_skills");
    professional_skills = viewChild("professional_skills");
    specialized_skills = viewChild("specialized_skills");
    competencies_values = viewChild("competencies_values");
    competencies_knowledge = viewChild("competencies_knowledge");
    groups = signal(groupsInfo.map((g) => ({ ...g, template: this[g.variable] })));

    ngAfterViewInit() {
        this.groups.set(groupsInfo.map((g) => ({ ...g, template: this[g.variable] })));
    }

    static async updateResult(v: ReportViewModel, http: HttpClient): Promise<any> {
        const result = generateResponseReport(v);
        try {
            await firstValueFrom(http.post(`${environment.base_url}/tfr/update-result`, { responseId: v._id, result }));
            return result;
        } catch (err) {
            console.error(err);
        }
    }

    report = signal(null);

    reportViewModel = input.required<ReportViewModel>();
    private readonly http = inject(HttpClient);
    private async _fillResult(vm) {
        let result = vm.result;
        if (!result) {
            console.warn(`${RiseIndexReportComponent.name} has no result to be displayed`);
            result = await RiseIndexReportComponent.updateResult(vm, this.http);
        }
        this.report.set({ ...result });

        this.drawChart(this.report());
    }
    private _convertResultToData(result: any) {
        return this.groups().map((g) => {
            return { name: g.name, score: +result[g.variable] };
        });
    }

    rendered = signal(false);
    drawChart(result) {
        google.charts.load("current", { packages: ["corechart"] });

        const data: { name: string; score: number }[] = this._convertResultToData(result);

        const chartData = data
            .slice()
            .map((e) => ({ name: e.name, score: +e.score }))
            .sort((e1, e2) => e2.score - e1.score)
            .map((e) => [e.name, e.score, e.score < 50 ? "#ed6e4e" : "#a6ba31", `${e.score}%`]);
        google.charts.setOnLoadCallback(() => {
            drawChart(chartData);
            setTimeout(() => {
                const div = document.createElement("div");
                div.classList.add("chart-container");
                document.body.appendChild(div);
            }, 1000);
        });

        function drawChart(d) {
            const data = google.visualization.arrayToDataTable([["الجدارة", "المستوى", { role: "style" }, { role: "annotation" }], ...d]);

            const options = {
                direction: -1, // RTL
                title: "تقييم الجدارات",
                legend: { position: "none", alignment: "end" },
                enableInteractivity: false,
                fontName: "Noto Sans Arabic",
                height: "100%",
                hAxis: {
                    minValue: 0,
                    maxValue: 100,
                    ticks: [0, 25, 50, 75, 100],
                    direction: -1, // Also set RTL for horizontal axis text
                },
            };

            const container = document.getElementById("result-chart");
            const chart = new google.visualization.BarChart(container);
            chart.draw(data, options);
        }
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes["reportViewModel"]) {
            this._fillResult(this.reportViewModel());
        }
    }
}

function generateResponseReport(v: ReportViewModel) {
    const res = {
        name: v.user.name,
        ...Object.entries(v.variables)
            .map(([_, value]) => ({ [value["key"]]: Number(value["number"]) }))
            .reduce((v, acc) => ({ ...v, ...acc }), {}),
    };
    return res;
}
