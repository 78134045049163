import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';

// import { InviteToSignupComponent } from "../invite-to-signup/invite-to-signup.component";

@Component({
    selector: 'home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
})
export class HomePageComponent implements OnDestroy {
    destroyed$ = new Subject();

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
