export const groupsInfo = [
    {
        name: 'الجدارات المهارية الشخصية',
        variable: 'personal_skills',
        skills: [
            { name: 'التحليل', variable: 'analytical' },
            { name: 'التفكير النقدي', variable: 'critical' },
            { name: 'الإبداع', variable: 'creativity' },
            { name: 'الابتكار', variable: 'innovation' },
            { name: 'القيادة', variable: 'leadership' },
            { name: 'المرونة', variable: 'flexibility' },
        ],
    },
    {
        name: 'الجدارات المهارية المهنية',
        variable: 'professional_skills',
        skills: [
            { name: 'السلامة المهنية', variable: 'safety' },
            { name: 'التخطيط والتنظيم', variable: 'planning_and_organizing' },
            { name: 'التوجيه', variable: 'caoching' },
            { name: 'إدارة التغيير', variable: 'change_management' },
            { name: 'العمل ضمن فريق', variable: 'team_working' },
            { name: 'الثقافة المؤسسية', variable: 'organizational_culture' },
            { name: 'التواصل المهني', variable: 'professional_communication' },
            { name: 'التكنولوجيا في العمل', variable: 'business_technology' },
            { name: 'العمل عن بعد', variable: 'remote_working' },
        ],
    },
    {
        name: 'الجدارات المهارية التخصصية',
        variable: 'specialized_skills',
        skills: [
            { name: 'الهندسة البيئية والاجتماعية', variable: 'se_engineering' },
            { name: 'تصميم نموذج العمل الاجتماعي والبيئي', variable: 'model_design' },
            { name: 'الاستدامة المالية للمشاريع الاجتماعية', variable: 'fin_sustainability' },
            { name: 'التسويق الاجتماعي', variable: 'commercial_int' },
            { name: 'إدارة الأثر والجدوى الاجتماعية', variable: 'impact' },
        ],
    },
    {
        name: 'الجدارات الوجدانية',
        variable: 'competencies_values',
        skills: [
            { name: 'لثقة بالنفس', variable: 'e_confidence' },
            { name: 'الشغف', variable: 'e_passion' },
            { name: 'التعاطف', variable: 'e_empathy' },
        ],
    },
    {
        name: 'الجدارات المعرفية',
        variable: 'competencies_knowledge',
        skills: [
            { name: 'المعرفة بالاقتصاد', variable: 'k_economic' },
            { name: 'المعرفة بقوانين وتشريعات الاستدامة والشركات الاجتماعية', variable: 'k_regulations' },
            { name: 'مواكبة التوجهات الحديثة في الابتكار والريادة', variable: 'k_trends' },
        ],
    },
];
