<div class="container">
    @if (adapter) {
        <input class="search-input" placeholder="ابحث ياستخدام البريد الالكتروني" #searchInput type="text" (input)="search$.next(searchInput.value)" />

        @if (query$ | async; as query) {
            <sdl-cards-list [cardTemplate]="bookCardTemplate" [adapter]="adapter" [query]="query"> </sdl-cards-list>
            <sdl-list-paginator
                class="paginator"
                [page]="query.page"
                [pageSize]="query.per_page"
                [total]="adapter.total$ | async"
                (pageChange)="page = $event"
            ></sdl-list-paginator>
        }
    }
</div>
<ng-template #bookCardTemplate let-item="item" let-adapter="adapter">
    <div
        sdlItem
        [item]="item"
        [adapter]="adapter"
        style="margin-top: 1rem; border-bottom: 1px solid #ddd"
        class="flex row nowrap a-center"
        style="width: 100%; padding: 0.5rem 0; margin-block-end: 1rem; border-block-end: 1px dotted #eee"
    >
        <!-- {{item | json}} -->
        <div class="flex col">
            <h4>{{ item.user?.email }}</h4>
            <span>{{ item.submitted_at | date: "medium" }}</span>
        </div>
        <span class="spacer"></span>

        <h4>{{ item.result?.level }}</h4>

        <span class="spacer"></span>
        @if (reports()) {
            <div class="actions flex row nowrap a-center">
                @for (a of reports(); track a) {
                    <button [matMenuTriggerFor]="actionMenu" class="action" mat-button>
                        {{ a.title }}
                    </button>
                    <mat-menu #actionMenu="matMenu">
                        @for (t of triggers(); track t) {
                            <button mat-menu-item (click)="sendByEmail({ response: item, action: a }, t)">{{ t.text || t.trigger }}</button>
                        }
                        <button mat-menu-item (click)="download({ response: item, action: a })">حمل التقرير</button>
                    </mat-menu>
                }
                @if ((isAdmin$ | async) === true) {
                    <button class="action" mat-button (click)="refresh(item)">تحديث الإجابة</button>
                }
            </div>
        }
    </div>
</ng-template>
