:root {
    --primaryC: 193, 24, 90;
    --primary: rgb(var(--primaryC));
    --accentC: 0, 121, 107;
    --accent: rgb(var(--accentC));
    --gradient: linear-gradient(45deg, rgba(100, 50, 75, 0.9), rgba(100, 50, 75, 0.8));
}


.mat-toolbar {
    background: #eeeeee;

    .links{
        a{
            text-decoration: none;
            color: #444;


            &:not(:last-of-type){
                margin-inline-end: 0.5rem;
            }
        }
    }
}