* {
    margin: 0;
    padding: 0;
}

html,
body,
#wrapper {
    width: 100%;
    height: 100%;
}

iframe {
    border-radius: 0 !important;
}