import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RatingStarsComponent } from '../rating-stars/rating-stars.component';

export interface Breadcrumb {
    name: string;
    display: string;
}
@Component({
    selector: 'top-header',
    templateUrl: './top-header.component.html',
    styleUrls: ['./top-header.component.scss'],
    standalone: true,
    imports: [CommonModule, RatingStarsComponent],
})
export class TopHeaderComponent {
    @Input() title: string | null = null;
    private _breadcrumbs: Breadcrumb[] | null = null;
    @Input()
    public get breadcrumbs(): Breadcrumb[] | null {
        return this._breadcrumbs;
    }
    public set breadcrumbs(value: Breadcrumb[] | null) {
        this._breadcrumbs = value;
        this.activeBreadcrumb = value?.at(-1);
    }
    @Input() description: string | null = null;
    @Input() rating: number | null = null;
    @Input() subscribers: number | null = null;

    @Input() activeBreadcrumb: Breadcrumb;
    @Output() activeBreadcrumbChange = new EventEmitter<Breadcrumb>();

    navigateTo(bc: Breadcrumb) {
        this.activeBreadcrumb = bc;
        this.activeBreadcrumbChange.emit(bc);
    }
}
