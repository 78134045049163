import { ApplicationConfig, importProvidersFrom, Provider, provideZoneChangeDetection } from '@angular/core';
import { ActivatedRoute, provideRouter, withComponentInputBinding, withInMemoryScrolling, withRouterConfig, withViewTransitions } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideClientHydration, withEventReplay, withI18nSupport } from '@angular/platform-browser';
import { LanguageModule, LanguageService } from '@upupa/language';
import { environment } from '../environments/environment';
import { DynamicFormMaterialThemeModule, DF_MATERIAL_THEME_INPUTS } from '@upupa/dynamic-form-material-theme';
import { DynamicFormModule } from '@upupa/dynamic-form';
import { TagsChipsInputComponent } from '@upupa/tags';
import { DataModule } from '@upupa/data';
import { AuthModule } from '@upupa/auth';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { AuthorizeModule } from '@upupa/authz';
import { providePageMetadata, UtilsModule, withOpenGraph, withTwitterCard } from '@upupa/common';
import { DataTableModule } from '@upupa/table';
import { DynamicFormNativeThemeModule, FileSelectComponent } from '@upupa/dynamic-form-native-theme';
import { UploadModule } from '@upupa/upload';
import { PermissionsModule } from '@upupa/permissions';
import { ControlPanelModule } from '@upupa/cp';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

const signinProvider: Provider = {
    provide: 'DEFAULT_SIGNIN',
    useFactory: (lang: LanguageService, route: ActivatedRoute) => `/${lang.language}/account/login?redirect=${route.url}`,
    deps: [LanguageService, ActivatedRoute],
};
const verifyProvider: Provider = {
    provide: 'DEFAULT_VERIFY',
    useFactory: (lang: LanguageService) => `/${lang.language}/verify`,
    deps: [LanguageService],
};

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(withFetch()),
        provideClientHydration(withI18nSupport(), withEventReplay()),
        provideAnimations(),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(
            appRoutes,
            withComponentInputBinding(),
            withRouterConfig({ resolveNavigationPromiseOnError: true }),
            withInMemoryScrolling({
                // anchorScrolling: 'enabled',
                scrollPositionRestoration: 'enabled',
            }),
            // withRichViewTransitionsService(),
            withViewTransitions(),
            // withDebugTracing()
        ),
        // provideServiceWorker('ngsw-worker.js', {
        //   enabled: !isDevMode(),
        //   registrationStrategy: 'registerWhenStable:30000',
        // }),
        importProvidersFrom([
            LanguageModule.forRoot(environment.defaultLang || 'en', {}, 'lang', '/assets/langs'),
            DataModule.forChild(`${environment.base_url}/api`),
            AuthModule.forRoot(`${environment.base_url}/auth`, {
                default_login_url: signinProvider,
                default_verify_url: verifyProvider,
            }),
            AuthorizeModule.forRoot(`${environment.base_url}/permissions`),
            UtilsModule,
            DataTableModule.forRoot([]),
            UploadModule.forChild(`${environment.base_url}/storage`),
            PermissionsModule,
            
            DynamicFormMaterialThemeModule,
            DynamicFormNativeThemeModule,
            DynamicFormModule.forRoot(
                [],
                {
                    material: {
                        ...DF_MATERIAL_THEME_INPUTS,
                        file: { component: FileSelectComponent },
                        'tags-select': { component: TagsChipsInputComponent },
                    },
                },
                'material',
                { enableLogs: environment.production === false },
            ),
        ]),

        providePageMetadata(
            {
                canonicalBaseUrl: environment.base_url,
                fallback: () => environment.seo['en'] as any,
            },
            withTwitterCard(),
            withOpenGraph(),
        ),

        {
            provide: IMAGE_LOADER,
            useValue: (config: ImageLoaderConfig) => {
                if (!config.src) return '';
                if (config.src.startsWith('/storage') || config.src.startsWith('storage')) return `${environment.base_url}${config.src}`;
                return config.src;
            },
        },
        provideAnimationsAsync(), provideAnimationsAsync(),
    ],
};
