<div class="flex a-center">
    <span class="rating-num">({{rating}})</span>


    <ng-container *ngFor="let s of stars">
        <mat-icon class="star" *ngIf="s <= filled">star_filled</mat-icon>
        <mat-icon class="star flipY" *ngIf="s === half">star_half</mat-icon>
        <mat-icon class="star" *ngIf="s >= empty">star_outline</mat-icon>
    </ng-container>

</div>