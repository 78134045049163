@if (reportViewModel()) {
    @let vm = reportViewModel();
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/cover.png" alt="intro-image" />
        <div class="absolute">
            <h2 id="robotName" class="blue-text">تقرير نتائج مؤشر رايز (RISE Index)</h2>
            <h2 id="customer" class="blue-text">
                {{ vm.user.name }}
            </h2>

            <h2 id="date" class="blue-text">
                <bdi>
                    التاريخ: <span dir="ltr">{{ vm.submitted_at | date: "yyyy MMM dd" : undefined : "ar-SA" }}</span>
                </bdi>
            </h2>
        </div>
    </div>

    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg-2.png" alt="{{ vm.name }}" />
        <div class="wrapper">
            <div class="text-center">
                <p class="blue-text">بسم الله الرحمن الرحيم</p>
                <h1 class="orange-text">بطاقة المؤشر</h1>
            </div>
            <div class="flex-center">
                <table class="index">
                    <tr>
                        <td>
                            <img src="assets/rise-index-report/icons/report.svg" alt="report" />
                            <p>اسم المؤشر</p>
                        </td>
                        <td>
                            <p>مؤشر رايز لريادة الأعمال الاجتماعية والبيئية</p>
                            <p>RISE Index for Impact Entrepreneurship</p>
                        </td>
                    </tr>
                    <tr class="bg-lightblue">
                        <td>
                            <img src="assets/rise-index-report/icons/user.svg" alt="user" />
                            <p>المستفيد</p>
                        </td>
                        <td>{{ vm.user.name }}</td>
                    </tr>
                    <tr>
                        <td>
                            <img src="assets/rise-index-report/icons/purpose.svg" alt="report" />
                            <p>الهدف</p>
                        </td>
                        <td>
                            <p>قياس 27 جدارة أساسية للارتقاء بمشروعك نحو المرونة، الابتكار، الاستدامة، والريادة.</p>
                            <p>
                                يأتي هذا المؤشر كروبوت رقمي داعم لمشروع الابتكار والاستثمار الاجتماعي والبيئي الذي تنفذه أكاديمية الفوزان في جامعة الملك فهد للبترول والمعادن،
                                بالتعاون مع شريك التنفيذ (أثر الإدارية <a href="https://impactm.sa" target="_blank">https://impactm.sa</a> ).
                            </p>
                        </td>
                    </tr>
                    <tr class="bg-lightblue">
                        <td>
                            <img src="assets/rise-index-report/icons/version.svg" alt="user" />
                            <p>الإصدار</p>
                        </td>
                        <td>
                            <p>الإصدار 1.1 من مؤشر رايز</p>
                            <p>RISE Index 1.1, designed by Human Centered Learning LLC at California. 2024</p>
                        </td>
                    </tr>
                </table>

                <p style="direction: ltr; text-align: center; margin-top: 2rem">
                    This assessment and report are generated by Benchmarker360 App of <a href="https://humancentered-data.com">Human Centered Data</a> LLC at California.
                </p>
            </div>
        </div>
    </div>

    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" alt="about" />
        <div class="wrapper">
            <h2 class="orange-text">تعرّف أولاً على مؤشر رايز لريادة الأعمال الاجتماعية والبيئية</h2>

            <h2 class="blue-text">قصة مؤشر رايز:</h2>
            <p>
                من الأخطاء الشائعة لدى رواد الأعمال؛ البدء بحماس في مشاريعهم اعتماداً على جداراتهم التخصصية فقط، دون مراعاة لأهمية الجدارات المتكاملة الأخرى التي تعزز فرص النجاح
                المستدام.
            </p>
            <p>
                فالإلمام بالجوانب الفنية لا يكفي وحده، بل لا بد من تطوير قدرات شخصية، مهنية، وريادية، وكذلك جدارات وجدانية تساعدك على التكيف، والابتكار، وقيادة مشروعك نحو أثر أكبر.
            </p>
            <p>
                <bdi>
                    تؤكد العديد من الدراسات أن التخصص التقني وحده لا يكفي للنجاح. فمثلاً، يُشير تقرير Global Talent Trends إلى أن 89% من أسباب فشل الموظفين تعود إلى نقص الجدارات
                    الشخصية والمهنية والوجدانية. كما يؤكد تقرير The Future of Work 2021: Global Hiring Outlook أن هذه الجدارات هي الأهم للنجاح في عالم الأعمال.
                </bdi>
            </p>
            <p>لذا، نقدم لك هذا المؤشر الذي سيثري تجربتك المهنية والريادية، معتمداً على أبحاث علمية تضمن لك قياساً دقيقاً وتوصيات فعّالة.</p>
        </div>
    </div>
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg-2.png" alt="about" />
        <div class="wrapper">
            <h2 class="blue-text">ماذا يقيس مؤشر رايز؟</h2>
            <p>يقيس مؤشر رايز 27 جدارة أساسية للارتقاء بمشروعك نحو المرونة، الابتكار، الاستدامة، والريادة، مصنّفة على الشكل الآتي:</p>
            <div class="flex-center">
                <table>
                    <tr class="bg-lightblue">
                        <td colspan="3"><strong class="blue-text">الجدارات المهارية</strong></td>
                    </tr>
                    <tr class="bg-lightblue">
                        <td><strong class="blue-text">الشخصية</strong></td>
                        <td><strong class="blue-text">المهنية</strong></td>
                        <td><strong class="blue-text">التخصصية</strong></td>
                    </tr>

                    <tr>
                        <td>
                            <ul>
                                <li>التحليل</li>
                                <li>التفكير النقدي</li>
                                <li>الإبداع</li>
                                <li>الابتكار</li>
                                <li>القيادة</li>
                                <li>المرونة</li>
                            </ul>
                        </td>

                        <td>
                            <ul>
                                <li>السلامة المهنية</li>
                                <li>التخطيط والتنظيم</li>
                                <li>التوجيه</li>
                                <li>إدارة التغيير</li>
                                <li>العمل ضمن فريق</li>
                                <li>الثقافة المؤسسية</li>
                                <li>التواصل المهني</li>
                                <li>التكنولوجيا في العمل</li>
                                <li>العمل عن بعد</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>الهندسة البيئية والاجتماعية</li>
                                <li>تصميم نموذج العمل الاجتماعي والبيئي</li>
                                <li>الاستدامة المالية للمشاريع الاجتماعية</li>
                                <li>التسويق الاجتماعي</li>
                                <li>إدارة الأثر والجدوى الاجتماعية</li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="flex-center">
                <table>
                    <tr class="bg-lightblue">
                        <td colspan="3"><strong class="blue-text">الجدارات المعرفية</strong></td>
                        <!-- <td><strong class="blue-text">الجدارات الوجدانية</strong></td> -->
                    </tr>

                    <tr>
                        <td>
                            <ul>
                                <li>المعرفة بالاقتصاد</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>المعرفة بقوانين وتشريعات الاستدامة والشركات الاجتماعية</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>مواكبة التوجهات الحديثة في الابتكار والريادة</li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="flex-center">
                <table>
                    <tr class="bg-lightblue">
                        <td colspan="3"><strong class="blue-text">الجدارات الوجدانية</strong></td>
                    </tr>

                    <tr>
                        <td>
                            <ul>
                                <li>الثقة بالنفس</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>الشغف</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>التعاطف</li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- <div class="flex-center">
                <table>
                    <tr class="bg-lightblue">
                        <td colspan="3"><strong class="blue-text">الجدارات المهنية</strong></td>
                    </tr>

                    <tr>
                        <td>
                            <ul>
                                <li>السلامة المهنية</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>التخطيط والتنظيم</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>إدارة التغيير</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>العمل ضمن فريق</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>الثقافة المؤسسية</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>التواصل المهني</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>التكنولوجيا في العمل</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>العمل عن بعد</li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li>التوجيه</li>
                            </ul>
                        </td>
                    </tr>
                </table>
            </div> -->
            <p>
                قد أجبت في هذا المقياس على مجموعة من الأسئلة التي مكنتنا من قياس جداراتك وقدراتك بدقة. فيما يلي تفصيل لنتائج قياس كل جدارة، مع توصيات مخصصة لك بناءً على تقييمك؛
                لتعزيز نقاط قوتك وتطوير جداراتك.
            </p>
        </div>
    </div>

    @for (group of groups(); track group.variable) {
        <ng-container *ngTemplateOutlet="group.template(); context: { group: group, result: report() }"></ng-container>
        <ng-container *ngTemplateOutlet="blankPageTemplate; context: { placeholder: null }"></ng-container>
    }

    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg-2.png" alt="Final Result" />
        <div class="wrapper">
            <h2 class="blue-text">الخلاصة – كم هي قيمة مؤشر RISE الناتجة لديك؟</h2>
            <grade-viewer [pharse_1]="'بلغ مؤشر رايز (RISE Index) لقدراتك في ريادة الأعمال الاجتماعية والبيئية:'" [grade]="report().Score"></grade-viewer>

            <div id="result-chart" style="width: 100%; height: 500px"></div>

            <h2 class="blue-text">ختاماً</h2>
            <p>تذكر أن رحلتك نحو النجاح تبدأ بمعرفة نقاط قوتك وفرص تطوّرك، أنت الآن تمتلك رؤية أعمق لجداراتك وقدراتك، مما يمنحك الأساس لتحقيق أثر إيجابي ومستدام في مجالك.</p>
            <p>استمر في السعي نحو التطور والابتكار، وكن قائدًا ملهمًا للتغيير. الطريق أمامك مليء بالفرص، فاستعد لصناعة مستقبل يشبه طموحاتك، ويعكس شغفك وقيمك الريادية.</p>
        </div>
    </div>

    
}

<ng-template #personal_skills let-group="group" let-result="result">
    <personal-skills-report [report]="result" [skills]="group.skills"></personal-skills-report>
</ng-template>
<ng-template #professional_skills let-group="group" let-result="result">
    <professional-skills-report [report]="result" [skills]="group.skills"></professional-skills-report>
</ng-template>
<ng-template #specialized_skills let-group="group" let-result="result">
    <specialized-skills-report [report]="result" [skills]="group.skills"></specialized-skills-report>
</ng-template>
<ng-template #competencies_values let-group="group" let-result="result">
    <emotional-skills-report [report]="result" [skills]="group.skills"></emotional-skills-report>
</ng-template>
<ng-template #competencies_knowledge let-group="group" let-result="result">
    <cognitive-skills-report [report]="result" [skills]="group.skills"></cognitive-skills-report>
</ng-template>

<ng-template #blankPageTemplate let-placeholder="placeholder">
    @let src = placeholder ?? "assets/rise-index-report/images/bg-2.png";
    <div class="page">
        <!-- <img class="cover" [src]="src" alt="blank" /> -->
        <div class="wrapper"></div>
    </div>
</ng-template>
