import { AfterViewInit, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import SwiperCore, { Swiper } from 'swiper';
import { SwiperOptions } from 'swiper/types';

// SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
    selector: 's-swiper',
    templateUrl: './s-swiper.component.html',
    styleUrls: ['./s-swiper.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SSwiperComponent implements OnDestroy, AfterViewInit {
    private readonly destroyed$ = new Subject<void>();
    private swiper!: Swiper | null;

    _slides$ = new BehaviorSubject(s);

    @Input()
    public get slides(): any[] {
        return this._slides$.value;
    }
    public set slides(value: any[]) {
        this._slides$.next(value);
        console.log('SSwiper Slides change: ', this.slides.length);

        this.swiper?.updateSlides();
    }
    @Input() slideTemplate: any = null;

    private _config: SwiperOptions = defaultSwiperOptions as any;
    @Input()
    public get config(): SwiperOptions {
        return this._config;
    }
    public set config(value: SwiperOptions) {
        this._config = value;
        if (this.swiper) {
            this.swiper.destroy();
            this.swiper = null;
            this._initSwiper();
        }
    }

    private _initSwiper() {
        if (this.swiper) return;
        this.swiper = new Swiper('.swiper', {
            // configure Swiper to use modules
            // modules: [Navigation, Pagination],
            ...this.config,
        });
        console.log('S Swiper initialized: ', this.swiper);
    }

    ngAfterViewInit(): void {
        if (!this.slideTemplate) throw 'SliderTemplate is required';

        this._initSwiper();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    onSwiper(swiper: Swiper) {
        console.log('S Swiper initialized: ', swiper);
    }
    onSlideChange() {
        console.log('slide change');
    }
}

const defaultSwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 0,
    effect: 'fade',
    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    // scrollbar: {
    //   draggable: true,
    //   el: '.swiper-scrollbar'
    // }
};

const s = [
    {
        title: 'أهلا بكم في استوديو زي اف اي العائلي',
        body: 'أهلا بكم في استوديو زي اف اي العائلي',
        media: { type: 'img', src: 'https://images.unsplash.com/photo-1561211919-1947abbbb35b?auto=format&w=1920&h=1080&q=80' },
    },
    {
        title: 'أهلا بكم في استوديو زي اف اي العائلي',
        body: 'أهلا بكم في استوديو زي اف اي العائلي',
        media: { type: 'img', src: 'https://images.unsplash.com/photo-1585854467604-cf2080ccef31?auto=format&w=1920&h=1080&q=80' },
    },
    {
        title: 'أهلا بكم في استوديو زي اف اي العائلي',
        body: 'Hello There...',
        media: { type: 'img', src: 'https://images.unsplash.com/photo-1596468138838-0f34c2d0773b?auto=format&w=1920&h=1080&q=80' },
    },
    {
        title: 'أهلا بكم في استوديو زي اف اي العائلي',
        body: 'أهلا بكم في استوديو زي اف اي العائلي',
        media: { type: 'img', src: 'https://html.designingmedia.com/vaauthority/assets/images/banner-backgroundimage.jpg' },
    },
];
