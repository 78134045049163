<div class="page">
    <img class="cover" src="assets/rise-index-report/images/bg-2.png" [alt]="groupName" />
    <div class="wrapper">
        <h2 class="blue-text">{{ groupName }}</h2>
        <p>الجدارات الوجدانية هي القيم التي تؤثر في مشاعر وسلوك الريادي تجاه العمل.</p>
        <p>نركز في الجدارات الوجدانية على أهم ثلاث جدارات:</p>
        <ul>
            @for (skill of skills(); track $index) {
                <li>{{ skill.name }}</li>
            }
        </ul>
        <grade-viewer [pharse_1]="'بلغت قيمة مؤشر جداراتك الوجدانية:'" [grade]="total()"></grade-viewer>
        @if (total() < 50) {
            <p>أنك تحتاج إلى مراجعة شجاعة للقيم التي تؤثر في حياتك، والتي تحفزك على الإبداع والعمل، بدءاً من ثقتك بقدراتك ونفسك...</p>
        } @else {
            <p>أنك تتحلى بقيم وأفكار تساعدك على تحقيق أهدافك في المشاريع الريادية، ولابدّ من استمرارية تعزيز هذه القيم لتحقيق أقصى استفادة منها.</p>
        }
        <p>وسنأتي على تفصيل نتيجة كل جدارة، وخطوات تطويرك لهذه الجدارة انطلاقاً من نتائج تقييمك.</p>
    </div>
</div>

@for (skill of skills(); track i; let i = $index) {
    <ng-container *ngTemplateOutlet="skill.template(); context: { variable: skill.variable, score: report()[skill.variable] }"></ng-container>
}

<ng-template #e_confidence let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">الثقة بالنفس</h2>
            <p>الثقة بالنفس هي إيمان راسخ بقدرتك على تحقيق الأثر الإيجابي والتغيير المطلوب، رغم التحديات، مع الالتزام بالمبادئ والقيم التي تخدم المجتمع والبيئة.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة الثقة بالنفس، فأنت تمتلك'" [grade]="score" [pharse_2]="'من هذه الجدارة الوجدانية'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>اعمل على تعزيز ثقتك بقدراتك؛ من خلال تحديد أهداف صغيرة قابلة للتحقيق، والاحتفال بإنجازها.</li>

                    <li>طوّر جدارات حل المشكلات؛ بتعلّم استراتيجيات التعامل مع التحديات بثقة وهدوء.</li>

                    <li>أحط نفسك بأشخاص إيجابيين، يدعمونك ويعززون ثقتك بقدرتك على تحقيق النجاح.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>واصل تعزيز ثقتك بقدراتك؛ من خلال وضع أهداف طموحة، والعمل بجد لتحقيقها.</li>

                    <li>وظّف قدرتك على تجاوز التحديات كقوة دافعة لتطوير مشروعك بشكل مستدام.</li>

                    <li>شارك خبراتك وتجارِبك مع الآخرين؛ لإلهامهم وتأكيد ثقتك بنفسك في المجال الريادي.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #e_passion let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg-2.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">الشغف</h2>
            <p>الشغف هو الدافع العميق والمتجدد لتحقيق الأثر الإيجابي، وتحويل الرؤية إلى واقع ملموس يخدم المجتمع والبيئة بجدية وإصرار.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة الشغف'"></grade-viewer>
            @if (score < 25) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>حاول تعزيز اهتمامك، بالبحث عن الجوانب الإيجابية في المشكلات؛ عبر التركيز على الفرص التي تقدمها للتعلم والنمو.</li>

                    <li>ضع أهدافاً ملهمة لمشروعك؛ لتزيد من رغبتك وحماسك للعمل نحو إنجاحه.</li>

                    <li>أحط نفسك بأشخاص داعمين ومتحمسين للنجاح، مما يعزز شغفك ويحفّزك لتحقيق أهدافك.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>واصل استثمار شغفك؛ من خلال التركيز على رؤية إيجابية تدفعك لإيجاد الحلول الإبداعية للمشكلات.</li>

                    <li>استخدم حماسك لإنجاح مشروعك كدافع قوي للعمل بجدية، وإلهام فريقك نحو النجاح.</li>

                    <li>شارك شغفك مع الآخرين؛ لتعزيز روح الابتكار والتعاون، مما يسهم في تحقيق أهداف مشروعك الريادي.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #e_empathy let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">التعاطف</h2>
            <p>التعاطف هو القدرة على فهم احتياجات وتحديات الآخرين بعمق، وتوجيه الجهود نحو حلول تعزز رفاه المجتمع والبيئة بحسٍّ إنسانيٍّ عالٍ.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة التعاطف'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>اعمل على تطوير اهتمامك بفهم مشاعر واحتياجات المستفيدين؛ من خلال الاستماع الفعّال، وملاحظة تفاعلاتهم.</li>

                    <li>درّب نفسك على تعزيز الذكاء العاطفي والاجتماعي؛ عبر تحسين جدارات التواصل، وتفهم وجهات نظر الآخرين.</li>

                    <li>خصِّص وقتاً للتعرف على الأفراد والجهات المهتمة بعملك، وابدأ بتكوين علاقات قائمة على الاحترام والتقدير المتبادل.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>واصل استخدام تعاطفك لتحقيق فهم عميق لاحتياجات المستفيدين والعملاء، مما يعزز تجربتهم ويحقق رضاهم.</li>

                    <li>استثمر ذكاءك العاطفي في بناء علاقات إيجابية ومثمرة مع الأفراد والجهات ذات الصلة بعملك.</li>

                    <li>شارك اهتمامك الحقيقي بالآخرين لتعزيز الثقة والتعاون، مما يسهم في تحقيق أهداف مشروعك الريادي بنجاح.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>
