import { HttpClient } from "@angular/common/http";
import { Component, inject, OnInit, signal } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { LanguageService } from "@upupa/language";
import { BehaviorSubject, combineLatest, debounceTime, filter, firstValueFrom, map, switchMap, tap } from "rxjs";
import { environment } from "../../../environments/environment";
import { AuthService } from "@upupa/auth";
import { CardsListComponent, ListPaginatorComponent, SDataListItemEvent, SdlItemDirective } from "@s-data-list";
import { ResponseListPageAdapter, ResponseListHttpDataAdapter } from "../responses-list-adapter.service";
import { SnackBarService } from "@upupa/dialog";
import { CommonModule } from "@angular/common";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";

@Component({
    selector: "subscription-responses",
    templateUrl: "./subscription-responses.component.html",
    styleUrls: ["./subscription-responses.component.scss"],
    standalone: true,
    imports: [CommonModule, CardsListComponent, MatButtonModule, ListPaginatorComponent, SdlItemDirective, MatMenuModule],
})
export class SubscriptionResponsesComponent implements OnInit {
    private readonly http = inject(HttpClient);
    private readonly adapterService = inject(ResponseListHttpDataAdapter);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    public readonly ls = inject(LanguageService);
    private readonly auth = inject(AuthService);
    private readonly snack = inject(SnackBarService);

    isAdmin$ = this.auth.user$.pipe(map((u) => u?.roles.indexOf("super-admin") > -1));
    search$ = new BehaviorSubject<string>("");
    q$ = this.search$.pipe(debounceTime(300));

    private _query = { page: 1, per_page: 250 };
    private _page$ = new BehaviorSubject(1);
    subscriptionId$ = this.route.params.pipe(map((ps) => ps["subscriptionId"]));
    query$ = combineLatest([this.subscriptionId$, this.q$, this._page$]).pipe(
        map(([sub_id, q, page]) => ({
            ...this._query,
            page,
            q,
            subscription_id: sub_id,
        })),
    );

    triggers = signal([]);
    reports = signal([]);

    adapter = new ResponseListPageAdapter(this.adapterService);
    set page(page: number) {
        this._page$.next(page);
    }
    page$ = this.route.queryParams.pipe(tap((qps) => (this.page = +(qps["page"] ?? "1"))));

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    async ngOnInit(): Promise<void> {
        this.subscriptionId$
            .pipe(switchMap((sub_id) => this.http.get<{ triggers: any[]; reports: any[] }>(`${environment.base_url}/subscription/p-reports?sub_id=${sub_id}`)))
            .subscribe(({ triggers, reports }) => {
                this.triggers.set(triggers);
                this.reports.set(reports);
            });
        // this.adapter.events$.pipe(filter((e) => e.event === "item-click")).subscribe((e: SDataListItemEvent<any>) => {
        // this.router.navigate(['pubs', 'books', e.items[0]._id]);
        // });
    }

    async sendByEmail({ response, action }, trigger: any) {
        try {
            const result = await firstValueFrom(
                this.http.post<any>(`${environment.base_url}/subscription/handle-trigger`, {
                    trigger: trigger.trigger,
                    responseId: response._id,
                    reportId: action._id,
                }),
            );

            if (result.emailSent === true) this.snack.openSuccess(`تم الإرسال إلى ${response.user.email}`);
            else this.snack.openFailed(result.error);
        } catch (error) {
            this.snack.openFailed(error.error);
        }
    }

    download({ response, action }) {
        let url = `${environment.base_url}/tfr/get-pdf?res_id=${response._id}`;
        if (action.name) url += `&rep_id=${action.name}`;
        window.open(url, "_blank");
    }

    async refresh(response: any) {
        const payload = {
            responseId: response._id,
            subscriptionId: this.route.snapshot.params["subscriptionId"],
        };
        try {
            const result = await firstValueFrom(this.http.post<any>(`${environment.base_url}/tfr/new-response`, payload));
            if (result.emailSent === true) this.snack.openSuccess(`تم الإرسال إلى ${response.user.email}`);
            else this.snack.openFailed(result.error);
        } catch (error) {
            this.snack.openFailed(error.error);
        }
    }
}
