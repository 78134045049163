import { HttpClient } from "@angular/common/http"
import { Injectable, Query } from "@angular/core"
import { IDataListDataSource, DataListAdapter, SDataListItemEvent } from "@s-data-list";
import { Observable, filter, map } from "rxjs"
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";

@Injectable({ providedIn: 'root' })
export class ResponseListHttpDataAdapter implements IDataListDataSource {

    constructor(private http: HttpClient) { }

    fetch(query?: Partial<Query> | undefined): Observable<{ data: any[], total: number, query: Partial<Query> }> {
        const subscriptionId = query?.['subscription_id']
        const q = (query?.['q'] ?? '').trim()
        const page = query?.['page'] ?? '1'
        const perPage = query?.['per_page'] ?? '10'
        const sort = query?.['sort_by'] ?? 'submitted_at,desc'
        let p = `${environment.base_url}/subscription/responses?subscription_id=${subscriptionId}&page=${page}&per_page=${perPage}&sort_by=${sort}`
        if (q.length > 0) p += `&user.email=*${q}*`
        return this.http.get<any>(p)
    }
}


@Injectable({ providedIn: 'root' })
export class ResponseListPageAdapter extends DataListAdapter {
    constructor(private _dataAdapter: ResponseListHttpDataAdapter) {
        super(_dataAdapter)
    }

}