import { AfterViewInit, ContentChildren, Directive, ElementRef, Host, input, Input, QueryList } from "@angular/core";
import { IDataListAdapter } from "./list-page-adapter";

@Directive({
    selector: "[sdlItemAction]",
    standalone: true,
})
export class SdlItemActionDirective<T = any> {
    element!: HTMLElement;

    private _sdlItemAction: "click" | "hover" = "click";
    @Input()
    get sdlItemAction() {
        return this._sdlItemAction;
    }
    set sdlItemAction(name: "click" | "hover") {
        this._sdlItemAction = name;
    }

    constructor(@Host() private el: ElementRef) {
        this.element = el.nativeElement;
    }
}

@Directive({
    selector: "[sdlItem]",
    standalone: true,
})
export class SdlItemDirective<T = any> implements AfterViewInit {
    @ContentChildren(SdlItemActionDirective, { descendants: true }) listItemActions!: QueryList<SdlItemActionDirective>;

    item = input.required<T>();
    adapter = input.required<IDataListAdapter<T>>();

    constructor(@Host() private el: ElementRef) {}

    ngAfterViewInit(): void {
        const actionElements =
            this.listItemActions?.length > 0 ? Array.from(this.listItemActions).map((c: any) => c.element) : this.el.nativeElement?.querySelectorAll("[sdlItemAction]");
        actionElements?.forEach((actionEl: HTMLElement) => {
            const attr = actionEl.attributes.getNamedItem("sdlItemAction");
            if (attr?.nodeValue === "click") {
                actionEl.style.cursor = "pointer"; // consider checking current value
                actionEl.onclick = (e) => {
                    this.adapter().triggerListItemEvent({ event: "item-click", items: [this.item()] });
                };
            }
        });
    }
}
