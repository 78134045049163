@if(canGoPrev){
<button class="p-btn" [style.color]="color || 'black'" [style.background]="background || 'white'" mat-button mat-button id="prev" (click)="changePage(page - 1)">
    <span class="arrow-icon"> arrow_back_ios_new </span>
</button>
} @for(p of pagesBtns; track p){
<button
    (click)="changePage(p)"
    class="p-btn"
    [style.color]="p === page ? activeColor : (color || 'black')"
    [style.background]="p === page ? activeBackground : (background || 'white')"
    mat-button
    [attr.color]="p === page ? 'primary' : null"
>
    {{p}}
</button>
} @if(canGoNext){
<button class="p-btn" [style.color]="color || 'black'" [style.background]="background || 'white'" mat-button mat-button (click)="changePage(page + 1)">
    <span class="arrow-icon"> arrow_forward_ios </span>
</button>
}

<!-- [class.mat-raised-button]="p === page"     -->
