import { HttpClient } from "@angular/common/http"
import { Injectable, Query } from "@angular/core"
import { IDataListDataSource, DataListAdapter, SDataListItemEvent } from "@s-data-list";
import { Observable, filter, map } from "rxjs"
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";

@Injectable({ providedIn: 'root' })
export class SubscriptionListHttpDataAdapter implements IDataListDataSource {

    constructor(private http: HttpClient) { }

    fetch(query?: Partial<Query> | undefined): Observable<{ data: any[], total: number, query: Partial<Query> }> {
        const page = query?.['page'] ?? '1'
        const perPage = query?.['per_page'] ?? '10';
        const sort = query?.['sort_by'] ?? 'date,desc'
       return this.http.get<any>(`${environment.base_url}/subscription/list?page=${page}&per_page=${perPage}&sort_by=${sort}`)
    }
}


@Injectable({ providedIn: 'root' })
export class SubscriptionListPageAdapter extends DataListAdapter {
    constructor(private _dataAdapter: SubscriptionListHttpDataAdapter,
        ) {
        super(_dataAdapter)

        
    }

}