import { Component } from "@angular/core";
import { animate, keyframes, query, stagger, style, transition, trigger } from "@angular/animations";
import { CardsGridComponent } from "../cards-grid/cards-grid.component";
import { CommonModule } from "@angular/common";

@Component({
    selector: "sdl-cards-list",
    templateUrl: "./cards-list.component.html",
    styleUrls: ["./cards-list.component.scss"],
    standalone: true,
    imports: [CommonModule],
    animations: [
        trigger("listAnimation", [
            transition("* => *", [
                query(":enter", style({ opacity: 0 }), { optional: true }),

                query(
                    ":enter",
                    stagger("75ms", [
                        animate(
                            "150ms ease-in",
                            keyframes([
                                style({ opacity: 0, transform: "translateY(16px)", offset: 0 }),
                                style({ opacity: 0.85, transform: "translateY(8px)", offset: 0.3 }),
                                style({ opacity: 1, transform: "translateY(0)", offset: 1 }),
                            ]),
                        ),
                    ]),
                    { optional: true },
                ),
            ]),
        ]),
    ],
})
export class CardsListComponent extends CardsGridComponent {}
