
:host {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    width: 100%;

    &>.embed-f,
    &.embed-f>div:first-child {
        display: flex;
        flex-flow: column nowrap;
        height: 100%;
        width: 100%;
    }

    * {
        margin: 0;
        padding: 0;
    }

    html,
    body,
    #wrapper {
        width: 100%;
        height: 100%;
    }

    iframe {
        border-radius: 0 !important;
    }
}