import { Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "@upupa/language";
import { filter, tap } from "rxjs";
import { CardsGridComponent, ListPaginatorComponent, SDataListItemEvent, SdlItemDirective } from "@s-data-list";
import { FormsListHttpDataAdapter, FormsListPageAdapter } from "../forms-list-adapter.service";
import { TopHeaderComponent } from "../../shared/top-header/top-header.component";
import { CommonModule } from "@angular/common";
import { FormsFilterComponent } from "../../shared/forms-filter/forms-filter.component";
import { MatIconModule } from "@angular/material/icon";

@Component({
    selector: "forms-page",
    templateUrl: "./forms-page.component.html",
    styleUrls: ["./forms-page.component.scss"],
    standalone: true,
    imports: [TopHeaderComponent, CommonModule, FormsFilterComponent, MatIconModule, CardsGridComponent, ListPaginatorComponent, SdlItemDirective],
})
export class FormsPageComponent implements OnInit {
    private readonly adapterService = inject(FormsListHttpDataAdapter);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    public readonly ls = inject(LanguageService);
    async ngOnInit(): Promise<void> {
        this.adapter.events$
            .pipe(filter((e) => e.event === "item-click"))
            .subscribe((e: SDataListItemEvent<any>) => this.router.navigateByUrl(`/${this.ls.language}/robot/${e.items[0]._id}`));
    }

    adapter = new FormsListPageAdapter(this.adapterService);

    readonly breadcrumb = [
        { name: "home", display: "الرئيسية" },
        { name: "robots", display: "قائمة الروبوتس" },
    ];
    query = { page: 1, per_page: 1 };
    set page(page: number) {
        this.query = { ...this.query, page };
    }
    page$ = this.route.queryParams.pipe(tap((qps) => (this.page = +(qps["page"] ?? "1"))));

    filterFunc(filterValue: any) {
        console.log(filterValue);
    }
}
