import { CommonModule } from '@angular/common';
import { Component, computed, input, viewChild, ViewEncapsulation } from '@angular/core';
import { GradeViewerComponent } from '../../components/grade-viewer/grade-viewer.component';

@Component({
    selector: 'personal-skills-report',
    templateUrl: './personal-skills-report.component.html',
    styleUrls: ['../../rise-index-report.component.scss'],
    standalone: true,
    imports: [CommonModule, GradeViewerComponent],
    encapsulation: ViewEncapsulation.None,
})
export class PersonalSkillsReportReportComponent {
    report = input.required<any>();

    groupName = 'الجدارات المهارية الشخصية';
    total = computed(() => this.report()['personal_skills']);

    analytical = viewChild('analytical');
    critical = viewChild('critical');
    creativity = viewChild('creativity');
    innovation = viewChild('innovation');
    leadership = viewChild('leadership');
    flexibility = viewChild('flexibility');

    skills = input.required({
        transform: (v: { name: string; variable: string }[]) => {
            return v.map((skill) => {
                return { name: skill.name, variable: skill.variable, template: this[skill.variable] };
            });
        },
    });
}
