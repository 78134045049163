import { Route } from "@angular/router";
import { environment } from "../environments/environment";
import { ClientLayoutComponent } from "./client/client-layout/client-layout.component";
import { AnswerPageComponent } from "./answer/answer-page/answer-page.component";
import { AuthGuardService } from "./admin-guard.service";
import { HomePageComponent } from "./client/home-page/home-page.component";
import { FormsPageComponent } from "./forms/forms-page/forms-page.component";
import { FormDetailsPageComponent } from "./forms/form-details-page/form-details-page.component";
import { RiseIndexReportComponent } from "./report/reports/orgs/rise-index-report/rise-index-report.component";
import { ReportPageComponent } from "./report/report-page/report-page.component";
import { MySubscriptionsComponent } from "./subscription/my-subscriptions/my-subscriptions.component";
import { SubscriptionResponsesComponent } from "./subscription/subscription-responses/subscription-responses.component";
import AccountLayoutComponent from "./account-layout/account-layout.component";

export const appRoutes: Route[] = [
    {
        path: "",
        redirectTo: `/${environment.defaultLang}/home`,
        pathMatch: "full",
    },
    {
        path: ":lang/print",
        children: [
            { path: ":responseId", component: ReportPageComponent },
            { path: ":responseId/:report", component: ReportPageComponent },
        ],
    },
    {
        path: ":lang/account",
        component: AccountLayoutComponent,
        loadChildren: () => import("./membership.module").then((m) => m.FormsMembershipModule),
    },
    {
        path: ":lang",
        component: ClientLayoutComponent,
        children: [
            {
                path: "report",
                children: [
                    { path: ":responseId", component: ReportPageComponent },
                    { path: ":responseId/:report", component: ReportPageComponent },
                ],
            },
            //this redirect is required because we have some old links where answer components were on /home/subscription
            {
                path: "home/:subscription",
                redirectTo: `answer/:subscription`,
                pathMatch: "full",
            },

            { path: "home", component: HomePageComponent },
            { path: "home/:subscription", component: AnswerPageComponent },
            {
                path: "answer/:subscription",
                component: AnswerPageComponent,
            },
            {
                path: "subscriptions",
                canActivate: [AuthGuardService],
                children: [
                    { path: "list", component: MySubscriptionsComponent },
                    { path: ":subscription", component: AnswerPageComponent },
                    { path: "responses/:subscriptionId", component: SubscriptionResponsesComponent },
                ],
            },
            {
                path: "robot",
                children: [
                    { path: "list", component: FormsPageComponent },
                    { path: ":slug", component: FormDetailsPageComponent },
                ],
            },
        ],
    },
    { path: "**", redirectTo: `${environment.defaultLang}`, pathMatch: "full" },
];
