import { HttpClient } from "@angular/common/http";
import { Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "@upupa/language";
import { filter, tap } from "rxjs";
import { CardsListComponent, ListPaginatorComponent, SDataListItemEvent, SdlItemDirective } from "@s-data-list";
import { SubscriptionListHttpDataAdapter, SubscriptionListPageAdapter } from "../subscription-list-adapter.service";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";

@Component({
    selector: "my-subscriptions",
    templateUrl: "./my-subscriptions.component.html",
    styleUrls: ["./my-subscriptions.component.scss"],
    standalone: true,
    imports: [CommonModule, MatButtonModule, CardsListComponent, ListPaginatorComponent, SdlItemDirective],
})
export class MySubscriptionsComponent implements OnInit {
    private readonly http = inject(HttpClient);
    private readonly adapterService = inject(SubscriptionListHttpDataAdapter);
    private readonly route = inject(ActivatedRoute);
    private readonly router = inject(Router);
    public readonly ls = inject(LanguageService);

    subscriptions = [];
    adapter = new SubscriptionListPageAdapter(this.adapterService);
    query = { page: 1, per_page: 25 };
    set page(page: number) {
        this.query = { ...this.query, page };
    }
    page$ = this.route.queryParams.pipe(tap((qps) => (this.page = +(qps["page"] ?? "1"))));

    async ngOnInit(): Promise<void> {
        this.adapter.events$.pipe(filter((e) => e.event === "item-click")).subscribe((e: SDataListItemEvent<any>) => {
            this.navigate(e.items[0]);
        });
    }

    navigate(sub: any) {
        this.router.navigateByUrl(`/${this.ls.language}/subscriptions/responses/${sub._id}`);
    }
}
