<div class="page">
    <img class="cover" src="assets/rise-index-report/images/bg-2.png" [alt]="groupName" />
    <div class="wrapper">
        <h2 class="blue-text">{{ groupName }}</h2>
        <p>الجدارات المعرفية هي المعارف التي تحتاجها في ريادة الأعمال الاجتماعية والبيئية والمواضيع المرتبطة بها.</p>
        <p>نركز في الجدارات المعرفية على أهم ثلاث جدارات:</p>
        <ul>
            @for (skill of skills(); track $index) {
                <li>{{ skill.name }}</li>
            }
        </ul>
        <grade-viewer [pharse_1]="'بلغت قيمة مؤشر جداراتك المعرفية:'" [grade]="total()"></grade-viewer>
        @if (total() < 50) {
            <p>تحتاج إلى القراءة والاطلاع على العلوم ذات الصلة بعملك كشرط أساسي لنجاحك في مشروعك الريادي.</p>
        } @else {
            <p>قطعت شوطاً كبيراً في القراءة والاطلاع، وتحتاج إلى الاستمرار في التعلم والقراءة اليومية.</p>
        }
        <p>وسنأتي على تفصيل نتيجة كل جدارة وخطوات تطويرك لهذه الجدارة انطلاقاً من نتائج تقييمك.</p>
    </div>
</div>

@for (skill of skills(); track i; let i = $index) {
    <ng-container *ngTemplateOutlet="skill.template(); context: { variable: skill.variable, score: report()[skill.variable] }"></ng-container>
}

<ng-template #k_economic let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">المعرفة بالاقتصاد</h2>
            <p>المعرفة بالاقتصاد هي الفهم الكافي للعوامل الاقتصادية المؤثرة، وتوظيفها لتحقيق الأثر المستدام.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة المعرفة بالاقتصاد'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>ابدأ بتعلم أساسيات الاقتصاد وفهم مصطلحات مهمة مثل الاستثمار والتضخم؛ لتعزيز معرفتك بالمجال.</li>

                    <li>تعرّف على أهم مفاهيم الاستدامة والطاقة المتجددة؛ لتكون على دراية بأحدث التوجهات الاقتصادية المستدامة.</li>

                    <li>تابع الأخبار الاقتصادية والمصادر الموثوقة بانتظام؛ لتعميق معرفتك وفهمك للتغيرات في الاقتصاد.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>استمر في تعميق معرفتك بمواضيع الاقتصاد والاستثمار؛ لتحليل الفرص وتقييم المخاطر بفعالية.</li>

                    <li>واصل متابعة تطورات الاستدامة والطاقة المتجددة؛ لتطبيق أفضل الممارسات المستدامة في عملك.</li>

                    <li>استخدم معرفتك الاقتصادية لمواكبة التغيرات، وتوجيه مشروعك نحو فرص النمو المتاحة في السوق.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #k_regulations let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg-2.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">المعرفة بقوانين وتشريعات الاستدامة والشركات الاجتماعية</h2>
            <p>
                المعرفة بقوانين وتشريعات الاستدامة والشركات الاجتماعية هي الإلمام بالإطار القانوني والتنظيمي الذي يدعم الممارسات المستدامة، وتحقيق الأثر الاجتماعي والبيئي المنشود
                بشكل قانوني.
            </p>
            <grade-viewer
                [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'"
                [grade]="score"
                [pharse_2]="'من هذه جدارة المعرفة بقوانين وتشريعات الاستدامة والشركات الاجتماعية'"
            ></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>ابدأ بتوسيع معرفتك بقوانين وتشريعات الاستدامة والشركات الاجتماعية؛ عبر قراءة المصادر القانونية المتخصصة.</li>

                    <li>تعرف على الأنظمة والقوانين المتعلقة بالشركات الناشئة؛ لفهم كيفية التفاعل معها والامتثال لها.</li>

                    <li>احرص على متابعة التحديثات القانونية بانتظام؛ لتعزيز فهمك للتغيرات المؤثرة على شركتك.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>استمر في متابعة التشريعات الجديدة للاستدامة؛ لضمان توافق مشروعك مع أفضل الممارسات البيئية والاجتماعية.</li>

                    <li>استخدم معرفتك بالأنظمة القانونية للشركات الناشئة؛ لتطوير استراتيجيات متكاملة تمتثل للقوانين وتحقق الاستدامة.</li>

                    <li>شارك معرفتك القانونية مع فريقك وشركائك؛ لضمان الالتزام، وتعزيز الفهم المشترك للمتطلبات التشريعية.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #k_trends let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">مواكبة التوجهات الحديثة في الابتكار والريادة</h2>
            <p>
                مواكبة التوجهات الحديثة في الابتكار والريادة هي القدرة على متابعة أحدث الأفكار والتقنيات لتعزيز المرونة وتحقيق الأثر الإيجابي المستدام في مواجهة التغيرات السريعة.
            </p>
            <grade-viewer
                [pharse_1]="'ووفقاً لإجاباتك على أسئلة مواكبة التوجهات الحديثة في الابتكار والريادة، فأنت تمتلك'"
                [grade]="score"
                [pharse_2]="'من هذه الجدارة المعرفية'"
            ></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>ابدأ بمتابعة المقالات والأخبار المتعلقة بالاستدامة والريادة بانتظام؛ لتبقى على اطلاع دائم بالتطورات الحديثة.</li>

                    <li>تعرّف على مفاهيم الابتكار الحديثة مثل التصميم السريع (Design Sprint) وتطبيقاتها في الريادة الاجتماعية.</li>

                    <li>استعرض أمثلة من المشاريع الريادية التي تتناول قضايا اجتماعية وبيئية؛ لاكتساب أفكار ملهمة وممارسات فعّالة.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>واصل متابعة مستجدات الاستدامة والريادة عبر مصادر موثوقة؛ لتبقى على اطلاع دائم بأحدث الاتجاهات.</li>

                    <li>استثمر معرفتك بمفاهيم الابتكار الحديثة؛ لتطوير حلول ريادية فعّالة تلبي الاحتياجات المجتمعية والبيئية.</li>

                    <li>استخدم أمثلة المشاريع الريادية الناجحة كمصادر إلهام؛ لتوسيع نطاق تأثير مشروعك، وتحقيق الاستدامة.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>
