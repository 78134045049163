<div class="page">
    <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="groupName" />
    <div class="wrapper">
        <h1 class="orange-text">تقييم الجدارات</h1>
        <h2 class="blue-text">{{ groupName }}</h2>
        <p>الجدارات الشخصية لرائد الأعمال الاجتماعي والبيئي هي مجموعة من القدرات النفسية والمعرفية التي تمكّنه من التفاعل مع الآخرين، والتفكير، وحل المشكلات، واتخاذ القرار.</p>
        <p>نركز في الجدارات الشخصية على أهم ست جدارات:</p>
        <ul>
            @for (skill of skills(); track $index) {
                <li>{{ skill.name }}</li>
            }
        </ul>
        <grade-viewer [pharse_1]="'بلغت قيمة مؤشر جداراتك الشخصية'" [grade]="total()"></grade-viewer>
        @if (total() < 50) {
            <p>تحتاج إلى خطوات شجاعة وجادة لتطوير جداراتك الشخصية، لأنها ستكون السر وراء نجاحك في مختلف جوانب الحياة، بما فيها رحلتك الريادية.</p>
        } @else {
            <p>أنت متطور في جداراتك الشخصية، وتحتاج إلى خطة للحفاظ عليها، ومواصلة توظيفها بذكاء في تطوير مشروعك الريادي لتحقيق أقصى أثر.</p>
        }
        <p class="my-2">وسنأتي على تفصيل نتيجة كل جدارة، وخطوات تطويرك لها، انطلاقاً من نتائج تقييمك.</p>
    </div>
</div>

@for (skill of skills(); track i; let i = $index) {
    <ng-container *ngTemplateOutlet="skill.template(); context: { variable: skill.variable, score: report()[skill.variable] }"></ng-container>
}

<ng-template #analytical let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg-2.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">التحليل</h2>
            <p>جدارة التحليل هي قدرتك على فهم المشكلة، واستخلاص الحلول؛ من خلال جمع وتحليل البيانات والمعلومات.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة التحليل'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>خصص وقتًا كافيًا لجمع المعلومات من مصادر متنوعة وموثوقة لفهم المشكلة بشكل شامل.</li>

                    <li>درب نفسك على التفكير النقدي وتجزئة المشكلة الكبيرة إلى مشكلات صغيرة لتحديد الأسباب والعلاقات واستخلاص النتائج.</li>

                    <li>قارن بين الحلول المختلفة بشكل موضوعي، مع مراعاة إيجابيات وسلبيات كل حل.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>عمّق مهاراتك التحليلية بتطبيق تقنيات متقدمة كتحليل البيانات الكمية والنوعية.</li>

                    <li>شارك تحليلاتك مع الآخرين؛ لتعزيز التعاون، والاستفادة من رؤى جديدة لربما لم تتنبّه لها من قبل.</li>

                    <li>استثمر مهارتك في التحليل لتطوير خطط استراتيجية طويلة المدى تسهم في نمو مشروعك وتوسعه.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #critical let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">التفكير النقدي</h2>
            <p>جدارة التفكير النقدي هي قدرتك على مراجعة وتقييم مختلف السيناريوهات بالاعتماد على الأدلة والبراهين. </p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة التفكير النقدي'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>اعمل على تحسين قدرتك على التمييز بين الأدلة القوية والضعيفة؛ بالتحقق من مصادر المعلومات ومدى موثوقيتها.</li>

                    <li>ابحث عن نقاط القوة والضعف في الحلول والأفكار، ولا تركز فقط على جانب واحد.</li>

                    <li>تمرّن على التفكير المستقبلي؛ من خلال تحليل النتائج المحتملة لكل حل قبل اتخاذ القرار.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>عزّز مهاراتك النقدية من خلال ممارسة التحدي الفكري؛ عبر مناقشة آراء مختلفة وتحليلها بعمق.</li>

                    <li>اعتمد على التفكير النقدي لتحديد المخاطر المحتملة والسيناريوهات البديلة لكل قرار.</li>

                    <li>وظّف مهارتك في التفكير النقدي لتحليل استراتيجيات مختلف العاملين في السوق وتحديد نقاط التكامل الممكنة لمشروعك.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #creativity let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg-2.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">الإبداع</h2>
            <p>جدارة الإبداع هي قدرتك على تصور حلول جديدة انطلاقاً من البحث، والفضول، والانفتاح على أفكار الآخرين. </p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة الإبداع'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>خصّص وقتاً لاستكشاف أفكار جديدة؛ من خلال البحث عن حلول غير تقليدية وتدوينها دون الحكم عليها.</li>

                    <li>حفّز فضولك؛ بطرح المزيد من الأسئلة والتفاعل مع الأشخاص ذوي الثقافات المختلفة لتوسيع آفاقك.</li>

                    <li>درّب نفسك على الانفتاح للأفكار الخارجة عن المألوف؛ بتجربة أنشطة جديدة تخرجك من الأمور التي اعتدت عليها.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>استثمر إبداعك في تصميم حلول مبتكرة تضيف قيمة مميزة لمشروعك.</li>

                    <li>وسّع نطاق فضولك؛ بالبحث عن مصادر غير تقليدية للإلهام، والمشاركة في فعاليات متعددة التخصصات.</li>

                    <li>استمر في تحدي نفسك لقبول الأفكار المختلفة، وتطويرها لتصبح أدوات قوية لحل المشكلات.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #innovation let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">الابتكار</h2>
            <p>جدارة الابتكار هي قدرتك على تطوير وتجريب حلول جديدة مع المستهدفين؛ من خلال الاستلهام من المعارف والتجارب المختلفة.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة الابتكار'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>وسّع مداركك بالاطلاع على العلوم والأفكار الحديثة، واستلهم منها لتصميم حلول مبتكرة.</li>

                    <li>ابدأ بتجربة أمور جديدة في حياتك الاجتماعية والمهنية؛ لتزيد من مرونتك في تقبل الأفكار المبتكرة.</li>

                    <li>تدرب على تطوير أفكارك بانتظام؛ عبر تقييم نتائج تجربتها، وإجراء التحسينات اللازمة بسرعة.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>احرص على الاستفادة من العلوم والأفكار الحديثة؛ لتحفيز ابتكارات جديدة تميز مشاريعك.</li>

                    <li>استثمر شغفك بالتجربة والتجديد؛ لتضيف قيمة متطورة ومبتكرة لبيئتك الاجتماعية والمهنية.</li>

                    <li>شارك أفكارك المبتكرة مع الآخرين، وابحث عن فرص للتعاون معهم لتطويرها وتحويلها إلى واقع.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #leadership let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg-2.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">القيادة</h2>
            <p>جدارة القيادة هي قدرتك على وضع رؤية ملهمة، والتأثير بمن حولك من موارد للتوجه نحوها.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة القيادة'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>اعمل على تطوير رؤيتك المستقبلية؛ عبر تحديد أهداف واضحة وطموحة تستند إلى قيمك وأهداف الفريق.</li>

                    <li>مارس جدارات التأثير والتحفيز؛ من خلال التواصل الإيجابي، والاستماع لاحتياجات الآخرين، وتقديم الدعم المناسب.</li>

                    <li>حسّن قدرتك على استكشاف الموارد المتاحة حولك، وتوجيهها بشكل فعّال؛ عبر التأمل والجلوس مطوّلاً مع فريقك.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>عزّز رؤيتك الملهمة؛ من خلال مراجعة وتحديث الأهداف بعيدة المدى، والتأكد من توافقها مع تطلعات الفريق.</li>

                    <li>واصل تحفيز الآخرين؛ عبر تقديم تحديات جديدة، والاحتفال بالإنجازات لتعزيز التزامهم ورغبتهم في التطور.</li>

                    <li>استثمر مهارتك في توجيه الموارد؛ عبر تحديد الكفاءات المناسبة لكل مهمة، وتحقيق الاستخدام الأمثل للقدرات المتاحة.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #flexibility let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">المرونة</h2>
            <p>جدارة المرونة هي قدرتك على التكيف مع التغيرات التي تحصل؛ من خلال الاستعداد المسبق، والحفاظ على هدوئك عند التغيير.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة المرونة'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>تدرب على التحكم بمشاعرك أثناء التوتر؛ عبر الاسترخاء والتنفس العميق، والوضوء والصلاة للحفاظ على هدوئك.</li>

                    <li>اعمل على تحسين قدرتك على التكيف مع التغيرات تدريجياً؛ بتجربة مواقف جديدة بشكل متعمد.</li>

                    <li>قم بتطوير خطط احتياطية، واستعد للمشكلات المحتملة؛ لتحسين جاهزيتك وتقليل تأثير المفاجآت.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>واظب على الصلاة، وحافظ على هدوئك مع أي مشكلة تصادفك.</li>

                    <li>استفد من قدرتك على التكيف السريع بالتعامل مع التحديات كفرص للنمو والتطور.</li>

                    <li>عزّز مهارتك في التوقع والتخطيط؛ عبر تقييم المخاطر بانتظام لتحسين استجابتك للمستجدات.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>
