import { CommonModule } from "@angular/common";
import { Component, input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: 'grade-viewer',
    templateUrl: './grade-viewer.component.html',
    styleUrls: ['../../rise-index-report.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class GradeViewerComponent {
    grade = input.required<any>();
    pharse_1 = input.required<string>();
    pharse_2 = input<string>('');
}