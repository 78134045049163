@let itm = item();
{{itm | json}}
@if (itm.image) {
    <img mat-card-image [src]="itm.image" [attr.alt]="itm.title" />
}
@if (itm.title) {
    <h3 sdlItemAction="click">{{ itm.title }}</h3>
}
<div>
    @if (itm.description) {
        <p>{{ itm.description }}</p>
    }
    @let primaryBtn = itm.primaryBtn;
    @let secondaryBtn = itm.secondaryBtn;
    @if (primaryBtn || secondaryBtn) {
        <div class="footer">
            @if (primaryBtn) {
                <button (click)="handleActionClick(primaryBtn, $event)" class="card-btn primary">{{ primaryBtn.text }}</button>
            }
            @if (secondaryBtn) {
                <button (click)="handleActionClick(secondaryBtn, $event)" class="card-btn secondary">{{ secondaryBtn.text }}</button>
            }
        </div>
    }
</div>
