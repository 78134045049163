import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SSwiperComponent } from './s-swiper/s-swiper.component';


import { SSwiperSlideDirective } from './s-swiper-slide.directive';

const impExpDecs = [
    SSwiperComponent,
    SSwiperSlideDirective
];


@NgModule({
    declarations: [...impExpDecs],
    imports: [CommonModule],
    exports: [...impExpDecs],
})
export class SSwiperModule { }
