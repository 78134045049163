<ng-container *ngIf="(adapter.loading$ | async) === true; else gridTemplate">
    <div class="loading">
        <p>loading...</p>
    </div>
</ng-container>

<ng-template #gridTemplate>
    <p *ngIf="adapter.error !== null; else dataTemplate">
        {{ adapter.error }}
    </p>
</ng-template>
<ng-template #dataTemplate>
    <ng-container *ngIf="adapter.items$ | async as items">
        <div class="grid-list" *ngIf="items.length > 0" [@listAnimation]="items.length">
            <div class="card" *ngFor="let item of items" direction="col">
                <ng-container *ngTemplateOutlet="cardTemplate(); context: { item, adapter }"></ng-container>
            </div>
        </div>
        <p *ngIf="items.length === 0">No Data</p>
    </ng-container>
</ng-template>

<ng-template #defaultCardTemplate let-item="item" let-adapter="adapter">
    <sdl-card sdlItem [item]="item" [adapter]="adapter"> </sdl-card>
</ng-template>
