@if (adapter) {
    <div class="container">
        <sdl-cards-list [cardTemplate]="bookCardTemplate" [adapter]="adapter" [query]="query"> </sdl-cards-list>
        <sdl-list-paginator class="paginator" [page]="query.page" [pageSize]="query.per_page" [total]="adapter.total$ | async" (pageChange)="page = $event"></sdl-list-paginator>
    </div>
}

<ng-template #bookCardTemplate let-item="item" let-adapter="adapter">
    <div sdlItem [item]="item" [adapter]="adapter" style="margin-top: 1rem; border-bottom: 1px solid #ddd" class="flex row nowrap a-center" style="width: 100%; padding: 0.5rem 0; margin-block-end: 1rem; border-block-end: 1px dotted #eee;">
        <div class="flex col">
            <h4>{{ item.name }}</h4>
            <span>{{ item.date | date: "yyyy/MMM/dd" }}</span>
        </div>
        <span class="spacer"></span>
        <div class="flex row nowrap a-center">
            <button mat-stroked-button (click)="navigate(item)">الإجابات</button>
        </div>
    </div>
</ng-template>
