import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { RatingStarsComponent } from '../rating-stars/rating-stars.component';

export type ReviewCardVm = {
    image: string;
    title: string;
    subtitle: string;
    rating?: number;
    body?: string;
};

@Component({
    selector: 'review-card',
    templateUrl: './review-card.component.html',
    styleUrls: ['./review-card.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule, RatingStarsComponent],
})
export class ReviewCardComponent {
    @Input() data: ReviewCardVm = defaultAuthor;
}

export const defaultAuthor = {
    subtitle: 'Author',
    image: 'https://cdn0.iconfinder.com/data/icons/avatar-profession-3/512/Avatar_29_-_Professions_-_Background_-_Copy-09-512.png',
    title: 'الباحثة الدكتورة سارة عبد الرحمن',
    rating: 2.25,
    body: 'تمتلك الدكتورة سارة خبرة واسعة في علوم النفس وعلم البيانات',
} as ReviewCardVm;

export const defaultVisitor = {
    subtitle: 'Visitor',
    image: 'https://cdn0.iconfinder.com/data/icons/avatar-profession-3/512/Avatar_29_-_Professions_-_Background_-_Copy-09-512.png',
    title: 'زائر',
    body: 'تجربة رائعة ونتيجة مذهلة لم أتوقعها صراحة',
} as ReviewCardVm;
export const defaultSubscriber = {
    subtitle: 'Subscriber',
    image: 'https://cdn0.iconfinder.com/data/icons/avatar-profession-3/512/Avatar_29_-_Professions_-_Background_-_Copy-09-512.png',
    title: 'سمير عز الدين',
    rating: 3.25,
    body: 'الفكرة جميلة نوعاً ما لكنها تحتاج للقليل من التفاصيل, ماحبيت',
} as ReviewCardVm;
