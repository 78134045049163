<div class="page">
    <img class="cover" src="assets/rise-index-report/images/bg-2.png" [alt]="groupName" />
    <div class="wrapper">
        <h2 class="blue-text">{{ groupName }}</h2>
        <p>الجدارات المهنية لرائد الأعمال الاجتماعي والبيئي هي مجموعة من القدرات التطبيقية والتنظيمية التي تتعلق بسياق عمله في المؤسسات وقطاع الأعمال.</p>
        <p>نركز في الجدارات المهنية على أهم تسع جدارات:</p>
        <ul>
            @for (skill of skills(); track $index) {
                <li>{{ skill.name }}</li>
            }
        </ul>
        <grade-viewer [pharse_1]="'بلغت قيمة مؤشر جداراتك المهنية:'" [grade]="total()"></grade-viewer>
        @if (total() < 50) {
            <p>تحتاج إلى خطوات شجاعة وجادة لتطوير جداراتك المهنية، لأنها ستكون مفتاح تحويل فكرتك إلى مشروع تطبيقي مبتكر ينمو وينجح في قطاع العمل.</p>
        } @else {
            <p>متطوّر في جداراتك المهنية، وتحتاج إلى خطة للحفاظ عليها، ومواصلة توظيفها بذكاء في تطوير مشروعك الريادي لتحقيق أقصى أثر.</p>
        }
        <p>سنأتي على تفصيل نتيجة كل جدارة، وخطوات تطويرك لهذه الجدارة انطلاقاً من نتائج تقييمك.</p>
    </div>
</div>
@for (skill of skills(); track i; let i = $index) {
    <ng-container *ngTemplateOutlet="skill.template(); context: { variable: skill.variable, score: report()[skill.variable] }"></ng-container>
}

<ng-template #safety let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">السلامة المهنية</h2>
            <p>جدارة السلامة المهنية هي قدرتك على الحفاظ على سلامتك الجسدية والنفسية في العمل.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة السلامة المهنية'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>احرص على التثقيف المستمر حول قواعد السلامة في العمل، وتطبيقها بانتظام لضمان بيئة آمنة.</li>

                    <li>التزم بممارسة الرياضة بانتظام؛ لتجنب مشاكل العمود الفقري الناتجة عن الجلوس لفترات طويلة.</li>

                    <li>تجنب ضغط العمل الزائد، واحرص على أخذ فترات استراحة منتظمة؛ لتجنب الإرهاق والمخاطر الصحية.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>استمر في متابعة أحدث معايير السلامة المهنية، وتحديث ثقافتك بشكل مستمر.</li>

                    <li>شارك زملاءك إرشادات السلامة؛ لتشجيع بيئة عمل صحية وآمنة للجميع.</li>

                    <li>وظّف مهارتك أكثر في تنظيم فترات الراحة وضبط التوازن بين العمل والصحة؛ لتحقيق أداء مستدام.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #planning_and_organizing let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg-2.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">التخطيط والتنظيم</h2>
            <p>جدارة التخطيط والتنظيم هي قدرتك على وضع الأهداف، وترجمة هذه الأهداف في خطة عمل، والالتزام بها.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="''"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>ابدأ بوضع أهداف واضحة ومحددة تساعدك على توجيه جهودك بشكل أفضل.</li>

                    <li>قسم الأهداف إلى خطوات عملية وصغيرة؛ لتسهيل التنفيذ ومتابعة التقدم.</li>

                    <li>احرص على الالتزام بالمواعيد النهائية؛ عبر استخدام أدوات تنظيمية مثل التقويم وتطبيقات إدارة المهام.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>عزّز قدرتك على تحقيق الأهداف؛ بوضع خطط مرنة وتحديثها بانتظام وفق التغيرات.</li>

                    <li>استخدم أدوات رقمية متقدمة؛ لمتابعة التقدم، وتحديد الأولويات؛ لتحسين كفاءة التنفيذ.</li>

                    <li>واصل الالتزام بالمواعيد النهائية؛ بتحديد مراحل زمنية لكل خطوة، وتحليل نتائج التخطيط الدوري.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #caoching let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">التوجيه</h2>
            <p>جدارة الدعم والتوجيه هي قدرتك على دعم الفريق في مهامهم من خلال التفويض ووضع مؤشرات لتقييم أداء الفريق وتقديم الدعم والإرشاد لهم.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة الدعم والتوجيه'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>ابدأ بتحديد مؤشرات قياس أداء بسيطة وملائمة، تساعدك على متابعة التقدم وتقييم النتائج.</li>

                    <li>درّب نفسك على تفويض المهام بوضوح وثقة، وتأكد من اختيار الأشخاص المناسبين لكل مهمة.</li>

                    <li>كن داعماً لزملائك ومرؤوسيك؛ بتقديم النصائح والإرشادات اللازمة لتسهيل إنجاز مهامهم.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>واصل تطوير مؤشرات الأداء لتكون شاملة ودقيقة، مما يسهل تقييم وتحسين العمل.</li>

                    <li>استخدم مهارتك في التفويض بفعالية أكثر لتوزيع المسؤوليات بكفاءة وتعزيز إنتاجية الفريق.</li>

                    <li>عزّز بيئة الدعم؛ عبر تقديم الملاحظات البناءة وركّز في التوجيه على الأعمال المقبلة أكثر من السابقة.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #change_management let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg-2.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">إدارة التغيير</h2>
            <p>جدارة إدارة التغيير هي قدرتك على تحديد عوائق التغيير، والتغلب عليها.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة إدارة التغيير'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>ابدأ بتحليل عوائق التغيير، وحدد أسبابها بوضوح؛ لفهم كيفية التغلب عليها.</li>

                    <li>طوّر استراتيجيات فعالة للتغلب على العوائق تدريجياً، مثل التخطيط المسبق واستشارة الزملاء.</li>

                    <li>اعمل على دمج التغيير مع الأعمال والمشاريع بمرونة تدريجية؛ لضمان استيعابه وتحقيق نتائج إيجابية.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>واصل تحليل وتوقع عوائق التغيير مسبقاً؛ لتحسين استعدادك لمواجهتها.</li>

                    <li>ساعد الآخرين على فهم وتقبل التغيير من خلال مشاركة خبراتك وتقديم الدعم لهم.</li>

                    <li>عزز استراتيجيات دمج التغيير، عبر التحديث المستمر للمشاريع؛ لضمان استمرارية التطوير والنجاح.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #team_working let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">العمل ضمن فريق</h2>
            <p>جدارة العمل ضمن فريق هي قدرتك على القيام بدورك في الفريق، والتفاعل مع أعضائه، متجاوزاً مشكلات العمل في فريق.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة العمل ضمن فريق'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>احرص على تعزيز تفاعلك الإيجابي مع الفريق؛ من خلال الاستماع بفاعلية، والمساهمة بأفكار بنّاءة.</li>

                    <li>تعلم استراتيجيات إدارة الصراعات، مثل التفاوض والحوار؛ لتخفيف التوترات داخل الفريق.</li>

                    <li>التزم بدورك ومسؤولياتك داخل الفريق؛ لضمان سير العمل بانسيابية وفعالية.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>واصل تعزيز تفاعلك الإيجابي لدعم روح التعاون، وتحفيز الآخرين على المشاركة بفعالية.</li>

                    <li>انضم إلى فرق عمل جديدة ومتنوعة لاكتساب خبرات أوسع وتطوير مهاراتك في التعامل مع شخصيات مختلفة.</li>

                    <li>عزّز التزامك بالأدوار والمسؤوليات، بتوجيه أعضاء الفريق وتشجيعهم على أداء مهامهم بكفاءة.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #organizational_culture let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg-2.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">الثقافة المؤسسية</h2>
            <p>الثقافة المؤسسية هي مدى الاضطلاع بالثقافة الإدارية للمؤسسات بشكل عام، والمؤسسة التي تعمل فيها (إن وجدت) بشكل خاص.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة الثقافة المؤسسية'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>ابدأ بتطوير معرفتك بالهياكل الإدارية وآليات التواصل الداخلي؛ لفهم كيفية عمل المؤسسات بشكل أفضل.</li>

                    <li>تعلّم قراءة وتحليل أهم أرقام القوائم المالية؛ لتعزيز فهمك للوضع المالي للمؤسسة.</li>

                    <li>اطلع على مبادئ إدارة الموارد البشرية الأساسية، مثل التوصيف الوظيفي ونظام الأجور؛ لتعزيز ثقافتك المؤسسية.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>واصل تطوير معرفتك بالهياكل الإدارية، وتعمق في فهم آليات التواصل؛ لتعزيز كفاءتك التنظيمية.</li>

                    <li>
                        استخدم معرفتك بالثقافة المؤسسية لتحليل أداء الشركات الناشئة ورواد الأعمال، وتحديد فرص الاستثمار أو التعاون بناءً على فهمك لنقاط القوة والضعف في ثقافتهم.
                    </li>

                    <li>استثمر معرفتك بمبادئ إدارة الموارد البشرية لدعم بيئة عمل متكاملة، وتطوير ممارسات تعزز الإنتاجية والرضا الوظيفي.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #professional_communication let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">التواصل المهني</h2>
            <p>جدارة التواصل المهني هي قدرتك على التواصل الفعال مع زملائك في العمل بمختلف طرق التواصل المكتوب والشفهي.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة التواصل المهني'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>اعمل على تحسين تواصلك الفعّال مع الزملاء؛ من خلال الإنصات الجيد والتعبير الواضح عن أفكارك.</li>

                    <li>طوّر جداراتك في التحرير والمراسلات باللغة العربية؛ عبر قراءة النصوص الرسمية والتدريب على الكتابة.</li>

                    <li>ابدأ بتحسين لغتك الإنجليزية تدريجياً من خلال دورات مكثفة في التحدث والكتابة.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>واصل تعزيز تواصلك الفعّال داخل الفريق لتقوية التعاون وتحقيق الأهداف المشتركة.</li>

                    <li>استخدم مهاراتك في التحرير والمراسلات لإنتاج محتوى إعلامي جذاب ومؤثر يساهم في انتشار وتسويق مشروعك.</li>

                    <li>استثمر إتقانك للغة الإنجليزية في توسيع شبكة علاقاتك المهنية، وتعزيز فرصك الدولية.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #business_technology let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg-2.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">التكنولوجيا في العمل</h2>
            <p>جدارة التكنولوجيا هي قدرتك على استخدام التكنولوجيا المناسبة في العمل، وبالطريقة الصحيحة.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة التكنولوجيا'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>اعمل على تطوير مهاراتك في تطبيقات الحاسوب الأساسية مثل الأوفيس؛ لتسهيل إنجاز المهام اليومية بكفاءة.</li>

                    <li>ابدأ بتعلم أساسيات البيانات والذكاء الاصطناعي؛ لفهم كيفية تطبيقها في بيئة العمل.</li>

                    <li>حاول التعرّف على حلول تكنولوجية مختلفة، وتقييم مناسبتها للمشكلات التي تواجهها في العمل.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>استمر في تحسين مهاراتك في التطبيقات الذكية؛ لتسريع العمليات وزيادة الإنتاجية.</li>

                    <li>استثمر معرفتك في البيانات والذكاء الاصطناعي لتحليل المعلومات، وتطوير حلول مبتكرة.</li>

                    <li>واصل اقتراح التكنولوجيا المناسبة للمشاكل التنظيمية، لتعزيز الكفاءة وتحقيق النتائج المستهدفة.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>

<ng-template #remote_working let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">العمل عن بعد</h2>
            <p>جدارة العمل عن بعد هي قدرتك على العمل والتواصل عن بعد، من المنزل أو غيره، مع الحفاظ على إنتاجيتك وتحفيزك.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة العمل عن بعد'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>خصّص مساحة عمل مريحة وهادئة في المنزل؛ لتحسين تركيزك وزيادة إنتاجيتك.</li>

                    <li>ضع أهدافاً يومية صغيرة؛ لتحفيز نفسك، والالتزام بجدول عمل منتظم.</li>

                    <li>تدرب على استخدام تطبيقات الاجتماع عن بُعد مثل زوم؛ لتتمكن من التواصل الفعال مع فريقك.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>واصل تعزيز تركيزك في بيئة العمل المنزلية؛ عبر تحسين روتينك اليومي، وتنظيم وقتك بفعالية.</li>

                    <li>استخدم مهارتك في التحفيز الذاتي؛ لزيادة إنتاجيتك، وتحقيق التوازن بين العمل والحياة.</li>

                    <li>استثمر إتقانك لتطبيقات الاجتماع عن بُعد؛ لتسهيل التواصل الفعّال، والتعاون مع زملائك بشكل مثالي.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>
