import { Component, Input } from '@angular/core';
import { ReportViewModel } from '../../../../shared/types';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { CommonModule } from '@angular/common';
export class Report {
    evaluations!: SamModel[];
}

export const TYPE = ['theoreticalWorks', 'theoreticalDoNotWork', 'practicalWorks', 'practicalDoNotWork'] as const;
export type Type = (typeof TYPE)[number];

export type SamModel = {
    name: string;
    theory: number;
    work: 0 | 1;
    type: Type;
};

@Component({
    selector: 'reading-report',
    templateUrl: './reading-report.component.html',
    styleUrls: ['./reading-report.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class ReadingReportComponent {
    static __name = 'reading-robot-full-report';
    static async updateResult(v: ReportViewModel, http: HttpClient): Promise<any> {
        const result = generateResponseReport(v);
        try {
            await firstValueFrom(http.post(`${environment.base_url}/tfr/update-result`, { responseId: v._id, result }));
            return result;
        } catch (err) {
            console.error(err);
        }
    }

    type: Type = 'practicalDoNotWork';
    report: SamModel = null;

    private _reportViewModel: ReportViewModel;
    @Input()
    public get reportViewModel(): ReportViewModel {
        return this._reportViewModel;
    }
    public set reportViewModel(value: ReportViewModel) {
        this._reportViewModel = value;
        if (!value) return;
        this._fillResult();
    }

    private async _fillResult() {
        let result = this.reportViewModel.result;
        if (!result) {
            console.warn(`${ReadingReportComponent.name} has no result to be displayed`);
            result = await ReadingReportComponent.updateResult(this.reportViewModel, this.http);
        }
        this.report = { ...result };
    }

    constructor(private readonly http: HttpClient) {}
}
function typeCalculator(theory: number, work: 0 | 1) {
    if (work === 0) return theory <= 4 ? 'practicalDoNotWork' : 'theoreticalDoNotWork';

    return theory <= 4 ? 'practicalWorks' : 'theoreticalWorks';
}

function generateResponseReport(v: ReportViewModel) {
    const res = {
        name: v.user.name,
        ...v.variables.map((v) => ({ [v.key]: Number(v.number) })).reduce((v, acc) => ({ ...v, ...acc }), {}),
    } as SamModel;
    res['type'] = typeCalculator(res.theory, res.work);
    return res;
}
