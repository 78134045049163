body[dir="rtl"] 
.mat-icon.flipY {
    transform: rotateY(180deg) !important;
    display: flex;
    justify-content:center; 
}

.rating-num{
    margin-inline-end: 1rem;
    display: flex;
    align-items: center;
}
