import { HttpClient } from "@angular/common/http"
import { Injectable, Query } from "@angular/core"
import { IDataListDataSource, DataListAdapter } from "@s-data-list";
import { Observable, map } from "rxjs"

@Injectable({ providedIn: 'root' })
export class FormsListHttpDataAdapter implements IDataListDataSource {

    constructor(private http: HttpClient) { }

    fetch(query?: Partial<Query> | undefined): Observable<{ data: any[], total: number, query: Partial<Query> }> {
        // const subscriptionId = query?.['subscription_id']
        // const q = (query?.['q'] ?? '').trim()
        const page = query?.['page'] ?? '1'
        const perPage = query?.['per_page'] ?? '10'
        const sort = query?.['sort_by'] ?? 'created_at,desc'




        let p = `https://forms.ask-robots.com/api/v2/form?select=created_at,name,published_at,status,title,settings.language,settings.meta&page=${page}&per_page=${perPage}&sort_by=${sort}`
        // if (q.length > 0) p += `&user.email=*${q}*`
        return this.http.get<{ data: any[], total: number, query: Partial<Query> }>(p).pipe(map(res => ({
            ...res, data: res.data.concat(res.data).concat(res.data).map(f => {

                return {
                    ...f,
                    lang: f.settings.language,
                    desc: f.settings.meta.description,
                    image: [{ path: f.settings.meta.image.href }]
                }
            })
        })))
    }
}


@Injectable({ providedIn: 'root' })
export class FormsListPageAdapter extends DataListAdapter {
    constructor(private _dataAdapter: FormsListHttpDataAdapter) {
        super(_dataAdapter)
    }

}