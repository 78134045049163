import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
    standalone: true,
    imports: [CommonModule],
})
export class StepperComponent {
    @Input() public steps: string[] = [];
    @Input() public active = 0;
}
