import { CommonModule } from '@angular/common';
import { Component, computed, input, viewChild, ViewEncapsulation } from '@angular/core';
import { GradeViewerComponent } from '../../components/grade-viewer/grade-viewer.component';

@Component({
    selector: 'cognitive-skills-report',
    templateUrl: './cognitive-skills-report.component.html',
    styleUrls: ['../../rise-index-report.component.scss'],
    standalone: true,
    imports: [CommonModule, GradeViewerComponent],
    encapsulation: ViewEncapsulation.None,
})
export class CognitiveSkillsReportComponent {
    report = input.required<any>();
    total = computed(() => this.report()['competencies_knowledge']);
    groupName = 'الجدارات المعرفية';

    k_economic = viewChild('k_economic');
    k_regulations = viewChild('k_regulations');
    k_trends = viewChild('k_trends');

    skills = input.required({
        transform: (v: { name: string; variable: string }[]) => {
            return v.map((skill) => {
                return { name: skill.name, variable: skill.variable, template: this[skill.variable] };
            });
        },
    });
}
