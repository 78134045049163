const imgs = ['https://forms.ask-robots.com/png']

const FALLBACK_SEO = {
  title: 'Ask robots forms',
  description: 'Ask robots forms',
  image: imgs,
  url: 'https://forms.ask-robots.com/ar',
  twitterCard: 'summary_large_image'
};

const AR_SEO = {
  title: 'Ask robots forms',
  description: 'Ask robots forms',
  image: imgs,
  url: 'https://forms.ask-robots.com/ar',
  twitterCard: 'summary_large_image',

};

export const environment = {
  production: true,
  base_url: 'https://forms.ask-robots.com',
  api_base: 'https://forms.ask-robots.com/api',
  defaultLang: 'ar',
  languages: ['ar', 'en'],
  rendertron_url: 'https://rendertron.raptor7.com',
  fonts: {
    ar: { fontFamily: 'Tajawal' },
    en: { fontFamily: 'Solway' }
  },
  seo: {
    fallBack: FALLBACK_SEO,
    ar: AR_SEO,
    en: FALLBACK_SEO,

    socialMedia: {
      facebookId: '',
      twitterId: ''
    }
  },
  siteKey: '',
  secretKey: ''
};
export function getSeo(lang: string) {
  return environment.seo[lang] ?? environment.seo.fallBack;
}