@import '../reports.scss';

:host {
    --blue-color: #3f5b82;
    --yellow-color: #a6ba31;
    --orange-color: #ed6e4e;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    direction: rtl;
    font-family: 'Noto Sans Arabic', sans-serif !important;
}

.blue-text {
    color: var(--blue-color);
}

.yellow-text {
    color: var(--yellow-color);
}

.orange-text {
    color: var(--orange-color);
}

.logo {
    img:first-child {
        display: block;
        margin-inline: auto;
        width: 100%;
        height: auto;
        margin-block: 1rem;
        max-width: 200px;
    }
}

ul,
ol {
    padding-inline: 0.75rem;
    margin: 0 !important;

    li {
        margin: 0 !important;
        margin-block: 0.5rem;
        margin-inline-start: 0.5rem !important;
        margin-block-end: 0.25rem !important;
        padding: 0 !important;
    }
    li::marker {
        color: var(--blue-color);
    }
}
ul {
    list-style-type: square;
}
.wrapper {
    margin: 0 !important;
    padding: 20mm 20mm 25mm 0 !important;
    height: 100% !important;
}

h1 {
    font-size: 25pt !important;
}
h2 {
    font-size: 22pt !important;
}
h4 {
    font-size: 18pt !important;
}

.my {
    margin-block: 15mm !important;
}

.absolute {
    position: absolute;
    top: 85mm;
    right: 0;
    z-index: 100;
    margin-inline-start: 20mm;
    color: var(--blue-color);
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

table {
    box-sizing: border-box;
    border-spacing: 0;
    margin-block: 1rem;
    border: 1px solid #ddd;
    border-inline-start: none;

    td {
        padding: 0.75rem;
        text-align: center;
        min-width: 200px;
        ul {
            padding: 0 !important;
            padding-inline-start: 0.75rem !important;
            text-align: start;
        }

        img {
            width: 50px;
        }
    }
    td:not(first-child) {
        border-inline-start: 1px solid #ddd;
    }

    td:not(tr:last-child td) {
        border-bottom: 1px solid #ddd;
    }
}

table:not(.index) tr td {
    vertical-align: top;
    font-size: 12pt !important;
    text-align: start !important;
}

.bg-lightblue {
    background: rgba(214, 232, 255, 0.555);
}

.bg-lightcoral {
    background: rgba(255, 214, 214, 0.555);
}

