<!-- Why ?!!!!
We copied this module because it has all the required code and you just removed it and expecting it to work!!! -->


<!-- <input class="search-input" placeholder="ابحث ياستخدام البريد الالكتروني" #searchInput type="text"
        (input)="search$.next(searchInput.value)"> -->
<top-header [title]="'قائمة الروبوتس'" [breadcrumbs]="breadcrumb"></top-header>
<div class="container">

    <div class="page-grid">

        <div class="side-bar">
            <forms-filter (filterApplied)="filterFunc($event)"></forms-filter>
        </div>
        <div class="content">
            <!-- <div class="filter">
                <div class="flex space-between">
                    <div class="sort flex space-between">
                        <label for="select">رتب حسب </label>
                        <select>
                            <option data-display="Select">Nothing</option>
                            <option value="1">Some option</option>
                            <option value="2">Another option</option>
                            <option value="3" disabled>A disabled option</option>
                            <option value="4">Potato</option>
                        </select>
                    </div>
                </div>
            </div> -->
            <sdl-cards-grid class="robots-grid" [adapter]="adapter" [query]="query" [cardTemplate]="customCard">
            </sdl-cards-grid>
            <sdl-list-paginator class="paginator" [page]="query.page" [pageSize]="query.per_page"
                [total]="adapter.total$ | async" (pageChange)="page = $event" [background]="'#424242'" [color]="'#ffffffb3'" [activeBackground]="'#009688'"
                [activeColor]="'white'"></sdl-list-paginator>
        </div>
    </div>
</div>


<ng-template #customCard let-item="item" let-adapter="adapter">
    <ng-container sdlItem [item]="item" [adapter]="adapter">
        <div sdlItemAction="click" class="custom-card">
            <div class="card-image">
                <img [src]="item.image[0].path" alt="{{item.title}}">
            </div>
            <div class="card-body">
                <h4 class="subtitle">تعليم</h4>
                <h1 class="card-title">{{ item.title }}</h1>
                <p class="card-desc">{{ item.desc }}</p>
                
            </div>
            <div class="footer flex space-between">
                <span class="price"><mat-icon>monetization_on</mat-icon>Free</span>
                <span class="pub-date"><mat-icon>schedule</mat-icon> {{item.average_completion_time || '10-25' }} </span>
            </div>
        </div>
    </ng-container>
</ng-template>

<!-- 
    [
    {
        "_id": "rUQvGit8",
        "created_at": "2023-02-27T07:51:20.000Z",
        "published_at": "2023-02-27T14:36:41.000Z",
        "settings": {
            "language": "en",
            "meta": {
                "allow_indexing": true,
                "description": "أسعد بالحوار معك، أقدم فيه النصح لك حول أنسب مجالات القراءة حسب نمط شخصيتك واحتياجاتك",
                "image": {
                    "href": "https://images.typeform.com/images/qyV3xnNLbJFN"
                }
            }
        },
        "title": "روبوت ماذا أقرأ",
        "name": "reading-robot",
        "status": "published"
    }
]
   -->