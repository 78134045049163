import { afterNextRender, Component, inject, Injector, OnInit, signal } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ReportViewModel } from "../../shared/types";
import { CommonModule } from "@angular/common";
import { ReportViewerComponent } from "../report-viewer/report-viewer.component";

@Component({
    selector: "report-page",
    templateUrl: "./report-page.component.html",
    styleUrls: ["./report-page.component.scss"],
    standalone: true,
    imports: [ReportViewerComponent, CommonModule],
})
export class ReportPageComponent implements OnInit {
    private readonly route = inject(ActivatedRoute);
    private readonly http = inject(HttpClient);

    private readonly injector = inject(Injector);
    loading = true;
    reportVm = signal<ReportViewModel>(null);
    async ngOnInit(): Promise<void> {
        afterNextRender(
            async () => {
                await loadGoogleCharts();

                const snapshot = this.route.snapshot;
                const { responseId, report } = snapshot.params;
                const resId = (responseId ?? "").trim();
                const reportId = (report ?? "").trim();

                if (resId.length === 0) throw new Error("Response Id is required.");
                let url = `${environment.base_url}/tfr/report-data?res_id=${responseId}`;
                if (reportId.length > 0) url += `&rep_id=${reportId}`;
                try {
                    const res = await firstValueFrom(this.http.get<ReportViewModel>(url));

                    this.reportVm.set(res);
                } catch (e) {
                    if (e.status === 404) alert(e.error.message);
                    else console.error(e);
                }
            },
            { injector: this.injector },
        );
    }
}
function loadGoogleCharts() {
    // check if on browser
    if (typeof window === "undefined") return Promise.resolve();
    const src = "https://www.gstatic.com/charts/loader.js";
    const script = document.createElement("script");
    script.src = src;
    return new Promise((resolve, reject) => {
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
    });
}
