.review-card {
    width: 100%;
    padding: 1rem;
    border: 1px solid var(--theme-divider);
    border-radius: var(--border-radius);
    .review-card-header{
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        .avatar {
            width: 100px;
            height: 100px;
            margin-inline-end: 2rem;
            border-radius: 50%;
    
            img {
                border-radius: var(--border-radius);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    
        .desc {
            flex-grow: 1;
    
            .title {
                color: var(--theme-text);
                font-size: 16px;
            }
    
            .subtitle {
                color: var(--theme-secondary-text);
                font-size: 14px;
                margin-block: .5rem;
            }
        }
    }
    .review-card-body{
        display: flex;
        align-items: center;
        height: 90px;
            
        p{
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--theme-secondary-text);
            margin-block-start: 1rem;
        }
    }

}