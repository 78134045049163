import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputBaseComponent } from '@upupa/common';
import { DynamicFormService, Field, FormScheme } from '@upupa/dynamic-form';

@Component({
    selector: 'forms-filter',
    templateUrl: './forms-filter.component.html',
    styleUrls: ['./forms-filter.component.scss'],
    standalone: true,
})
export class FormsFilterComponent extends InputBaseComponent {


    @Output() filterApplied = new EventEmitter<any>();
    fields: FormScheme = {
        search: {
            name: 'search',
            type: 'field',
            input: 'filter-text',
            ui: {
                inputs: { label: 'بحث' }
            }
        } as Field,
        // rating:
        // priceMin:
        // priceMax:
        // mode:
    }



    search: string;
    rating: number;
    priceMin: number;
    priceMax: number;
    mode: string;

    ratingOptions: number[] = [1, 2, 3, 4, 5];
    modeOptions: string[] = ['شركة،مجموعة', 'أفراد'];


    applyFilter() {

        this.filterApplied.emit(this.value)
        // const filter = {
        //     search: this.search,
        //     rating: this.rating,
        //     priceMin: this.priceMin,
        //     priceMax: this.priceMax,
        //     mode: this.mode
        // };

        // this.filterApplied.emit(filter);
    }

    constructor(private readonly formService: DynamicFormService) {
        super()
        // this._value = { search: '' }
        // formService.addControlType('filter-text', FilterTextInputComponent, 'material')
    }


}
