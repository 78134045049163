import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";

@Component({
    selector: "account-layout",
    templateUrl: "./account-layout.component.html",
    styleUrls: ["./account-layout.component.scss"],
    standalone: true,
    imports: [RouterOutlet],
})
export default class AccountLayoutComponent {}
