<div class="page">
    <img class="cover" src="assets/rise-index-report/images/bg-2.png" [alt]="groupName" />
    <div class="wrapper">
        <h2 class="blue-text">{{ groupName }}</h2>
        <p>الجدارات التخصصية في ريادة الأعمال الاجتماعية والبيئية هي المعارف والقدرات التي تحتاجها للنجاح في تصميم وتأسيس وتشغيل مشروعك الريادي.</p>
        <p>نركز في الجدارات التخصصية على أهم خمس جدارات:</p>
        <ul>
            @for (skill of skills(); track $index) {
                <li>{{ skill.name }}</li>
            }
        </ul>
        <grade-viewer [pharse_1]="'بلغت قيمة مؤشر جداراتك التخصصية:'" [grade]="total()"></grade-viewer>
        @if (total() < 50) {
            <p>أنك تحتاج إلى التطوير النوعي والمركز لجداراتك التخصصية؛ لتحويل فكرتك إلى مشروع ريادي حيوي.</p>
        } @else {
            <p>أنك متطوّر في جداراتك التخصصية، وتحتاج إلى خطة للحفاظ عليها، ومواصلة توظيفها بذكاء لضمان الابتكار المستمر والمتجدد لمشروعك.</p>
        }
        <p>وسنأتي على تفصيل نتيجة كل جدارة، وخطوات تطويرك لهذه الجدارة انطلاقاً من نتائج تقييمك.</p>
    </div>
</div>

@for (skill of skills(); track i; let i = $index) {
    <ng-container *ngTemplateOutlet="skill.template(); context: { variable: skill.variable, score: report()[skill.variable] }"></ng-container>
}

<ng-template #se_engineering let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">الهندسة البيئية والاجتماعية</h2>
            <p>جدارة الهندسة البيئية والاجتماعية هي قدرتك على فهم تحديات المجتمع والبيئة وتحويلها إلى فرص استثمارية ومبادرات مجتمعية.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة الهندسة البيئية والاجتماعية'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>ابدأ بمتابعة تحديات وقضايا المجتمع والبيئة بانتظام؛ لتكون على دراية بأهم الاحتياجات.</li>

                    <li>تدرب على تحليل احتياجات الناس؛ من خلال الاستماع إلى مشكلاتهم ومحاولة فهم أعمق لتطلعاتهم.</li>

                    <li>ابحث عن دورات أو ورش عمل تُنمّي مهاراتك في التفكير الإبداعي وحل المشكلات لتتمكن من تحويل التحديات إلى فرص.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>واصل متابعة القضايا المجتمعية والبيئية؛ لتكون في طليعة من يسهمون في إيجاد الحلول.</li>

                    <li>استثمر قدرتك على فهم احتياجات الناس؛ لتطوير مشاريع مبتكرة تُحدث فرقاً حقيقياً في المجتمع.</li>

                    <li>انقل معارفك ومهاراتك إلى من حولك من الفريق، قدّم لهم جلسات توعية ونقاش.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>
<ng-template #model_design let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg-2.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">تصميم نموذج العمل الاجتماعي والبيئي</h2>
            <p>جدارة تصميم نموذج العمل هي قدرتك على تحويل فكرة مشروعك إلى عمل تجاري مستدام.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة تصميم نموذج العمل'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>ابدأ بتطوير مهارتك في تحويل الأفكار إلى مشاريع؛ عبر دراسة نماذج العمل المبتكرة، وتحليل كيفية بنائها.</li>

                    <li>تعلم خطوات إدارة المشاريع التجارية، كالتخطيط والتنظيم والتوجيه والمتابعة، لتكون قادراً على إنجاح مشروعك.</li>

                    <li>احرص على تعلم أساسيات دراسات الجدوى، وإعداد الموازنات المالية؛ لتحسين قدرتك على تقييم الجدوى المالية لمشروعك.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>ساعد رواد الأعمال الطموحين على تطوير نماذج أعمالهم من خلال تقديم استشارات أو ورش عمل.</li>

                    <li>واصل التعلم عن أحدث الاتجاهات والمفاهيم في مجال تصميم نماذج الأعمال لتحسين معرفتك وتطوير أدواتك.</li>

                    <li>اعمل على تطوير شبكة علاقات مهنية مع رواد الأعمال والمستثمرين لتبادل التجارب والأفكار والممارسات في التصميم الابتكاري لنماذج الأعمال.​​​​​​​​​​​​​​​​</li>
                </ol>
            }
        </div>
    </div>
</ng-template>
<ng-template #fin_sustainability let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">الاستدامة المالية للمشاريع الاجتماعية</h2>
            <p>جدارة الاستدامة المالية هي قدرتك على توليد عائدات مستدامة من مشروعك، من مصادر ربحية واجتماعية مناسبة.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة الاستدامة المالية'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>ابدأ بتعلم كيفية استخدام المنصات التقنية الحكومية والمجتمعية؛ للحصول على الدعم والتمويل لمشروعك.</li>

                    <li>طوّر مهاراتك في التشبيك مع الداعمين والمستثمرين؛ من خلال حضور الفعاليات، وبناء علاقات مهنية.</li>

                    <li>احرص على إتقان إعداد العروض الفنية والمالية التي تتوافق مع أنظمة المشتريات والمنافسات؛ لتقديم مشروعك بشكل احترافي.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>واصل استغلال المنصات التقنية الحكومية والمجتمعية لتعزيز موارد مشروعك، والحصول على فرص تمويلية جديدة.</li>

                    <li>استثمر علاقاتك مع الداعمين والمستثمرين؛ لتوسيع شبكتك، وزيادة فرص الاستدامة المالية لمشروعك.</li>

                    <li>شارك خبراتك ومعرفتك في الاستدامة المالية من خلال كتابة مقالات أو تقديم ورش عمل أو محاضرات.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>
<ng-template #commercial_int let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg-2.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">التسويق الاجتماعي</h2>
            <p>جدارة التسويق الاجتماعي هي قدرتك على فهم سوق العمل وتسويق وبيع خدمات ومنتجات مشروعك الريادي.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة التسويق الاجتماعي'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>ابدأ بتعلم كيفية استخدام أدوات التسويق الرقمي، مثل وسائل التواصل الاجتماعي والبريد الإلكتروني، لزيادة وصول مشروعك إلى الجمهور.</li>

                    <li>طوّر مهاراتك في إقناع الزبائن بمنتجاتك وخدماتك؛ عبر التدرّب على استراتيجيات البيع والتواصل الفعّال.</li>

                    <li>تعلم مبادئ التسعير الأساسية؛ لضمان وضع أسعار مناسبة تعكس قيمة منتجاتك، وتناسب احتياجات السوق.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>استمر في تعزيز استفادتك من أدوات التسويق الرقمي؛ لتحسين استراتيجيات الوصول وزيادة التأثير.</li>

                    <li>قدم استشارات تسويقية لرواد الأعمال أو الشركات الناشئة التي تحتاج إلى دعم في مجال التسويق الاجتماعي.</li>

                    <li>راجع باستمرار استراتيجيات التسويق والتسعير لديك؛ لتحقيق التوازن بين القيمة المقدمة والأرباح المستدامة.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>
<ng-template #impact let-variable="variable" let-score="score">
    <div class="page">
        <img class="cover" src="assets/rise-index-report/images/bg.png" [alt]="variable" />
        <div class="wrapper">
            <h2 class="blue-text">إدارة الأثر والجدوى الاجتماعية</h2>
            <p>جدارة إدارة الأثر والجدوى الاجتماعية هي قدرتك على وضع مؤشرات اجتماعية وبيئية لمشروعك، وقياسها، والاستفادة من نتائج قياسها.</p>
            <grade-viewer [pharse_1]="'ووفقاً لإجاباتك على أسئلة هذه الجدارة، فأنت تمتلك'" [grade]="score" [pharse_2]="'من جدارة إدارة الأثر والجدوى الاجتماعية'"></grade-viewer>
            @if (score < 50) {
                <p>تحتاج إلى تطوير هذه الجدارة لديك، ولذا نقترح ما يلي:</p>
                <ol>
                    <li>ابدأ بتعلم أساسيات التخطيط لقياس الأثر؛ من خلال تحديد المؤشرات المناسبة لتقييم الجدوى الاجتماعية والبيئية.</li>

                    <li>درّب نفسك على استخدام أدوات التقييم؛ لقياس أثر المشاريع، وفهم كيفية تأثيرها على المجتمع والبيئة.</li>

                    <li>اعمل على تطوير مهاراتك في تحليل البيانات الاجتماعية والبيئية؛ لتحديد مدى فعالية مشاريعك.</li>
                </ol>
            } @else {
                <p>أنت تمتلك هذه الجدارة، وللحفاظ عليها، والاستمرار في تطويرها، وتحقيق الاستفادة القصوى منها؛ نقترح ما يلي:</p>
                <ol>
                    <li>استخدم نتائج قياس الأثر الاجتماعي لتطوير منتجات وخدمات جديدة في مشروعك تلبي احتياجات المجتمع التي اكتشفتها من خلال القياس.</li>

                    <li>وثّق نجاحاتك في إدارة الأثر واستخدمها كأداة لجذب المزيد من الداعمين والمستثمرين إلى مشروعك.</li>

                    <li>استخدم نتائج قياس الأثر لتطوير استراتيجية مشروعك وتحقيق أهدافك الاجتماعية والبيئية بشكل فعال.</li>
                </ol>
            }
        </div>
    </div>
</ng-template>
