<div *ngIf="data" class="review-card">
    <div class="review-card-header">
        <div class="avatar">
            <img [src]="data.image" alt="{{data.title}}">
        </div>
        <div class="desc">
            <h4 class="title">{{data.title}}</h4>
            <p class="subtitle">{{data.subtitle}}</p>
            <div *ngIf="data.rating" class="rating">
                <rating-stars [rating]="data.rating"></rating-stars>
            </div>
        </div>
    </div>
    <div *ngIf="data.body" class="review-card-body">
        <p>
            {{data.body}}
        </p>
    </div>
</div>
