import { CommonModule } from '@angular/common';
import { Component, computed, input, viewChild, ViewEncapsulation } from '@angular/core';
import { GradeViewerComponent } from '../../components/grade-viewer/grade-viewer.component';
@Component({
    selector: 'professional-skills-report',
    templateUrl: './professional-skills-report.component.html',
    styleUrls: ['../../rise-index-report.component.scss'],
    standalone: true,
    imports: [CommonModule, GradeViewerComponent],
    encapsulation: ViewEncapsulation.None,
})
export class ProfessionalSkillsReportComponent {
    report = input.required<any>();
    total = computed(() => this.report()['professional_skills']);
    groupName = 'الجدارات المهارية المهنية';

    safety = viewChild('safety');
    planning_and_organizing = viewChild('planning_and_organizing');
    caoching = viewChild('caoching');
    change_management = viewChild('change_management');
    team_working = viewChild('team_working');
    organizational_culture = viewChild('organizational_culture');
    professional_communication = viewChild('professional_communication');
    business_technology = viewChild('business_technology');
    remote_working = viewChild('remote_working');

    skills = input.required({
        transform: (v: { name: string; variable: string }[]) => {
            return v.map((skill) => {
                return { name: skill.name, variable: skill.variable, template: this[skill.variable] };
            });
        },
    });
}
