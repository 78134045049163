import { Component, inject, OnInit, PLATFORM_ID, signal, ViewEncapsulation } from "@angular/core";
import { map } from "rxjs/operators";
import { firstValueFrom } from "rxjs";

import { ActivatedRoute } from "@angular/router";
import { TypeFormResponseInfo } from "../typeform-response-info";

import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

import { CommonModule, isPlatformBrowser } from "@angular/common";
import { EmbedComponent } from "../embed/embed.component";

@Component({
    selector: "answer-page",
    templateUrl: "./answer-page.component.html",
    styleUrls: ["./answer-page.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule, EmbedComponent],
})
export class AnswerPageComponent implements OnInit {
    private readonly http = inject(HttpClient);
    private readonly route = inject(ActivatedRoute);

    private readonly platformId = inject(PLATFORM_ID);
    subscriptionId = signal<string>(null);
    formId = signal<string>(null);
    async ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            const subscriptionId = this.route.snapshot.params["subscription"];

            const formId = await firstValueFrom(
                this.http
                    .post<{ formId: string; form: any }>(`${environment.base_url}/tfr/answer`, {
                        subscriptionId,
                    })
                    .pipe(map((res) => res.formId)),
            );
            
            this.subscriptionId.set(subscriptionId);
            this.formId.set(formId);
        }
    }

    async onNewResponseSubmit(responseInfo: TypeFormResponseInfo) {
        try {
            const fetchResults = await firstValueFrom(
                this.http.post<{
                    emailSent: boolean;
                    error?: string;
                    formName: string;
                    userInfo: { email: string; _id: string; name: string };
                }>(`${environment.base_url}/tfr/new-response`, {
                    responseId: responseInfo.responseId,
                    subscriptionId: responseInfo.subscriptionId,
                }),
            );
            if (fetchResults.emailSent === true) {
                // this.dialog
                //     .openDialog(InviteToSignupComponent, {
                //         closeOnNavigation: false,
                //         direction: languageDir(this.languageService.language),
                //         disableClose: true,
                //         hideCloseBtn: false,
                //         title: "إجابة مكتملة",
                //         inputs: { userInfo: res.userInfo, formName: res.formName },
                //     })
                //     .afterClosed()
                //     .subscribe((c) => {
                //         if (fetchResults.subscription.redirectUrl)
                //             document.location.href = fetchResults.subscription.redirectUrl //"https://www.ask-robots.com/npos";
                //     });
            }
        } catch (error) {
            console.log("error", error);
        }
    }
}
