<ng-container *ngIf="form$ | async as form">
    <top-header
        [breadcrumbs]="breadcrumb$ | async"
        (activeBreadcrumbChange)="navigateToBreadcrumbItem($event)"
        [title]="form.title"
        [description]="form.settings.meta.description"
        [rating]="5"
        [subscribers]="44"
    >
    </top-header>
    <section class="container mbs-1 mbe-1">
        <aside>
            <div class="sidebar">
                <figure class="image">
                    <img [src]="form.settings.meta.image.href" alt="{{ form.title }}" />
                </figure>
                <div class="price">
                    <h4 class="new-price">$74.99</h4>
                    <span class="prev-price">$81.00</span>
                </div>

                <button class="subscribe-btn">اشترك الآن</button>

                <ul class="list">
                    <li class="list-item">
                        <span>
                            <mat-icon>schedule</mat-icon>
                            <span>الوقت المتوقع</span>
                        </span>
                        <span>{{ form.average_completion_time || '10-25' }} د</span>
                    </li>
                    <li class="list-item">
                        <span>
                            <mat-icon>person</mat-icon>
                            <span>مجموع الاشتراكات</span>
                        </span>
                        <span>44</span>
                    </li>

                    <li class="list-item">
                        <span>
                            <mat-icon>category</mat-icon>
                            <span>التصنيف</span>
                        </span>
                        <span>مقياس للجمعيات</span>
                    </li>
                    <li style="flex-direction: column" class="list-item"></li>
                </ul>

                <div class="reports-template mbs-2 mbe-2">
                    <h2 class="sub-title">نماذج التقارير</h2>
                    <ul class="list">
                        <ng-container *ngFor="let report of form.reports">
                            <li class="list-item">
                                <a href="#">
                                    <mat-icon class="pdf-icon">picture_as_pdf</mat-icon>
                                    <mat-icon class="download-icon">download</mat-icon>
                                    {{ report.title }}
                                </a>

                                <span *ngIf="report.price === 0; else reportPriceTemplate">مجاني</span>
                                <ng-template #reportPriceTemplate>{{ report.price | currency }}</ng-template>
                            </li>
                        </ng-container>
                    </ul>
                </div>

                <review-card *ngIf="author$ | async as author" [data]="author"></review-card>
            </div>
        </aside>
        <main>
            <div class="about">
                <h4 class="sub-title">عن الروبوت</h4>
                <div class="mbs-1 mbe-1">
                    <p class="normal-fs">
                        نود أن نقدم لكم تقييمًا إلكترونيًا جديدًا يحمل اسم "ماذا أقرأ؟"، هو تقييم يطرح على الناس مجموعة من الأسئلة، ثم يصنفهم ويقترح عليهم قائمة بالمواضيع والكتب
                        المحتملة لقراءتها. يهدف هذا التقييم إلى مساعدتكم في اكتشاف ما الذي يثير اهتمامكم وما يناسبكم من القراءة. سيساعدكم هذا التطبيق المبتكر في توجيه اختياراتكم
                        القرائية وتوفير قائمة شخصية تضم أعمالًا أدبية ومواضيع متنوعة تتناسب مع ذوقكم واهتماماتكم. ستتمتعون بتجربة قراءة مثيرة ومفيدة مع هذا التقييم الذي يستند إلى
                        تقنيات حديثة لتحليل وتصنيف الأفكار والاهتمامات الشخصية. ابدأوا رحلة الاكتشاف القرائي مع "ماذا أقرأ؟" الآن واستعدوا لاكتشاف عالم الكتب والمعرفة الذي يناسب
                        ذوقكم الفريد.
                    </p>
                </div>
            </div>
            <mat-accordion id="robot-subs" multi>
                <mat-expansion-panel expanded="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4 class="sub-title">كيف يعمل</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p class="normal-fs">
                        عمل روبوت "ماذا أقرأ؟" بسيطة وفعالة. عند بدء التقييم، ستُطلب منكم الإجابة على سلسلة من الأسئلة المتنوعة حول اهتماماتكم الشخصية وتفضيلاتكم القرائية. ستتعلق
                        هذه الأسئلة بمختلف المواضيع مثل الأدب، والتاريخ، والعلوم، والفلسفة، وغيرها. ستكون الأسئلة مصممة بشكل استراتيجي للكشف عن تفاصيل هامة تتعلق بشخصيتكم
                        واهتماماتكم. بعد الانتهاء من الإجابة على الأسئلة، سيقوم النظام بتحليل إجاباتكم بشكل شامل ودقيق. باستخدام خوارزميات التصنيف المتقدمة، سيتم تحديد اهتماماتكم
                        وميولكم القرائية. ثم، سيقوم التقييم بمقارنة تلك النتائج مع قاعدة بيانات ضخمة تحتوي على توصيات واقتراحات متنوعة للكتب والمواضيع المناسبة. باستناد إلى التحليل
                        والمقارنة، ستحصلون على قائمة مخصصة وشخصية تتضمن مجموعة متنوعة من الكتب والمواضيع التي تناسب ذوقكم الفريد واهتماماتكم الخاصة. ستتمكنون من استكشاف هذه القائمة
                        واكتشاف محتوى جديد ومثير للاهتمام، مما سيمنحكم تجربة قراءة ممتعة ومفيدة. بفضل "ماذا أقرأ؟"، ستجدون الكتب المثالية التي تناسب طموحاتكم واحتياجاتكم القرائية.
                        ابدؤوا الآن واستعدوا لاستكشاف عالم القراءة والمعرفة بطريقة مبتكرة وشخصية.
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4 class="sub-title">البحث العلمي</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p class="normal-fs">
                        روبوت "ماذا أقرأ؟" يستند إلى عملية علمية شاملة قام بها باحثون متخصصون في مجالات متعددة. قام الفريق بدراسة عميقة لنماذج سلوك القراءة وعادات القراءة للأفراد.
                        بدءًا من تحليل البيانات الضخمة واستخلاص النماذج والأنماط القرائية، ثم انتقلوا إلى تطوير خوارزميات متقدمة لتصنيف الأفراد واهتماماتهم القرائية. تم استخدام
                        أساليب إحصائية وتحليلية دقيقة لتحليل البيانات واستنتاج الارتباطات والترابطات بين الإجابات والتوصيات المحتملة. تم تنفيذ عملية التحليل بدقة عالية للوصول إلى
                        نتائج دقيقة وموثوقة. تم توظيف المهارات العلمية والتقنية المتقدمة لتصميم هذا التقييم الإلكتروني الذي يقدم نتائج مبنية على أسس قوية وأدلة محددة. باستخدام هذه
                        الطرق والتقنيات العلمية، تم تطوير "ماذا أقرأ؟" ليكون أداة فعالة وذكية لتوفير توصيات قرائية دقيقة ومتنوعة. إن هذا التقييم يعكس الجهود البحثية العلمية العميقة
                        والتحليل المعمق لتلبية احتياجات القراء المتنوعة. تم تطويره بشكل مبتكر ليكون شريكًا موثوقًا لكل من يبحث عن اقتراحات قرائية مخصصة وفعالة استنادًا إلى المعرفة
                        العلمية والبيانات الشاملة.
                    </p>
                </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4 class="sub-title">الفئة المستهدفة</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p class="" normal-fs>
                        روبوت "ماذا أقرأ؟" متاح للجميع، بغض النظر عن العمر أو المستوى التعليمي أو الخلفية الثقافية. سواء كنتم طلابًا، أو محترفين، أو هواة قراءة، فإن هذا التقييم
                        سيكون مفيدًا لكم. إنه فرصة لاستكشاف تفضيلاتكم القرائية واكتشاف مجالات جديدة تستحق الاهتمام. سواء كنتم ترغبون في تنويع قائمة قراءاتكم المعتادة أو تحديد
                        اتجاهات جديدة للقراءة، فإن "ماذا أقرأ؟" سيكون دليلاً قيمًا لكم. استفيدوا من هذا التقييم الذكي واستمتعوا بتوصياته المخصصة التي تلبي احتياجاتكم الفريدة في
                        عالم القراءة.
                    </p>
                </mat-expansion-panel>
            </mat-accordion>
        </main>
    </section>
    <section class="container">
        <div id="similar-robots">
            <h2 class="sub-title">روبوتات مشابهة</h2>
            <div class="slider">
                <s-swiper [slides]="adapter.items$ | async" [slideTemplate]="slideCardTempalte" [config]="similarSwiperConfig"></s-swiper>

                <ng-template #slideCardTempalte let-slide="slide">
                    <div class="custom-card" style="cursor: pointer">
                        <div class="card-image">
                            <img [src]="slide.image[0].path" alt="{{ slide.title }}" />
                        </div>
                        <div class="card-body">
                            <h4 class="subtitle">تعليم</h4>
                            <h1 class="card-title">{{ slide.title }}</h1>
                            <p class="card-desc">{{ slide.desc }}</p>
                        </div>
                        <div class="footer flex space-between">
                            <span class="price"><mat-icon>attach_money</mat-icon> Free</span>
                            <span class="pub-date"><mat-icon>schedule</mat-icon> {{ slide.average_completion_time || '10-25' }} </span>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
        <div id="reviews" class="mbs-2">
            <h2 class="title">المراجعات</h2>
            <div id="progresses" class="mbs-2">
                <div id="total">
                    <h2 class="title">التقييم العام</h2>
                    <rating-stars [rating]="4"></rating-stars>
                </div>
                <div id="details">
                    <div *ngFor="let i of [1, 2, 3, 4, 5]" class="p-item mbe-1">
                        <span>{{ i }} نجمة</span>
                        <mat-progress-bar mode="determinate" #progress value="{{ 20 * i }}"></mat-progress-bar>
                        <span>{{ progress.value }} %</span>
                    </div>
                </div>
            </div>
            <div id="reviews" class="mbs-2">
                <div class="slider">
                    <review-card [data]="review"></review-card>
                </div>
            </div>
        </div>
    </section>
</ng-container>
