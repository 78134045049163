<div class="swiper">
  <!-- Additional required wrapper -->
  <div class="swiper-wrapper">
    <div class="swiper-slide" *ngFor="let slide of _slides$ | async">
        <ng-container *ngTemplateOutlet="slideTemplate; context: { slide }"></ng-container>
    </div>
  </div>
  
  <ng-container *ngIf="config">
    <!-- If we need pagination -->
    <div *ngIf="config.pagination" class="swiper-pagination"></div>

    <!-- If we need navigation buttons -->
    <ng-container *ngIf="config.navigation">
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </ng-container>
    

    <!-- If we need scrollbar -->
    <div *ngIf="config.scrollbar" class="swiper-scrollbar"></div>
  </ng-container>
</div>