import { AfterViewInit, Component, input, Input, OnDestroy, viewChild, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { animate, keyframes, query, stagger, style, transition, trigger } from "@angular/animations";
import { IDataListAdapter } from "../../list-page-adapter";
import { ListItemCardComponent } from "../list-item-card/list-item-card.component";
import { SdlItemDirective } from "../../sdl-list-item.directive";
import { CommonModule } from "@angular/common";

@Component({
    selector: "sdl-cards-grid",
    templateUrl: "./cards-grid.component.html",
    styleUrls: ["./cards-grid.component.scss"],
    standalone: true,
    imports: [ListItemCardComponent, SdlItemDirective, CommonModule],
    animations: [
        trigger("listAnimation", [
            transition("* => *", [
                query(":enter", style({ opacity: 0 }), { optional: true }),

                query(
                    ":enter",
                    stagger("75ms", [
                        animate(
                            "150ms ease-in",
                            keyframes([
                                style({ opacity: 0, transform: "translateY(16px)", offset: 0 }),
                                style({ opacity: 0.85, transform: "translateY(8px)", offset: 0.3 }),
                                style({ opacity: 1, transform: "translateY(0)", offset: 1 }),
                            ]),
                        ),
                    ]),
                    { optional: true },
                ),
            ]),
        ]),
    ],
})
export class CardsGridComponent implements OnDestroy {
    private readonly destroyed$ = new Subject<void>();

    dir: "col" | "row" = "col";

    defaultCardTemplate = viewChild("defaultCardTemplate");

    cardTemplate = input(this.defaultCardTemplate(), {
        transform: (v: any) => v ?? this.defaultCardTemplate(),
    });

    private _adapter!: IDataListAdapter;
    @Input()
    public get adapter(): IDataListAdapter {
        return this._adapter;
    }
    public set adapter(value: IDataListAdapter) {
        if (!value) throw new Error(`${CardsGridComponent.name} requires adapter:IDataListAdapter`);
        this._adapter = value;
        // console.log(CardsGridComponent.name, 'Adapter Changed');
    }

    private _query!: any;
    @Input()
    public get query(): any {
        return this._query;
    }
    public set query(value: any) {
        this._query = value;
        // console.log(CardsGridComponent.name, 'Query Changed');
        this.adapter?.fetch(this.query);
    }

    // ngAfterViewInit(): void {
    //     this.cardTemplate ??= this.defaultCardTemplate;
    // }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}

/*
    PAGE:
        - Layout -> PROJECT
        - Depends on router

    LIST -> Library Component
        adapter is just an input like all other options and configs
*/
