import { Component, EventEmitter, input, Input, output, Output, ViewEncapsulation } from "@angular/core";
import { CardModel, CardModelButtonDescriptor } from "../../../card-item.model";
import { MatCardModule } from "@angular/material/card";
import { CommonModule } from "@angular/common";
import { SdlItemActionDirective } from "../../sdl-list-item.directive";

// install Swiper modules
// SwiperCore.use([EffectCoverflow, Pagination]);

export type ListItemActionEvent = {
    action: CardModelButtonDescriptor;
    e: Event;
};
@Component({
    selector: "sdl-card",
    templateUrl: "./list-item-card.component.html",
    styleUrls: ["./list-item-card.component.scss"],
    host: { class: "card" },
    standalone: true,
    imports: [MatCardModule, CommonModule, SdlItemActionDirective],
})
export class ListItemCardComponent {
    item = input.required<CardModel>();
    imagePlaceholder = input<string>();
    cardClicked = output();
    onAction = output<ListItemActionEvent>();

    imageError(e: any) {
        e.target.onError = null;
        if (this.imagePlaceholder) e.target.src = this.imagePlaceholder;
    }

    async handleActionClick(action: CardModelButtonDescriptor, e: Event) {
        if (!action.handler) return console.warn(`Action ${action.text} has no assigned handler`);

        await action.handler(action, e);
    }
}
