* {
    box-sizing: border-box;
}

.page {
    display: block;
    position: relative;
    text-align: justify;
    font-size: 14pt;
    line-height: 1.5;
    width: 210mm;
    height: 297mm;
    // FOR TESTING
    border: 1px solid #ddd;
    margin-block: 5mm;
    margin-inline: auto;
    page-break-inside: avoid;

    h1,
    h2 {
        font-weight: 600;
    }

    // h1,
    // h2,
    // h3,
    // h4,
    // h5,
    // h6 {
    //     // margin-block-start: 3mm;
    // }

    &>img.cover:first-of-type,
    &>img.back-cover:first-of-type,
    &>.wrapper:first-of-type {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: 0;
        padding: 0;
    }

    &>img.cover:first-of-type,
    &>img.back-cover:first-of-type {
        object-fit: cover;
        object-position: center;
        z-index: 1;
        page-break-before: always;
    }

    &>.wrapper:first-of-type {
        z-index: 2;
        background-color: transparent;
        margin: 25mm 10mm;
        width: calc(100% - 20mm);
        height: calc(100% - 50mm);

        p {
            margin-block-end: 3mm;
        }

    }
}

.text-center {
    text-align: center;
}

.flex-center {
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.index {
    .row {
        display: table;
    }

    .title,
    .dots,
    .value {
        display: table-cell;
    }

    .title,
    .value {
        white-space: nowrap;
    }

    .dots {
        border-bottom: 1px dotted gray;
        width: 100%;
        position: relative;
        top: -10px;
    }
}



@media print {

    @page {
        size: A4;
        margin: 0;
        padding: 0;
    }

    .page {
        border: none !important;
        margin: 0 !important;
        padding: 0 !important;
    }

}