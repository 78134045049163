<ng-container *ngIf="report">
    <div class="page">
        <img class="cover" src="assets/reading-report/images/cover.jpg" alt="intro-image" />
    </div>

    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="respondant" />
        <div class="wrapper flex-center">

            <div class="text-center">
                <p class="orange-text">محتوى تقرير:</p>
                <h2>نتائج وتوصيات روبوت ماذا أقرأ</h2>
                <p>تم إعداده لصالح:</p>
                <h2 class="green-text">{{ reportViewModel.user.name }}</h2>
                <p>تقديم:</p>
                <h2 class="orange-text" style="margin-block: 2rem;">نادي أصدقاء القراءة</h2>
                <p>{{ reportViewModel.submitted_at | date : "yyyy MMM dd" }}</p>
            </div>

        </div>
    </div>


    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="about" />
        <div class="wrapper">
            <p class="text-center">بسم الله الرحمن الرحيم</p>
            <h2 class="green-text">قبل البدء</h2>
            <p>
                يشتمل هذا التقرير على المجالات التي ننصحك بقراءتها بناء على نتائج تفاعلك
                مع الروبوت. أعد المحتوى العلمي لهذا التقرير مشروع أصدقاء القراءة، وقدمت
                خدمة البيانات والذكاء الاصطناعي شركة Human Centered Data LLC.
            </p>
            <div class="logo">
                <img src="assets/reading-report/images/hcd-logo.png" alt="human centered data" />
            </div>
            <h2 class="green-text">حول مشروع أصدقاء القراءة:</h2>
            <p>
                مشروع تطوعي يتّخذ لغة العصر (الإنترنت) وسيلة لتنمية ثقافة القراءة في
                العالم العربي، من خلال تشجيع القراءة بالحديث عن الكتب، عن المؤلفين،
                والقيام بمسابقات دورية ثقافية مختلفة وبقراءات جماعية في مجالات متعددة،
                ومناقشتها، يقوم على إدارة المشروع فريق قرّاء من أنحاء الوطن العربي أجمع.
            </p>
            <div class="logo">
                <img src="assets/reading-report/images/reading-friends-logo.png" alt="reading friends" />
            </div>
        </div>
    </div>
    <ng-container [ngSwitch]="report.type">
        <ng-container *ngSwitchCase="'theoreticalWorks'">
            <ng-container *ngTemplateOutlet="theoreticalWorksSection; context: { report }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'theoreticalDoNotWork'">
            <ng-container *ngTemplateOutlet="theoreticalDoNotWorkSection; context: { report }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'practicalWorks'">
            <ng-container *ngTemplateOutlet="practicalWorksSection; context: { report }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'practicalDoNotWork'">
            <ng-container *ngTemplateOutlet="practicalDoNotWorkSection; context: { report }"></ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault></ng-container>
    </ng-container>

    <div class="page">
        <img class="back-cover" src="assets/reading-report/images/back-cover.jpg" alt="back-cover" />
    </div>
</ng-container>

<!-- SECTIONS -->
<ng-template #theoreticalWorksSection let-section="section">
    <div class="page">
        <img class="cover" src="assets/reading-report/images/2.jpg" alt="section-image" />
    </div>
    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="green-text">ما هو المجال الذي يناسبني في القراءة؟</h2>
            <p>
                بناء على نمطك في القراءة وما تهدف إليه والمستقبل المقبل عليه، فأنت
                تناسبك:
            </p>
            <h4>الكتب الفكرية والنظرية</h4>
            <p>
                وهي كتبٌ تكتفي بالسرد أو الوصف، وتعملنا كيف نفكر ونحاكم الأمور، وتميل في
                بعض الأوقات إلى العاطفة أو الخيال.
            </p>
            <p>
                وكون العمل يعتبر هاماً لديك، فإننا نقدم لك نصيحة حول تركيز قراءاتك في
                المجالات الآتية لتقرأ فيها:
            </p>

            <p>
                <strong>أولاً- الاقتصاد: </strong>ليس بالضرورة أن يكون تخصصك هو الاقتصاد
                حتى تتركز قراءتك على هذا المجال. مجالات القراءة يمكن أن تكون في المستوى
                التوعوي الثقافي، مثل الأنظمة الاقتصادية، والمؤشرات والتضخم، وغيرها.
            </p>
            <p>
                <strong>ثانياً- علوم الأحياء: </strong>من المفيد والمناسب لنمطك أن تقرأ
                عن علم الأحياء والطبيعة، بحيث تقتصر القراءة على المستوى الثقافي التوعوي،
                بغض النظر عن تخصصك العلمي. 
            </p>
            <p>
                <strong>ثالثاً- الرياضيات: </strong>قد تكون كتب الرياضيات والألغاز
                العلمية من الأمور التي ستجذبك وتتناسب مع نمط شخصيتك. 
            </p>
        </div>
    </div>

    <div class="page">
        <img class="cover" src="assets/reading-report/images/3.jpg" alt="section-image" />
    </div>

    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="green-text">ماذا أقرأ من كتب؟</h2>
            <p>
                فيما يلي مجموعة مقترحة من الكتب من أصدقاء القراءة التي ننصحك بقراءتها
                وإضافتها إلى مكتبتك. وفي نهاية الأمر، فقرار اختيار ما تقرؤه يعود إليك،
                فالقراءة هي وسيلة لتحقيق أهدافك في الحياة كما هي منطقة للمتعة والراحة
                لك.
            </p>

            <h4 class="orange-text">كتب التطوير والتنمية الذاتية:</h4>
            <ul>
                <li>جدد حياتك، الغزالي.</li>
                <li>العادات السبع للناس الأكثر فعالية.</li>
                <li>كيف تؤثر على الآخرين وتكسب الأصدقاء.</li>
                <li>فن التفكير الإبداعي.</li>
                <li>العادات الذرية.</li>
                <li>لا تهتم بصغائر الأمور.</li>
                <li>الأب الغني والأب الفقير.</li>
                <li>خراب.</li>
                <li>ملاحظات حول كوكب متوتر.</li>
                <li>ولدت لتفوز.</li>
                <li>انهض.</li>
                <li>متعة أن تكون في الثلاثين.</li>
                <li>غدًا أجمل.</li>
                <li>فن اللامبالاة.</li>
                <li>استراحة نفسية.</li>
                <li>قوة عقلك الباطن.</li>
            </ul>
        </div>
    </div>

    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h4 class="orange-text">كتب في السيرة الذاتية والمذكرات:  </h4>
            <ul>
                <li>قصة حياة، المازني.</li>
                <li>رحلتي الفكرية، المسيري.</li>
                <li>حصاد السنين، زكي محمود.</li>
                <li>ذكريات، الطنطاوي.</li>
                <li>سبعون، ميخائيل نعيمه.</li>
                <li>مذكرات الأرقش.</li>
                <li>رحيق العمر، جلال أمين.</li>
                <li>قصة حياة، الغزالي.</li>
                <li>ذكرى عهود، الزيات.</li>
                <li>الأيام، طه حسين.</li>
                <li>أنا، العقاد.</li>
                <li>غبار السنين، فروخ.</li>
                <li>مذكرات الأرقش.</li>
            </ul>
            <h4 class="orange-text">كتب أدبية: </h4>
            <ul>
                <li>وحي القلم، الرافعي.</li>
                <li>الإمتاع والمؤانسة، التوحيدي.</li>
                <li>صور وخواطر، الطنطاوي.</li>
                <li>النظرات، المنفلوطي.</li>
                <li>البخلاء، الجاحظ.</li>
                <li>البصائر والذخائر، التوحيدي.</li>
                <li>ساعات بين الكتب، العقاد.</li>
                <li>من رسائل العقاد، محمد حمدان.</li>
                <li>المساكين، الرافعي.</li>
                <li>قصة عقل، زكي محمود.</li>
                <li>قصة نفس، زكي محمود.  </li>
            </ul>
        </div>
    </div>

    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h4 class="orange-text">كتب السيرة النبوية: </h4>
            <ul>
                <li>الرحيق المختوم، المباركفوري.</li>
                <li>نور اليقين في سيرة سيد المرسلين، الخضري.</li>
                <li>فقه السيرة، الغزالي.</li>
                <li>مصادر السيرة النبوية وتقويمها، فاروق حمادة.</li>
                <li>تحفة النبلاء من قصص الأنبياء، ابن كثير.</li>
                <li>صحيح السيرة النبوية، الألباني.</li>
                <li>بهجة قلوب الأبرار، السعدي.</li>
            </ul>

            <h4 class="orange-text">كتب عن القراءة والكتابة:</h4>
            <ul>
                <li>هكذا تكلم القارئ.</li>
                <li>في صحبة الكتب.</li>
                <li>فن القراءة.</li>
                <li>كيف تقرأ كتابًا.</li>
                <li>يوميات القراءة</li>
                <li>مكتبة ساحة الأعشاب.</li>
                <li>دروب القراءة.</li>
                <li>رحلات في حجرة الكتابة.</li>
                <li>مهنة القراءة.</li>
                <li>يوميات بائع كتب.</li>
                <li>كيف نقرأ الأدب.</li>
                <li>متعة القراءة.</li>
            </ul>
        </div>
    </div>
</ng-template>

<ng-template #theoreticalDoNotWorkSection let-section="section">
    <div class="page">
        <img class="cover" src="assets/reading-report/images/2.jpg" alt="intro-image" />
    </div>
    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="green-text">ما هو المجال الذي يناسبني في القراءة؟</h2>
            <p>
                بناء على نمطك في القراءة وما تهدف إليه والمستقبل المقبل عليه، فأنت
                تناسبك:
            </p>
            <h4>الكتب الفكرية والنظرية</h4>
            <p>
                وهي كتبٌ تكتفي بالسرد أو الوصف، وتعملنا كيف نفكر ونحاكم الأمور، وتميل في
                بعض الأوقات إلى العاطفة أو الخيال.
            </p>
            <p>
                وكون العمل لا يعتبر هاماً لديك، فإننا نقدم لك نصيحة حول تركيز قراءاتك في
                المجالات الآتية لتقرأ فيها:
            </p>

            <p>
                <strong>أولاً- كتب الأدب: </strong>ننصحك بقراءة الروايات والقصص، سواء
                العربية أو المترجمة، إضافة إلى الشعر والنثر، فهي تناسب نمط شخصيتك.
            </p>
            <p>
                <strong>ثانياً- التاريخ : </strong>ننصحك أيضاً بقراءة كتب التاريخ، مع
                الحرص أن تكون قراءة يستفاد منها، لذلك ابحث عن الكتب التاريخية التي تتضمن
                تحليلات ودروس مستفادة، وليس الكتب التأريخ المتخصصة والتي قد يكون من
                أهدافها التحقيق والتثبيت.
            </p>
            <p>
                <strong>ثالثاً- الفلسفة: </strong>إذا لم تكن متخصصاً، فمن المفيد
                والمناسب لك قراءة الكتب الفلسفية التي تتناول الأخلاقيات والقضايا
                الفكرية.
            </p>
        </div>
    </div>
    <div class="page">
        <img class="cover" src="assets/reading-report/images/3.jpg" alt="intro-image" />
    </div>

    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="green-text">ماذا أقرأ من كتب؟</h2>
            <p>
                فيما يلي مجموعة مقترحة من الكتب من أصدقاء القراءة التي ننصحك بقراءتها
                وإضافتها إلى مكتبتك. وفي نهاية الأمر، فقرار اختيار ما تقرؤه يعود إليك،
                فالقراءة هي وسيلة لتحقيق أهدافك في الحياة كما هي منطقة للمتعة والراحة
                لك.
            </p>

            <h4 class="orange-text">كتب لغوية: </h4>
            <ul>
                <li>المعجم الوسيط.</li>
                <li>معجم الأخطاء الشائعة، محمد العدناني.</li>
                <li>المعجم العربي، حسين نصار.</li>
                <li>النحو المصفى، محمد عيد.</li>
                <li>شرح الآجرومية، محمد بن عثيمين.</li>
                <li>نجعة الرائد وشرعة الوارد، اليازجي.</li>
                <li>مباحث تأسيسية في اللسانيات، المسدّي.</li>
                <li>نشأة النحو وتاريخ أشهر النحاة، محمد الطنطاوي.</li>
            </ul>

            <h4 class="orange-text">الكتب التاريخية:</h4>
            <ul>
                <li>تاريخ الخلفاء، السيرطي.</li>
                <li>حقبة من التاريخ، عثمان الخميس.</li>
                <li>التاريخ الأندلسي، عبدالرحمن الحجي.</li>
                <li>يوم الإسلام، أحمد أمين.</li>
                <li>التاريخ الإسلامي الوجيز، محمد طقوش.</li>
                <li>قصص من التاريخ، الطنطاوي.</li>
                <li>رجال من التاريخ، الطنطاوي.</li>
            </ul>
        </div>
    </div>

    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h4 class="orange-text">كتب الفلسفة:</h4>
            <ul>
                <li>قصة الفلسفة، ول ديورانت.</li>
                <li>تاريخ الفلسفة اليونانية، يوسف كرم.</li>
                <li>تاريخ الفلسفة الأوربية، يوسف كرم.</li>
                <li>تاريخ الفلسفة الحديثة، يوسف كرم.</li>
                <li>تاريخ الفلسفة الحديثة، وليم كلي رايت.</li>
            </ul>

            <h4 class="orange-text">كتب عن القراءة والكتابة:</h4>
            <ul>
                <li>هكذا تكلم القارئ.</li>
                <li>في صحبة الكتب.</li>
                <li>فن القراءة.</li>
                <li>كيف تقرأ كتابًا.</li>
                <li>يوميات القراءة</li>
                <li>مكتبة ساحة الأعشاب.</li>
                <li>دروب القراءة.</li>
                <li>رحلات في حجرة الكتابة.</li>
                <li>مهنة القراءة.</li>
                <li>يوميات بائع كتب.</li>
                <li>كيف نقرأ الأدب.</li>
                <li>متعة القراءة.</li>
            </ul>
        </div>
    </div>
    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h4 class="orange-text">كتب السيرة النبوية:</h4>
            <ul>
                <li>الرحيق المختوم، المباركفوري.</li>
                <li>نور اليقين في سيرة سيد المرسلين، الخضري.</li>
                <li>فقه السيرة، الغزالي.</li>
                <li>مصادر السيرة النبوية وتقويمها، فاروق حمادة.</li>
                <li>تحفة النبلاء من قصص الأنبياء، ابن كثير.</li>
                <li>صحيح السيرة النبوية، الألباني.</li>
                <li>بهجة قلوب الأبرار، السعدي.</li>
            </ul>

            <h4 class="orange-text">أدب السجون:</h4>
            <ul>
                <li>يسمعون حسيسها.</li>
                <li>السجينة.</li>
                <li>شرق المتوسط.</li>
                <li>القوقعة.</li>
                <li>رسائل السجن.</li>
                <li>اسمه أحمد.</li>
                <li>يا صاحبي السجن.</li>
                <li>تلك العتمة الباهرة.</li>
                <li>رسائل من السجن.</li>
            </ul>
        </div>
    </div>
</ng-template>

<ng-template #practicalWorksSection let-section="section">
    <div class="page">
        <img class="cover" src="assets/reading-report/images/2.jpg" alt="intro-image" />
    </div>
    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="green-text">ما هو المجال الذي يناسبني في القراءة؟</h2>
            <p>
                بناء على نمطك في القراءة وما تهدف إليه والمستقبل المقبل عليه، فأنت
                تناسبك:
            </p>
            <h4>الكتب العملية والتطبيقية</h4>
            <p>وهي كتبٌ تقصد أن تعلّمنا ماذا نفعل؟ وكيف نعمل؟ في مجالٍ ما.</p>
            <p>
                وكون العمل يعتبر هاماً لديك، فإننا نقدم لك نصيحة حول تركيز قراءاتك في
                المجالات الآتية لتقرأ فيها:
            </p>

            <p>
                <strong>أولاً- الإدارة: </strong>ننصحك بقراءة كتب وتجارب عن الإدارة بشكل
                عام، كالتمويل والتسويق وإدارة المشاريع وغيرها، كما يفضل أن تتركز الكتب
                إن أمكن حول مجالات العمل التي أنت فيها حالياً أو ضمن مخططاتك في
                المستقبل.
            </p>
            <p>
                <strong>ثانياً- علم الاجتماع: </strong>وهو أحد العلوم الإنسانية التي
                تركز على المجتمعات البشرية والعلاقات التي تنشأ بين الأفراد، بالإضافة إلى
                الأركان التي يتكون منها المجتمع كالشركات والمؤسسات المختلفة والسكان
                والأعراق والفئات العمرية. ننصحك بالقراءة في هذا المجال، من باب الثقافة
                والاطلاع، ثم تطبيق ذلك في مجالات عملك الحالية والمستقبلية.
            </p>
            <p>
                <strong>ثالثاً- القانون: </strong>إذا لم تكن متخصصاً، فمن المهم لك قراءة
                كتب توعوية في القوانين والتشريعات، المحلية منها والعالمية، فهي من الأمور
                التي لا بدّ وأنك ستحتاجها في عملك، وسيكون مهماً إدراكك لها في التوقيت
                المناسب.
            </p>
            <p>
                <strong>رابعاً- الإعلام: </strong>من أكثر الأمور التي نحتاجها هو علوم
                وممارسات الإعلام بمختلف تصنيفاته التقليدية كالصحف والمجلات، والاجتماعية
                كشبكات التواصل الاجتماعي. ننصحك بقراءة كتب تطبيقية تساعدك على استخدام
                الإعلام في صالحك سواء على الصعيد الشخصي أو المهني.
            </p>
        </div>
    </div>
    <div class="page">
        <img class="cover section-image" src="assets/reading-report/images/3.jpg" alt="intro-image" />
    </div>

    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="green-text">ماذا أقرأ من كتب؟</h2>
            <p>
                فيما يلي مجموعة مقترحة من الكتب من أصدقاء القراءة التي ننصحك بقراءتها
                وإضافتها إلى مكتبتك. وفي نهاية الأمر، فقرار اختيار ما تقرؤه يعود إليك،
                فالقراءة هي وسيلة لتحقيق أهدافك في الحياة كما هي منطقة للمتعة والراحة
                لك.
            </p>

            <h4 class="orange-text">كتب التطوير والتنمية الذاتية:</h4>
            <ul>
                <li>جدد حياتك، الغزالي.</li>
                <li>العادات السبع للناس الأكثر فعالية.</li>
                <li>كيف تؤثر على الآخرين وتكسب الأصدقاء.</li>
                <li>فن التفكير الإبداعي.</li>
                <li>العادات الذرية.</li>
                <li>لا تهتم بصغائر الأمور.</li>
                <li>الأب الغني والأب الفقير.</li>
                <li>خراب.</li>
                <li>ملاحظات حول كوكب متوتر.</li>
                <li>ولدت لتفوز.</li>
                <li>انهض.</li>
                <li>متعة أن تكون في الثلاثين.</li>
                <li>غدًا أجمل.</li>
                <li>فن اللامبالاة.</li>
                <li>استراحة نفسية.</li>
                <li>قوة عقلك الباطن.</li>
            </ul>
        </div>
    </div>

    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h4 class="orange-text">كتب الفلسفة:</h4>
            <ul>
                <li>قصة الفلسفة، ول ديورانت.</li>
                <li>تاريخ الفلسفة اليونانية، يوسف كرم.</li>
                <li>تاريخ الفلسفة الأوربية، يوسف كرم.</li>
                <li>تاريخ الفلسفة الحديثة، يوسف كرم.</li>
                <li>تاريخ الفلسفة الحديثة، وليم كلي رايت.</li>
            </ul>
            <h4 class="orange-text">كتب في السيرة الذاتية والمذكرات:</h4>
            <ul>
                <li>قصة حياة، المازني.</li>
                <li>رحلتي الفكرية، المسيري.</li>
                <li>حصاد السنين، زكي محمود.</li>
                <li>ذكريات، الطنطاوي.</li>
                <li>سبعون، ميخائيل نعيمه.</li>
                <li>مذكرات الأرقش.</li>
                <li>رحيق العمر، جلال أمين.</li>
                <li>قصة حياة، الغزالي.</li>
                <li>ذكرى عهود، الزيات.</li>
                <li>الأيام، طه حسين.</li>
                <li>أنا، العقاد.</li>
                <li>غبار السنين، فروخ.</li>
                <li>مذكرات الأرقش.</li>
            </ul>
        </div>
    </div>
    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h4 class="orange-text">كتب السيرة النبوية:</h4>
            <ul>
                <li>الرحيق المختوم، المباركفوري.</li>
                <li>نور اليقين في سيرة سيد المرسلين، الخضري.</li>
                <li>فقه السيرة، الغزالي.</li>
                <li>مصادر السيرة النبوية وتقويمها، فاروق حمادة.</li>
                <li>تحفة النبلاء من قصص الأنبياء، ابن كثير.</li>
                <li>صحيح السيرة النبوية، الألباني.</li>
                <li>بهجة قلوب الأبرار، السعدي.</li>
            </ul>

            <h4 class="orange-text">كتب عن القراءة والكتابة:</h4>
            <ul>
                <li>هكذا تكلم القارئ.</li>
                <li>هكذا تكلم القارئ.</li>
                <li>في صحبة الكتب.</li>
                <li>فن القراءة.</li>
                <li>كيف تقرأ كتابًا.</li>
                <li>يوميات القراءة</li>
                <li>مكتبة ساحة الأعشاب.</li>
                <li>دروب القراءة.</li>
                <li>رحلات في حجرة الكتابة.</li>
                <li>مهنة القراءة.</li>
                <li>يوميات بائع كتب.</li>
                <li>كيف نقرأ الأدب.</li>
                <li>متعة القراءة.</li>
            </ul>
        </div>
    </div>
</ng-template>

<ng-template #practicalDoNotWorkSection let-section="section">
    <div class="page">
        <img class="cover" src="assets/reading-report/images/2.jpg" alt="intro-image" />
    </div>
    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="green-text">ما هو المجال الذي يناسبني في القراءة؟</h2>
            <p>
                بناء على نمطك في القراءة وما تهدف إليه والمستقبل المقبل عليه، فأنت
                تناسبك:
            </p>
            <h4>الكتب العملية والتطبيقية</h4>
            <p>وهي كتبٌ تقصد أن تعلّمنا ماذا نفعل؟ وكيف نعمل؟ في مجالٍ ما.</p>
            <p>
                وكون العمل لا يعتبر هاماً لديك، فإننا نقدم لك نصيحة حول تركيز قراءاتك في
                المجالات الآتية لتقرأ فيها:
            </p>

            <p>
                <strong>أولاً- علم التربية: </strong>يُعرَّف علم التربية على أنّه دراسة
                طرق التعليم التي يتّبعها المعلّمون في تقديم المنهج الدراسي للطلبة، بما
                يضمن تحديد الأهداف المرادة منه، ومعرفة طرق تحقيقها. التربية هو بناء
                الأجيال، لذلك فهو وإن كان يتصدى له فئة من المتخصصين، فإننا ننصحك بقراءة
                كتب عن التربية لما لها من أهمية لك على صعيد فردي وأسري
            </p>
            <p>
                <strong>ثانياً- علم النفس : </strong>وهو دراسة أكاديمية وتطبيقية للسلوك
                البشري والمشاعر والعقل، ويهتم بدراسة النفس البشرية في جميع جوانب حياتها
                الشخصية والمهنية والاجتماعية. يفيد قراءة علم النفس في تطوير مهارات
                التواصل والتفكير النقدي وذلك من خلال فهم النفس والعقل والسلوك، لذلك بغض
                النظر عن تخصصك العلمي، فننصحك بقراءة كتب تنموية وتوعوية عن علم النفس.
            </p>
            <p>
                <strong>ثالثاً- الصحة والطب: </strong>الصحة والطلب من العلوم الأساسية
                التي ينبغي أن يكون لدى جميعنا الحد الأدنى منها، وعلى جميع الأحوال هذا
                المجال من الكتب يناسب نمطك الشخصي وميولك، لذلك ننصحك بقراءة كتب توعوية
                حول هذا المجال.
            </p>
            <p>
                <strong>ثالثاً- الفنون : </strong>وهي عملية إنشاء أعمال بصرية أو سمعية
                أو أداء (حركية)، للتعبير عن أفكار المؤلف الإبداعية أو المفاهيمية أو
                المهارة الفنية. وهي من العلوم التي تناسب شخصيتك وميولك، ننصحك بقراءة كتب
                عنها تتناول الأفكار والمفاهيم، وليس فقط الأخبار.
            </p>
        </div>
    </div>
    <div class="page">
        <img class="cover" src="assets/reading-report/images/3.jpg" alt="intro-image" />
    </div>

    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h2 class="green-text">ماذا أقرأ من كتب؟</h2>
            <p>
                فيما يلي مجموعة مقترحة من الكتب من أصدقاء القراءة التي ننصحك بقراءتها
                وإضافتها إلى مكتبتك. وفي نهاية الأمر، فقرار اختيار ما تقرؤه يعود إليك،
                فالقراءة هي وسيلة لتحقيق أهدافك في الحياة كما هي منطقة للمتعة والراحة
                لك.
            </p>

            <h4 class="orange-text">كتب التطوير والتنمية الذاتية:</h4>
            <ul>
                <li>جدد حياتك، الغزالي.</li>
                <li>العادات السبع للناس الأكثر فعالية.</li>
                <li>كيف تؤثر على الآخرين وتكسب الأصدقاء.</li>
                <li>فن التفكير الإبداعي.</li>
                <li>العادات الذرية.</li>
                <li>لا تهتم بصغائر الأمور.</li>
                <li>الأب الغني والأب الفقير.</li>
                <li>خراب.</li>
                <li>ملاحظات حول كوكب متوتر.</li>
                <li>ولدت لتفوز.</li>
                <li>انهض.</li>
                <li>متعة أن تكون في الثلاثين.</li>
                <li>غدًا أجمل.</li>
                <li>فن اللامبالاة.</li>
                <li>استراحة نفسية.</li>
                <li>قوة عقلك الباطن.</li>
            </ul>
        </div>
    </div>

    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h4 class="orange-text">كتب السيرة النبوية:</h4>
            <ul>
                <li>الرحيق المختوم، المباركفوري.</li>
                <li>نور اليقين في سيرة سيد المرسلين، الخضري.</li>
                <li>فقه السيرة، الغزالي.</li>
                <li>مصادر السيرة النبوية وتقويمها، فاروق حمادة.</li>
                <li>تحفة النبلاء من قصص الأنبياء، ابن كثير.</li>
                <li>صحيح السيرة النبوية، الألباني.</li>
                <li>بهجة قلوب الأبرار، السعدي.</li>
            </ul>
            <h4 class="orange-text">روايات:</h4>
            <ul>
                <li>اسم الوردة.</li>
                <li>مذكرات قبو.</li>
                <li>مكتبة ساحة الأعشاب.</li>
                <li>واختفى كل شيء.</li>
                <li>الجنيّة، القصيبي.</li>
                <li>شركاء في الجريمة.</li>
                <li>سمرقند.</li>
                <li>ليون الإفريقي.</li>
                <li>ربيع قرطبة.</li>
                <li>ألزهايمر، القصيبي.</li>
                <li>الفتى المفقود.</li>
                <li>الجنرال في متاهته.</li>
                <li>حفلة التيس.</li>
                <li>ناقة صالح.</li>
                <li>ساعي بريد نيرودا.</li>
                <li>لاعب الشطرنج.</li>
                <li>ثلاثية غرناطة.</li>
                <li>الحمامة.</li>
                <li>لو أبصرت ثلاثة أيام.</li>
            </ul>
        </div>
    </div>
    <div class="page">
        <img class="cover" src="assets/reading-report/images/bg.jpg" alt="overall-assessment" />
        <div class="wrapper">
            <h4 class="orange-text">كتب في السيرة الذاتية والمذكرات:</h4>
            <ul>
                <li>قصة حياة، المازني.</li>
                <li>رحلتي الفكرية، المسيري.</li>
                <li>حصاد السنين، زكي محمود.</li>
                <li>ذكريات، الطنطاوي.</li>
                <li>سبعون، ميخائيل نعيمه.</li>
                <li>مذكرات الأرقش.</li>
                <li>رحيق العمر، جلال أمين.</li>
                <li>قصة حياة، الغزالي.</li>
                <li>ذكرى عهود، الزيات.</li>
                <li>الأيام، طه حسين.</li>
                <li>أنا، العقاد.</li>
                <li>غبار السنين، فروخ.</li>
                <li>مذكرات الأرقش.</li>
            </ul>

            <h4 class="orange-text">كتب عن القراءة والكتابة:</h4>
            <ul>
                <li>هكذا تكلم القارئ.</li>
                <li>في صحبة الكتب.</li>
                <li>فن القراءة.</li>
                <li>كيف تقرأ كتابًا.</li>
                <li>يوميات القراءة</li>
                <li>مكتبة ساحة الأعشاب.</li>
                <li>دروب القراءة.</li>
                <li>رحلات في حجرة الكتابة.</li>
                <li>مهنة القراءة.</li>
                <li>يوميات بائع كتب.</li>
                <li>كيف نقرأ الأدب.</li>
                <li>متعة القراءة.</li>
            </ul>
        </div>
    </div>
</ng-template>