import { Component, ViewEncapsulation, ViewChild, inject, afterNextRender } from "@angular/core";
import { MatDrawer, MatSidenavModule } from "@angular/material/sidenav";
import { Router, RouterLink, RouterOutlet } from "@angular/router";
import { AuthService } from "@upupa/auth";
import { LanguageService } from "@upupa/language";
import { map } from "rxjs/operators";
import { fromEvent, startWith } from "rxjs";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";

@Component({
    selector: "client-layout",
    templateUrl: "./client-layout.component.html",
    styleUrls: ["./client-layout.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, MatToolbarModule, RouterLink, MatSidenavModule, RouterOutlet],
})
export class ClientLayoutComponent {
    public readonly languageService = inject(LanguageService);
    private readonly router = inject(Router);
    public readonly auth = inject(AuthService);

    date = new Date().getFullYear();
    @ViewChild(MatDrawer)
    matDrawer!: MatDrawer;
    dir = this.languageService.dir$;
    lChange(l: string) {
        const currentLang = this.router.url.slice(1, 3);
        this.router.navigateByUrl(this.router.url.replace(currentLang, l));
        // this.router.navigateByUrl(`/${l}`);
    }
}
