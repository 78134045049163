<mat-form-field [appearance]="appearance()" [floatLabel]="floatLabel()" style="width: 100%">
    <input type="hidden" [value]="value()" />
    @if (label()) {
        <mat-label>{{ label() }}</mat-label>
    }
    <mat-chip-grid #chipGrid [attr.aria-label]="label()" [disabled]="disabled()" [formControl]="control()">
        @for (item of selectedNormalized(); track item.key) {
            <mat-chip-row (removed)="remove(item)">
                {{ item.display }}
                <button matChipRemove [attr.aria-label]="'remove ' + item.key">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
        }
    </mat-chip-grid>

    <input
        #filterInput
        [disabled]="disabled()"
        [readOnly]="readonly()"
        (focus)="openedChange(true)"
        [formControl]="filterControl"
        [matChipInputFor]="chipGrid"
        [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes()"
        type="text"
        matInput
        (blur)="markAsTouched()"
        (input)="filterModel.set(filterInput.value); updateFilter()"
        [placeholder]="placeholder()"
        (matChipInputTokenEnd)="onAdding($event.value)"
    />

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
        @for (opt of items(); track opt.key) {
            <mat-option [value]="opt.key">
                @if (opt.image) {
                    <img [src]="opt.image" [attr.alt]="opt.value" />
                }
                <span>{{ opt.display }}</span>
            </mat-option>

            @if (canAdd && filterInput.value.length > 0 && options().length === 0) {
                <mat-option [value]="null">
                    <span>Add</span>
                </mat-option>
            }
        }
    </mat-autocomplete>
    <mat-error *errors="control().errors; control: control(); let message">{{ message }}</mat-error>
</mat-form-field>
