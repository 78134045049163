<div class="container">
    <div *ngIf="breadcrumbs?.length > 0" class="breadcrumb">
        <ng-container *ngFor="let item of breadcrumbs; let i = index">
            <a class="item" href="javascript:;" (click)="navigateTo(item)"
                [class.active]="activeBreadcrumb?.name === item.name">{{item.display}}</a>
            <span *ngIf="i < breadcrumbs.length - 1" class="item sep"></span>
        </ng-container>
    </div>
  
    <h2 *ngIf="title?.length > 0" class="title mbs-1">{{title}}</h2>
  

    <p  *ngIf="description?.length > 0" class="description mbs-1">{{description}}</p>
 
    <div *ngIf="rating !== null" class="rating mbs-1">
        <rating-stars [rating]="rating"></rating-stars>
    </div>
</div>