import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'rating-stars',
    templateUrl: './rating-stars.component.html',
    styleUrls: ['./rating-stars.component.scss'],
    encapsulation:ViewEncapsulation.None,
    standalone: true,
    imports: [MatIconModule, CommonModule],
})
export class RatingStarsComponent {


    filled = 0
    half = 0
    empty = 0
    stars = new Array(5).fill(0).map((c, i) => i)
    private _max = 5;
    @Input()
    public get max() {
        return this._max;
    }
    public set max(value) {
        this._max = value;
        this.stars = new Array(value).fill(0).map((c, i) => i)
    }
    @Input() total!: number

    private _rating!: number;
    @Input()
    public get rating(): number {
        return this._rating;
    }
    public set rating(value: number) {
        this._rating = value;
        const [n, i] = `${value}`.split('.').map(a => +(a ?? '0'))
        this.filled = n - 1
        this.half = i > 0 ? this.filled + 1 : -1
        this.empty = this.half > -1 ? this.half + 1 : this.filled + 1

        console.log(this.filled, this.half, this.empty);

    }

    // fillStars(index: number): string {
    //     if (this.rating >= index + 1) {
    //         return 'filled';
    //     } else if (this.rating >= index + 0.5) {
    //         return 'half-filled';
    //     } else {
    //         return 'empty';
    //     }
    // }
}
